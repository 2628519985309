import React from 'react';
import { Overlay as BpOverlay, Icon } from '@blueprintjs/core';

import styles from './Overlay.module.scss';

const Overlay = ({ children, ...props }) => {
  const { onClose } = props;

  return (
    <BpOverlay {...props}>
      <div className={styles.content}>
        <Icon
          icon="cross"
          iconSize={24}
          onClick={onClose}
          className={styles.icon}
        />
        {children}
      </div>
    </BpOverlay>
  );
};

export default Overlay;
