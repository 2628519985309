export const DASHBOARD_GET = 'DASHBOARD_GET';
export const DASHBOARD_GET_SUCCESS = 'DASHBOARD_GET_SUCCESS';
export const DASHBOARD_GET_FAILURE = 'DASHBOARD_GET_FAILURE';

// ARTICLES
export const DASHBOARD_ARTICLES_GET = 'DASHBOARD_ARTICLES_GET';
export const DASHBOARD_ARTICLES_GET_REQUEST = 'DASHBOARD_ARTICLES_GET_REQUEST';
export const DASHBOARD_ARTICLES_GET_SUCCESS = 'DASHBOARD_ARTICLES_GET_SUCCESS';
export const DASHBOARD_ARTICLES_GET_FAILURE = 'DASHBOARD_ARTICLES_GET_FAILURE';

// OTHERS
export const DASHBOARD_CANCEL = 'DASHBOARD_CANCEL';

export default {
  DASHBOARD_GET,
  DASHBOARD_GET_SUCCESS,
  DASHBOARD_GET_FAILURE,
  DASHBOARD_ARTICLES_GET,
  DASHBOARD_ARTICLES_GET_REQUEST,
  DASHBOARD_ARTICLES_GET_SUCCESS,
  DASHBOARD_ARTICLES_GET_FAILURE,
  DASHBOARD_CANCEL,
};
