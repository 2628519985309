import { combineReducers } from 'redux';

import general from './general';
import me from './users/me.reducer';
import overview from './overview';
import dashboard from './dashboard';

import articlesList from './articles/articlesList.reducer';
import articlesSingle from './articles/articlesSingle.reducer';

import blogsList from './blogs/blogsList.reducer';
import blogsSingle from './blogs/blogsSingle.reducer';

import newspapersList from './newspapers/newspapersList.reducer';
import bettingCardsList from './bettingCards/bettingCardsList.reducer';

// Combine reducers with routeReducer which keeps track of
// router state
const rootReducer = combineReducers({
  general,
  me,
  overview,
  dashboard,

  articlesList,
  articlesSingle,

  blogsList,
  blogsSingle,

  newspapersList,
  bettingCardsList,
});

export default rootReducer;
