import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';

import fetch from 'core/fetch';
import CONFIG from 'core/config';

export function getList() {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/newspapers`,
    method: 'GET',
    params: {
      per_page: 200,
    },
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export function post(file, params) {
  const source = CancelToken.source();
  const body = new FormData();
  body.append('file', file);

  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/newspapers`,
    method: 'POST',
    data: body,
    params,
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export function put(id, data) {
  const source = CancelToken.source();
  const body = new FormData();
  body.append('file', data);

  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/newspapers/${id}`,
    method: 'PUT',
    data: body,
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export function del(id) {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/newspapers/${id}`,
    method: 'DELETE',
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export default {
  getList,
  post,
  put,
  del,
};
