import React, { useMemo } from 'react';
import { Route } from 'react-router';
import { ResourceListPage } from 'shared/components';
import { SuspenseErrorBoundary } from 'shared/hocs';
import PriceList from './PriceList';

export const Settings = ({ match }) => {
  const overviewItems = useMemo(() => [{
    key: 'pricing',
    title: 'Pricing',
  }], []);

  return (
    <ResourceListPage
      title="Settings"
      overviewItems={overviewItems}
      defaultSearchElementHidden
    >
      <Route
        path={match.path}
        render={props => (
          <SuspenseErrorBoundary>
            <PriceList {...props} />
          </SuspenseErrorBoundary>
        )}
      />
    </ResourceListPage>
  );
};

export default Settings;
