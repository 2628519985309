import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Tabs, Tab } from '@blueprintjs/core';

import { Details, Password } from './views';

import styles from './User.module.scss';

export class UsersRoot extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.updateTab = this.updateTab.bind(this);
  }

  updateTab(newTab, prevTab) {
    const { history } = this.props;
    if (newTab !== prevTab) {
      history.push(`/me/${newTab}`);
    }
  }

  render() {
    const { match } = this.props;
    return (
      <Fragment>
        <Helmet
          title="Loading"
          titleTemplate="Profile - %s"
        />
        <Tabs
          large
          renderActiveTabPanelOnly
          className={styles.tabs}
          onChange={this.updateTab}
          defaultSelectedTabId={match.params.tab}
        >
          <Tab className={styles.tab} id="details" title="Details" panel={<Details {...this.props} />} />
          <Tab className={styles.tab} id="password" title="Password" panel={<Password {...this.props} />} />
        </Tabs>
      </Fragment>
    );
  }
}

export default UsersRoot;
