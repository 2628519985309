
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Dialog } from '@blueprintjs/core';

import 'react-image-crop/dist/ReactCrop.css';

import Stack from './components/Stack';

import styles from './UploadMedia.module.scss';

class UploadMedia extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onUpload: PropTypes.func,
  }
  static defaultProps = {
    isOpen: true,
    onClose: noop,
    onUpload: noop,
  }

  constructor(props) {
    super(props);

    this.state = {
      file: null,
    };

    this.onDropFile = this.onDropFile.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onDropFile(file) {
    this.setState({
      file,
    });
  }

  onClose() {
    const { onClose } = this.props;
    this.setState({
      file: null,
    });
    onClose();
  }

  render() {
    const { isOpen, onUpload, ...props } = this.props;
    const { file } = this.state;

    return (
      <Dialog
        className={styles.root}
        isOpen={isOpen}
        onClose={this.onClose}
        enforceFocus={false}
        title="Media upload"
      >
        <Stack
          onDrop={this.onDropFile}
          onUpload={onUpload}
          mediaFile={file}
          {...props}
        />
      </Dialog>
    );
  }
}

export default UploadMedia;
