import React, { Fragment, useCallback } from 'react';
import { FormGroup, EditableText, TextArea } from '@blueprintjs/core';

import { inputTypes } from './Forms.types';

const propTypes = inputTypes;

const defaultProps = {
  placeholder: null,
  intent: 'default',
  onChange: null,
  isDynamic: false,
};

export const TextAreaInput = ({
  helperText,
  labelFor,
  placeholder,
  intent,
  field,
  form,
  textAreaProps,
  onChange,
  isDynamic,
  withCounter,
  ...props
}) => {
  const error = form.errors[field.name];
  const value = field.value || '';
  const handleChangeEditableText = useCallback(value => form.setFieldValue(field.name, value));

  return (
    <FormGroup
      helperText={error ? error : helperText}
      labelFor={labelFor || field.name}
      intent={error ? 'danger' : intent}
      {...props}
    >
      <Fragment>
        {isDynamic ? (
          <EditableText
            id={labelFor || field.name}
            placeholder={placeholder}
            type="text"
            {...field}
            {...textAreaProps}
            onChange={onChange || handleChangeEditableText}
          />
        ) : (
          <TextArea
            id={labelFor || field.name}
            placeholder={placeholder}
            type="text"
            {...field}
            {...textAreaProps}
            onChange={onChange || field.onChange}
          />
        )}
        {withCounter && (
          <div className="m-t-5">
            {value.length}{props.maxLength ? ` / ${props.maxLength}` : ''} characters
          </div>
        )}
      </Fragment>
    </FormGroup>
  );
};

TextAreaInput.propTypes = propTypes;
TextAreaInput.defaultProps = defaultProps;

export default TextAreaInput;
