import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Dialog, Classes } from '@blueprintjs/core';

import AddWidgetForm from './AddWidgetForm';

const propTypes = {
  editorState: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
const defaultProps = {
  // initialValues: {},
};

const getFieldValidation = (name, rules) => {
  return rules.includes('required')
    ? yup
      .string()
      .trim()
      .required(`${name} is required`)
    : undefined;
};

export const AddWidget = ({ isOpen, onClose, onChange, editorState, widgetPlugin }) => {
  const handleSubmit = async values => {
    try {
      onChange(widgetPlugin.addWidget(editorState, values));
      onClose();
    } catch (error) {
      // err
    }
  };

  const schema = yup.lazy(values => {
    const selectedWidget = widgetPlugin.widgetsDefinition[values.widgetType];
    const validationShape = Object.entries(selectedWidget.fields).reduce((acc, [name, field]) => {
      const { validation } = field;
      acc[name] = getFieldValidation(name, validation);
      return acc;
    }, {});

    return yup.object().shape(validationShape);
  });

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Add widget"
    >
      <div className={Classes.DIALOG_BODY}>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={schema}
          render={props => (
            <AddWidgetForm
              widgetsDefinition={widgetPlugin.widgetsDefinition}
              fieldTypes={widgetPlugin.fieldTypes}
              {...props}
            />
          )}
        />
      </div>
    </Dialog>
  );
};

AddWidget.propTypes = propTypes;
AddWidget.defaultProps = defaultProps;

export default AddWidget;
