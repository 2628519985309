import {
  LOADING_STATES,
  BLOGS_SINGLE_GET_REQUEST,
  BLOGS_SINGLE_GET_SUCCESS,
  BLOGS_SINGLE_GET_FAILURE,
  BLOGS_PUT_SUCCESS,
  BLOGS_DELETE_SUCCESS,
  BLOGS_CHANGES_UNDO,
  BLOGS_CANCEL,
} from 'data/constants';

import { normalize } from './blogNormalizr';

const defaultState = {
  state: LOADING_STATES.INITIAL,
  count: 0,
  data: {},
  error: null,
};

export default function blogsSingle(state = defaultState, action) {
  switch (action.type) {
    case BLOGS_SINGLE_GET_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      };
    case BLOGS_SINGLE_GET_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        data: normalize(action.data, action.meta),
      };
    case BLOGS_SINGLE_GET_FAILURE:
      return {
        ...state,
        state: LOADING_STATES.FAILED,
        error: action.error.message,
      };
    case BLOGS_PUT_SUCCESS:
      return {
        ...state,
        data: normalize(action.req, action.meta),
      };
    case BLOGS_CHANGES_UNDO:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.blog,
        },
      };
    case BLOGS_CANCEL:
    case BLOGS_DELETE_SUCCESS:
      return defaultState;
    default:
      return state;
  }
}
