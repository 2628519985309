import React from 'react';
import cx from 'classnames';

import styles from './DataListItem.module.scss';

const DataListItem = ({ className, children, ...props }) => {
  return (
    <div className={cx(styles.root, className)} {...props}>
      {children}
    </div>
  );
};

export default DataListItem;
