import _DatePicker from './DatePicker';
import DateRange from './DateRange';

export const DatePicker = _DatePicker;
export const RangePicker = DateRange;

export default {
  DatePicker,
  RangePicker,
};
