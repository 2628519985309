export * from './general.const';
export * from './auth.const';
export * from './profile.const';
export * from './dashboard.const';
export * from './overview.const';

export * from './articles.const';
export * from './blogs.const';
export * from './newspapers.const';
export * from './bettingCards.const';

// LOADING STATES
export const LOADING_STATES = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  FAILED: 'FAILED',
};

export default {
  LOADING_STATES,
};
