import React from 'react';
import { MultiSelect } from '@blueprintjs/select';
import { FormGroup, MenuItem } from '@blueprintjs/core';

const itemListPredicate = (query, items) => items.filter(item => item.includes(query));

const renderItem = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      key={item}
      active={modifiers.active}
      disabled={modifiers.disabled}
      text={item}
      onClick={handleClick}
    />
  );
};

const CustomMultiSelect = ({
  items, intent, helperText, onChange,
  form, field,
  ...props
}) => {
  const MAX_ITEMS = 10;
  const value = field.value || props.value;
  const error = form.errors && form.errors[field.name];

  const limitedItems = items.filter((item, index) => value && !value.includes(item) && index < MAX_ITEMS);

  const handleChange = newValue => {
    if (typeof form.setFieldValue === 'function') {
      form.setFieldValue(field.name, newValue);
    }

    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  const handleItemSelect = selectedItem => {
    const newValue = [...value, selectedItem];

    handleChange(newValue);
  };

  const handleItemRemove = removedItem => {
    const newValue = value.filter(item => item !== removedItem);

    handleChange(newValue);
  };

  return (
    <FormGroup
      labelFor={field.name}
      intent={error ? 'danger' : intent}
      helperText={error ? error : helperText}
    >
      <MultiSelect
        resetOnSelect
        itemRenderer={renderItem}
        items={limitedItems}
        selectedItems={value}
        onItemSelect={handleItemSelect}
        noResults={<MenuItem disabled={true} text="No results." />}
        popoverProps={{
          targetClassName: 'fill',
          minimal: true,
        }}
        itemListPredicate={itemListPredicate}
        tagInputProps={{
          onRemove: handleItemRemove,
        }}
        tagRenderer={item => item}
        {...props}
      />
    </FormGroup>
  );
};

export default CustomMultiSelect;
