import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';

import { CropAndUploadImage } from 'shared/components';

import styles from './ImageUpload.module.scss';

export class ImageUpload extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    fill: PropTypes.bool,
    imageAlt: PropTypes.string,
  }

  static defaultProps = {
    onChange: noop,
    width: 250,
    height: 250,
    placeholder: 'Click to upload...',
    fill: false,
    imageAlt: 'Uploaded picture',
  }

  constructor(props) {
    super(props);

    this.state = {
      isUploadModalVisible: false,
    };

    this.openUpload = () => this.setState({ isUploadModalVisible: true });
    this.closeUpload = () => this.setState({ isUploadModalVisible: false });
    this.onUpload = this.onUpload.bind(this);
  }

  onUpload(value) {
    const { onChange } = this.props;
    onChange(value);
    this.closeUpload();
  }

  render() {
    const {
      width,
      height,
      cropCircle,
      image,
      placeholder,
      fill,
      imageAlt,
    } = this.props;
    const {
      isUploadModalVisible,
    } = this.state;

    return (
      <Fragment>
        <div
          style={{
            width: fill ? 'auto' : width,
          }}
          className={classnames(
            styles.block,
            {
              [styles['block--circle']]: cropCircle,
            }
          )}
          onClick={this.openUpload}
        >
          <div
            className={styles.image}
            style={{
              paddingTop: `${height * 100 / width}%`,
            }}
          >
            {image && <img src={image} alt={imageAlt} />}
          </div>
          <div className={styles.overlay}>
            {placeholder}
          </div>
        </div>
        <CropAndUploadImage
          isOpen={isUploadModalVisible}
          onClose={this.closeUpload}
          onUpload={this.onUpload}
          imageProps={{
            width,
            height,
            cropCircle,
          }}
        />
      </Fragment>
    );
  }
}

export default ImageUpload;
