import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import moment from 'moment';
import { Popover, Menu, MenuItem, MenuDivider, Position, Intent } from '@blueprintjs/core';

import {
  editArticle,
  deleteArticle,
  undoArticleChange,
  addArticleToList,
  removeArticleFromList,
} from 'data/actions/articles';

import Types from 'shared/types/Articles.types';

import { DeleteItemAlert } from 'shared/alerts/articles';
import { Button, RouterMenuItem } from 'shared/components';

class ArticleActions extends PureComponent {
  static propTypes = {
    article: Types.articleItemShape,
    edit: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isUpdating: false,
      isDeleteDialogOpen: false,
      canMoveToDraft: false,
      canMoveToSpellCheck: false,
      canMoveToContentCheck: false,
      canMoveToPublish: false,
      canMoveToArchive: false,
      canMoveToDeleted: false,
    };

    this.openDeleteDialog = () => this.setState({ isDeleteDialogOpen: true });
    this.closeDeleteDialog = () => this.setState({ isDeleteDialogOpen: false });
    this.editArticleStatus = this.editArticleStatus.bind(this);
    this.publishArticle = this.publishArticle.bind(this);
    this.archiveArticle = this.archiveArticle.bind(this);
    this.deleteArticle = this.deleteArticle.bind(this);
  }

  static getDerivedStateFromProps = ({ article }) => ({
    canPreview: !(article.status === 'approved' && moment(article.published_at).isBefore()),
    canMoveToSpellCheck: ['draft'].indexOf(article.status) > -1,
    canMoveToContentCheck: ['spell_check'].indexOf(article.status) > -1,
    canMoveToPublish: ['content_check'].indexOf(article.status) > -1,
    canMoveToArchive: !article.archived_at || moment(article.archived_at).isAfter(),
    canMoveToDeleted: article.archived_at && moment(article.archived_at).isBefore(),
  })

  async deleteArticle() {
    const { article, remove } = this.props;
    try {
      await remove(article.slug);
    } catch {
      this.closeDeleteDialog();
    }
  }

  async deployChange(data) {
    const { article, edit, undo } = this.props;

    this.setState({
      isUpdating: true,
    });

    // Remove article from current list
    const successEffect = () => removeArticleFromList(article.slug);
    // Make another request with previous data and add back to list
    const undoEffect = () => undo(article, () => addArticleToList(article));

    try {
      await edit(
        article.slug,
        article,
        data,
        successEffect,
        undoEffect,
      );
    } catch {
      // Do nothing
    }

    this.setState({
      isUpdating: false,
    });
  }

  async editArticleStatus(status) {
    const data = {
      status,
    };

    await this.deployChange(
      data,
    );
  }

  async archiveArticle() {
    const data = {
      archived_at: new Date(),
    };

    await this.deployChange(
      data,
    );
  }

  async publishArticle() {
    const date = new Date();

    const data = {
      status: 'approved',
      published_at: date,
    };

    await this.deployChange(
      data,
    );
  }

  render() {
    const { article } = this.props;
    const {
      isUpdating,
      isDeleteDialogOpen,
      canPreview,
      canMoveToContentCheck,
      canMoveToPublish,
      canMoveToArchive,
      canMoveToDeleted,
    } = this.state;

    return (
      <Fragment>
        <Popover
          position={Position.BOTTOM_LEFT}
        >
          <Button
            minimal
            onClick={noop}
            type="button"
            rightIcon="more"
            loading={isUpdating}
          />
          <Menu>
            {canPreview && (
              <RouterMenuItem
                to={`posts/preview/${article.slug}`}
                text="Preview"
                icon="share"
              />
            )}
            {canMoveToContentCheck && (
              <MenuItem
                onClick={() => this.editArticleStatus('content_check')}
                text="Move to Content check"
                icon="style"
              />
            )}
            {canMoveToPublish && (
              <MenuItem
                onClick={this.publishArticle}
                text="Publish"
                icon="calendar"
              />
            )}
            <MenuDivider />
            {canMoveToDeleted && (
              <MenuItem
                onClick={this.openDeleteDialog}
                text="Delete permanently"
                icon="cross"
                intent={Intent.DANGER}
              />
            )}
            {canMoveToArchive && (
              <MenuItem
                onClick={this.archiveArticle}
                text="Archive"
                icon="projects"
                intent={Intent.WARNING}
              />
            )}
          </Menu>
        </Popover>
        <DeleteItemAlert
          isOpen={isDeleteDialogOpen}
          onCancel={this.closeDeleteDialog}
          onConfirm={this.deleteArticle}
          item={article.header}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addToList: article =>
    dispatch(addArticleToList(article)),
  removeFromList: id =>
    dispatch(removeArticleFromList(id)),
  undo: (article, effect) =>
    dispatch(undoArticleChange(article, effect)),
  remove: id => dispatch(deleteArticle(id)),
  edit: (id, prevData, nextData, success, undo) =>
    dispatch(editArticle(id, prevData, nextData, success, undo)),
});

export default connect(
  null,
  mapDispatchToProps
)(ArticleActions);
