import React from 'react';
import cx from 'classnames';
import { Tag } from '@blueprintjs/core';
import moment from 'moment';

import CONFIG from 'core/config';

import styles from './StatusLabel.module.scss';

const StatusLabel = ({ className, status, publishedAt, archivedAt }) => {
  const labelMap = {
    draft: {
      icon: 'icon-pencil',
      text: 'Draft',
    },
    ['spell_check']: {
      icon: 'icon-spell-check',
      text: 'Spellcheck',
    },
    ['content_check']: {
      icon: 'icon-images',
      text: 'Content check',
    },
    approved: {
      icon: null,
      text: 'Published',
    },
    scheduled: {
      icon: 'icon-clock',
      text: moment(publishedAt).format(CONFIG.settings.dateTimeDisplayFormat),
    },
    archived: {
      text: 'Archived',
    },
  };

  let scope = status;

  if (scope === 'approved' && publishedAt && moment(publishedAt).isAfter()) {
    scope = 'scheduled';
  }
  if (archivedAt && moment(archivedAt).isBefore()) {
    scope = 'archived';
  }

  const state = labelMap[scope];

  if (!state.icon) {
    return <span className={cx(className)}>{state.text}</span>;
  }

  return state ? (
    <Tag
      round
      className={cx(styles.root, className, state.icon)}
      icon={!state.icon.includes('icon-') && state.icon}
    >
      {state.text}
    </Tag>
  )
    : null;
};

export default StatusLabel;
