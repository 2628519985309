import React, { Fragment, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Label, NonIdealState, Button } from '@blueprintjs/core';
import { get } from 'lodash';

import { DataList, FileButton } from 'shared/components';
import { NewspaperListItem } from './components';

import { NEWSPAPERS_GET, NEWSPAPERS_CANCEL } from 'data/constants';
import { editNewspaper, removeNewspaper } from 'data/actions/newspapers';

import { MainViewHandler } from 'shared/hocs';


export const NewspapersList = ({ items = [], filterBy, filterValue, editNewspaper, removeNewspaper }) => {
  const LastColumnElement = React.memo(({ id }) => (
    <Fragment>
      <FileButton
        minimal
        onChange={useCallback(file => editNewspaper({ id, file }), [])}
        inputProps={{
          accept: 'application/pdf',
        }}
      >
        override
      </FileButton>
      <Button minimal onClick={useCallback(() => removeNewspaper(id), [])}>
        remove
      </Button>
    </Fragment>
  ));

  const filteredItems = useMemo(() => items.filter(item => {
    if (!filterBy || !filterValue) return true;

    return get(item, filterBy) === filterValue;
  }), [filterBy, filterValue, items.length]);

  return filteredItems.length ? (
    <Fragment>
      <Label>Published content</Label>
      <DataList
        list={filteredItems.map((item, index) => ({
          key: item.id,
          component: (
            <NewspaperListItem
              item={item}
              no={index + 1}
              lastColumnElement={<LastColumnElement id={item.id} />}
            />
          ),
        }))}
      />
    </Fragment>
  ) : (
    <NonIdealState
      icon="database"
      title="No newspapers in selected category"
    />
  );
};

const mapStateToProps = state => ({
  state: state.newspapersList.state,
  items: state.newspapersList.list,
});

const mapDispatchToProps = dispatch => ({
  fetchData: params => dispatch({
    type: NEWSPAPERS_GET,
    params,
  }),
  cancelFetch: () => dispatch({
    type: NEWSPAPERS_CANCEL,
  }),
  removeNewspaper: id => dispatch(removeNewspaper(id)),
  editNewspaper: data => dispatch(editNewspaper(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainViewHandler(NewspapersList));
