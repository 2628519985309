import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, Button } from '@blueprintjs/core';

import { UserSelect } from 'shared/components';

import { inputTypes } from './Forms.types';

const propTypes = {
  ...inputTypes,
  loading: PropTypes.bool,
  withAssignSelf: PropTypes.bool,
};

const defaultProps = {
  placeholder: 'Select user...',
  intent: 'default',
  onChange: null,
  withAssignSelf: false,
  loading: false,
};

export const UserSelectField = ({
  helperText,
  labelFor,
  placeholder,
  intent,
  field,
  withAssignSelf,
  me,
  loading,
  buttonProps,
  form,
  validateOnChange = true,
  ...props
}) => {
  const error = form.errors[field.name];

  return (
    <FormGroup
      labelFor={labelFor || field.name}
      intent={error ? 'danger' : intent}
      helperText={error ? error : helperText}
      {...props}
    >
      <UserSelect
        id={labelFor || field.name}
        value={field.value}
        buttonProps={buttonProps}
        placeholder={placeholder}
        onChange={data => form.setFieldValue(field.name, data, validateOnChange)}
      />
      {!loading && withAssignSelf && (field.value ? field.value !== me.id : true) && (
        <Button
          small
          minimal
          onClick={() => form.setFieldValue(field.name, me.id)}
          className="m-l-10"
          intent="primary"
        >
          Assign to me
        </Button>
      )}
    </FormGroup>
  );
};

UserSelectField.propTypes = propTypes;
UserSelectField.defaultProps = defaultProps;

const mapStateToProps = state => ({
  me: state.me.data,
});

export default connect(
  mapStateToProps,
)(UserSelectField);
