// LOCAL STORAGE KEYS
export const STORAGE_AUTH_TOKEN_KEY = 'matchday-auth-token';
export const STORAGE_RESET_TOKEN_KEY = 'matchday-reset_token';

// ACTION TYPES
export const AUTH_GET = 'AUTH_GET';
export const AUTH_GET_REQUEST = 'AUTH_GET_REQUEST';
export const AUTH_GET_SUCCESS = 'AUTH_GET_SUCCESS';
export const AUTH_GET_FAILURE = 'AUTH_GET_FAILURE';
export const AUTH_CANCEL = 'AUTH_CANCEL';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE';

export default {
  STORAGE_AUTH_TOKEN_KEY,
  STORAGE_RESET_TOKEN_KEY,
  AUTH_GET,
  AUTH_GET_REQUEST,
  AUTH_GET_SUCCESS,
  AUTH_GET_FAILURE,
  AUTH_LOGIN,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_CANCEL,
};
