import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { closeNavigation } from 'data/actions/general';

import { PageHeader } from 'shared/components';
import Separator from 'shared/components/PageHeader/components/Separator';
import ScreenSizeSwitcher from './components/ScreenSizeSwitcher';
import DeviceFrame from './components/DeviceFrame/DeviceFrame';


class PreviewArticle extends Component {
  static propTypes = {
    closeNav: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props);

    this.state = {
      device: 'smartphone',
      landscape: false,
    };

    this.changeDevice = (device, landscape) => this.setState({ device, landscape });
  }

  componentDidMount() {
    this.props.closeNav();
  }

  render() {
    const { location } = this.props;
    const { device, landscape } = this.state;

    return (
      <Fragment>
        <Helmet
          title="Preview"
        />
        <PageHeader>
          <Link to={location.query.return || '/'}>Return to article</Link>
          <Separator />
          <h1 className="m-t-0 m-b-0">Preview article</h1>
        </PageHeader>
        <div className="m-b-50">
          <div className="m-b-30">
            <ScreenSizeSwitcher onChange={this.changeDevice} />
          </div>
          <DeviceFrame
            device={device}
            landscape={landscape}
          >
            <iframe
              title="Preview article"
              width="100%"
              height="100%"
              frameBorder="0"
              src={location.query.page}
            />
          </DeviceFrame>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  closeNav: () => dispatch(closeNavigation()),
});

export default connect(
  null,
  mapDispatchToProps
)(PreviewArticle);
