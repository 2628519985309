import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, isEqual } from 'lodash';

import { LOADING_STATES } from 'data/constants';

import { ViewLoader } from 'shared/components';

function MainViewHandlerHOC(WrappedComponent) {
  return class extends Component {
    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      state: PropTypes.oneOf(Object.keys(LOADING_STATES)).isRequired,
      fetchData: PropTypes.func.isRequired,
      cancelFetch: PropTypes.func,
    }
    static defaultProps = {
      cancelFetch: noop,
    }

    componentDidMount() {
      const {
        fetchData,
        match = { params: { } },
        location = { query: {} },
        params,
      } = this.props;

      setTimeout(fetchData({
        ...params,
        ...match.params,
        ...location.query,
      }));
    }

    componentDidUpdate(nextProps) {
      const {
        fetchData,
        match = { params: {} },
        location = { query: {} },
        params,
      } = this.props;
      const {
        match: nextMatch = { params: {} },
        location: nextLocation = { query: {} },
      } = nextProps;

      if (
        !isEqual(params, nextProps.params) ||
        !isEqual(match.params, nextMatch.params) ||
        !isEqual(location.query, nextLocation.query)
      ) {
        fetchData({
          ...params,
          ...match.params,
          ...location.query,
        });
      }
    }

    componentWillUnmount() {
      this.props.cancelFetch();
    }

    render() {
      const { state } = this.props;

      switch (state) {
        case LOADING_STATES.LOADING:
          return <ViewLoader />;
        case LOADING_STATES.LOADED:
        case LOADING_STATES.FAILED:
          return <WrappedComponent {...this.props} />;
        default:
          return null;
      }
    }
  };
}

export default MainViewHandlerHOC;
