import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tag, Intent } from '@blueprintjs/core';

import styles from './EventItem.module.scss';

class EventItem extends PureComponent {
  static propTypes = {
    // TODO: Event props
  };

  state = {
    imageLoaded: false,
    imageError: false,
  }

  render() {
    const { event, home, away, season } = this.props;

    return (
      <Link to={`/content-creator?eventId=${event.uuid}`} className={styles.root}>
        <article>
          <span className={styles.date}>
            {moment.utc(event.scheduled_started_at).local().fromNow()}
          </span>
          <div>
            <Tag intent={Intent.SUCCESS}>{home.short_name}</Tag>
            <span className={styles.versusTag}>VS</span>
            <Tag intent={Intent.PRIMARY}>{away.short_name}</Tag>
          </div>
          <div className={styles.meta}>
            {season && (
              <Tag
                minimal
                intent={Intent.SUCCESS}
              >
                {season.name}
              </Tag>
            )}
          </div>
        </article>
      </Link>
    );
  }
}

export default EventItem;
