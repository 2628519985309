import {
  LOADING_STATES,
  BLOGS_GET_REQUEST,
  BLOGS_GET_SUCCESS,
  BLOGS_GET_FAILURE,
  BLOGS_DELETE_SUCCESS,
  BLOGS_CANCEL,
  BLOGS_ADD_TO_LIST,
  BLOGS_REMOVE_FROM_LIST,
} from 'data/constants';

const defaultState = {
  state: LOADING_STATES.INITIAL,
  count: 0,
  list: [],
  error: null,
};

export default function blogsList(state = defaultState, action) {
  switch (action.type) {
    case BLOGS_GET_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      };
    case BLOGS_GET_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        count: action.data.meta.total_count,
        list: action.data.blog_posts,
      };
    case BLOGS_GET_FAILURE:
      return {
        ...state,
        state: LOADING_STATES.FAILED,
        error: action.error.message,
      };
    case BLOGS_ADD_TO_LIST:
      if (state.state !== LOADING_STATES.INITIAL) {
        return {
          ...state,
          count: state.count + 1,
          list: [
            action.blog_posts,
            ...state.list,
          ],
        };
      }
      return state;
    case BLOGS_DELETE_SUCCESS:
    case BLOGS_REMOVE_FROM_LIST:
      return {
        ...state,
        count: state.count - 1,
        list: state.list.filter(blog => blog.slug !== action.meta.slug ),
      };
    case BLOGS_CANCEL:
      return defaultState;
    default:
      return state;
  }
}
