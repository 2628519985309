import React from 'react';
import { NonIdealState } from '@blueprintjs/core';

const defaultProps = {
  permissionsNeeded: [],
};

const NoAccess = ({
  permissionsNeeded,
}) => {
  const allowedPermissionsDescription = (
    <span>
      Only <b>{permissionsNeeded.join(', ')}</b> can see this section
    </span>
  );

  return (
    <NonIdealState
      icon="lock"
      title="You are not allowed to see this page"
      description={permissionsNeeded
        ? allowedPermissionsDescription
        : ''
      }
    />
  );
};

NoAccess.defaultProps = defaultProps;

export default NoAccess;
