import React from 'react';
import { Alert, Intent } from '@blueprintjs/core';

export const DeleteItemAlert = ({ isOpen, onCancel, onConfirm, item }) => (
  <Alert
    canEscapeKeyCancel
    canOutsideClickCancel
    cancelButtonText="Cancel"
    confirmButtonText="Move to Trash"
    icon="trash"
    intent={Intent.DANGER}
    isOpen={isOpen}
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <p>
      Are you sure you want to move <strong>{item}</strong> to Trash? You won't be able to restore it later.
    </p>
  </Alert>
);

export default {
  DeleteItemAlert,
};
