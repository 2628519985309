import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Edit from './views/Edit';
import List from './views/List';

const ArticlesRoot = () => (
  <Switch>
    <Route exact path="/:type(articles|blogs)/:tab(all|draft|spell_check|content_check|scheduled|published|archived)?" component={List} />
    <Route path="/:type(articles|blogs)/:id" component={Edit} />

    <Redirect to="/articles" />
  </Switch>
);

export default ArticlesRoot;
