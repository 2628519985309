import request from 'axios';
import qs from 'qs';

class FetchService {
  serializeParams(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  }

  attachHeaders(options) {
    return {
      ...options,
      // This sends the query params in the correct format to API
      // We use arrays as param[]=1&param[]=2 in application
      // to know when a param should be an array or a simple value
      // without requiring some param mapper in the code
      paramsSerializer: params => this.serializeParams(params),
      headers: {
        ...options.headers,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: typeof options.withCredentials !== 'undefined' ? options.withCredentials : true,
    };
  }

  request = options => {
    const optionsWithHeader = this.attachHeaders(options);
    return request(optionsWithHeader).then(response => {
      Object.defineProperty(response.headers, 'get', {
        get: () => key => response.headers[key],
      });
      Object.defineProperty(response, 'json', {
        get: () => () => response.data,
      });
      Object.defineProperty(response, 'text', {
        get: () => () => response.data,
      });

      return response;
    });
  }

  fakeRequest(options = {}, response = {}, error = false) {
    console.log('options: ', options);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (error === null) {
          resolve({ data: response });
        } else {
          reject({ error });
        }
      }, 500);
    });
  }
}

const instance = new FetchService();

export default instance;
