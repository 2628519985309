import { take, takeLatest, cancel } from 'redux-saga/effects';

import { ARTICLES_GET, ARTICLES_CANCEL } from 'data/constants';

import { getArticlesList } from './articles.saga';

export default function* main() {
  while (true) {
    const task = yield takeLatest([ARTICLES_GET], getArticlesList);

    yield take(ARTICLES_CANCEL);
    yield cancel(task);
  }
}
