import React, { Fragment, useCallback, useState } from 'react';
import { NonIdealState, Intent } from '@blueprintjs/core';
import { useTable } from 'react-table';
import { useQuery, useMutation } from 'react-query';
import { throttle } from 'lodash';
import { Table, ValidationToast } from 'shared/components';
import API from 'data/fetch';

const getActivePlan = item => {
  try {
    return item;
  } catch (error) {
    return {};
  }
};

const getJoinedContentCategories = item => {
  try {
    return getActivePlan(item).content_categories
      .map(category => category)
      .join(' + ');
  } catch (error) {
    return '';
  }
};

const columns = [
  {
    Header: 'Plan name',
    accessor: 'name',
  },
  {
    id: 'plan_category',
    Header: 'Category',
    accessor: item => getJoinedContentCategories(item),
  },
  {
    id: 'price',
    Header: 'Price',
    Cell: props => <Table.EditableCell {...props} />,
    editFieldType: Table.EditableCell.fieldTypes.text,
    editableCellProps: item => {
      const defaultValue = item.initialValue;

      return {
        defaultValue,
        name: 'price',
      };
    },
    accessor: item => getActivePlan(item).price,
    validation: val => !isNaN(val) && val.slice(-1) !== '.' && val !== '' && val !== '0',
  },
  {
    id: 'discount',
    Header: 'Discount',
    Cell: props => <Table.EditableCell {...props} />,
    editFieldType: Table.EditableCell.fieldTypes.text,
    editableCellProps: item => {
      const defaultValue = item.initialValue;

      return {
        defaultValue,
        name: 'discount',
      };
    },
    accessor: item => getActivePlan(item).discount,
    validation: val => !isNaN(val) && val.slice(-1) !== '.' && val !== '',
  },
];

export const PriceList = React.memo(() => {
  const { data } = useQuery(
    ['priceList'],
    API.pricing.getList
  );
  const [plans, setPlans] = useState(data.json().plans);

  const [mutatePlan] = useMutation(API.pricing.pricingList.put);
  const handleSaveCellData = useCallback(throttle(async(original, data, value, rowIndex, columnId, isValid) => {
    updateMyData(rowIndex, columnId, value);
    if(!isValid) {
      ValidationToast.show({
        message: 'Value must be a number',
        intent: Intent.DANGER,
      });
      return;
    }
    const activePlan = getActivePlan(original);

    try {
      await mutatePlan({
        id: activePlan.id,
        data,
      });

      ValidationToast.show({
        message: 'Value has been changed',
        intent: Intent.SUCCESS,
      });
    } catch (error) {
      ValidationToast.show({
        message: error.message || 'Try again',
        intent: Intent.DANGER,
      });
    }
  }, 1000), [mutatePlan]);

  const updateMyData = (rowIndex, columnId, value) => {
    setPlans(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: plans,
      handleSaveCellData,
      updateMyData,
    }
  );


  if (plans.length === 0) {
    return (
      <NonIdealState
        icon="bookmark"
        title="No plans here"
      />
    );
  }


  return (
    <Fragment>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} >
          {rows.map(row => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <tr {...rowProps}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
});

export default PriceList;
