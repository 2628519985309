import React, { useMemo } from 'react';

import { WithSports } from 'shared/hocs';
import { Select } from 'shared/components';

const parseItems = items => items.map(item => ({
  label: item.name,
  subLabel: item.subLabel || ' ',
  value: item.id,
}));

const SportSelectField = ({
  sports,
  loading,
  ...props
}) => {
  const sportsItems = useMemo(() => parseItems(sports), [sports.length]);

  return (
    <Select
      items={sportsItems}
      itemPredicate={() => true}
      loading={loading}
      {...props}
    />
  );
};

SportSelectField.defaultProps = {
  field: {},
  form: {},
};

export default WithSports(SportSelectField);
