import { kinds } from 'views/ContentCreator/ContentCreator.const';

export const selectStrategy = (type, strategies) => {
  switch (type) {
    case kinds.article:
    case kinds.blog:
      return strategies.post;
    case kinds.newspaper_upload:
      return strategies.newspaper;
    case kinds.betting_cards:
      return strategies.bettingCard;

    default:
      return strategies.default;
  }
};
