import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AccessControl } from 'shared/components';
import Settings from './views/Settings';

const SettingsRoot = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}/:status(pricing)`}
      render={props => (
        <AccessControl
          allowedPermissions={[AccessControl.PERMISSIONS.admin, AccessControl.PERMISSIONS.technician]}
          renderNoAccess={allowedPermissions => <AccessControl.NoAccess permissionsNeeded={allowedPermissions} />}
        >
          <Settings {...props} />
        </AccessControl>
      )}
    />

    <Redirect to={`${match.url}/pricing`} />
  </Switch>
);

export default SettingsRoot;
