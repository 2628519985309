// LIST
export const ARTICLES_GET = 'ARTICLES_GET';
export const ARTICLES_GET_REQUEST = 'ARTICLES_GET_REQUEST';
export const ARTICLES_GET_SUCCESS = 'ARTICLES_GET_SUCCESS';
export const ARTICLES_GET_FAILURE = 'ARTICLES_GET_FAILURE';

// SINGLE
export const ARTICLES_SINGLE_GET = 'ARTICLES_SINGLE_GET';
export const ARTICLES_SINGLE_GET_REQUEST = 'ARTICLES_SINGLE_GET_REQUEST';
export const ARTICLES_SINGLE_GET_SUCCESS = 'ARTICLES_SINGLE_GET_SUCCESS';
export const ARTICLES_SINGLE_GET_FAILURE = 'ARTICLES_SINGLE_GET_FAILURE';

// CREATE
export const ARTICLES_POST = 'ARTICLES_POST';
export const ARTICLES_POST_REQUEST = 'ARTICLES_POST_REQUEST';
export const ARTICLES_POST_SUCCESS = 'ARTICLES_POST_SUCCESS';
export const ARTICLES_POST_FAILURE = 'ARTICLES_POST_FAILURE';

// EDIT
export const ARTICLES_PUT = 'ARTICLES_PUT';
export const ARTICLES_PUT_REQUEST = 'ARTICLES_PUT_REQUEST';
export const ARTICLES_PUT_SUCCESS = 'ARTICLES_PUT_SUCCESS';
export const ARTICLES_PUT_FAILURE = 'ARTICLES_PUT_FAILURE';

// DELETE
export const ARTICLES_DELETE = 'ARTICLES_DELETE';
export const ARTICLES_DELETE_REQUEST = 'ARTICLES_DELETE_REQUEST';
export const ARTICLES_DELETE_SUCCESS = 'ARTICLES_DELETE_SUCCESS';
export const ARTICLES_DELETE_FAILURE = 'ARTICLES_DELETE_FAILURE';

// OTHERS
export const ARTICLES_CREATE_NEW = 'ARTICLES_CREATE_NEW';
export const ARTICLES_CANCEL = 'ARTICLES_CANCEL';
export const ARTICLES_CHANGES_UNDO = 'ARTICLES_CHANGES_UNDO';
export const ARTICLES_CHANGES_UNDO_FAILURE = 'ARTICLES_CHANGES_UNDO_FAILURE';
export const ARTICLES_ADD_TO_LIST = 'ARTICLES_ADD_TO_LIST';
export const ARTICLES_REMOVE_FROM_LIST = 'ARTICLES_REMOVE_FROM_LIST';

export default {
  ARTICLES_GET,
  ARTICLES_GET_REQUEST,
  ARTICLES_GET_SUCCESS,
  ARTICLES_GET_FAILURE,
  ARTICLES_SINGLE_GET,
  ARTICLES_SINGLE_GET_REQUEST,
  ARTICLES_SINGLE_GET_SUCCESS,
  ARTICLES_SINGLE_GET_FAILURE,
  ARTICLES_POST,
  ARTICLES_POST_REQUEST,
  ARTICLES_POST_SUCCESS,
  ARTICLES_POST_FAILURE,
  ARTICLES_PUT,
  ARTICLES_PUT_REQUEST,
  ARTICLES_PUT_SUCCESS,
  ARTICLES_PUT_FAILURE,
  ARTICLES_DELETE,
  ARTICLES_DELETE_REQUEST,
  ARTICLES_DELETE_SUCCESS,
  ARTICLES_DELETE_FAILURE,
  ARTICLES_CREATE_NEW,
  ARTICLES_CANCEL,
  ARTICLES_CHANGES_UNDO,
  ARTICLES_CHANGES_UNDO_FAILURE,
  ARTICLES_ADD_TO_LIST,
  ARTICLES_REMOVE_FROM_LIST,
};
