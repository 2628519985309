import React from 'react';

import { Button } from 'shared/components';

const SidebarItem = React.memo(({ setIsOpen, isOpen, text }) => {
  return (
    <Button
      fill
      large
      minimal
      onClick={() => setIsOpen(!isOpen)}
      rightIcon={
        isOpen ?
          'chevron-up' :
          'chevron-down'
      }
      alignText="left"
    >
      {text}
    </Button>
  );
});

export default SidebarItem;
