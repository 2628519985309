import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { FormGroup, Classes } from '@blueprintjs/core';

import { DeleteItemAlert } from 'shared/alerts/articles';
import { Button } from 'shared/components';

const AdvancedSection = React.memo(({ article, archiveArticle, deleteArticle }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  return (
    <Fragment>
      {!article.archived_at && (
        <FormGroup
          inline
          label="Move to archive"
          labelFor="archive"
          className="m-b-0"
        >
          <div className="text-right">
            <Button
              small
              onClick={archiveArticle}
              intent="warning"
            >
              Archive
            </Button>
          </div>
        </FormGroup>
      )}
      {article.archived_at && (
        <Fragment>
          <FormGroup
            inline
            label="Move to trash"
            labelFor="delete"
          >
            <div className="text-right">
              <Button
                small
                onClick={() => setIsDeleteDialogOpen(true)}
                intent="danger"
              >
                Delete
              </Button>
            </div>
          </FormGroup>
          <p
            className={classNames(
              'block',
              'text-red',
              Classes.TEXT_SMALL
            )}
          >
            This action cannot be undone and will delete this article permanently
          </p>
        </Fragment>
      )}

      <DeleteItemAlert
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onConfirm={deleteArticle}
        item={article.header}
      />
    </Fragment>
  );
});

export default AdvancedSection;
