import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LOADING_STATES, GENERAL_SPORTS_GET } from 'data/constants';

import { ViewLoader } from 'shared/components';

import { getSports } from 'data/actions/general';

function WithSports(WrappedComponent) {
  class WithSportsClass extends PureComponent {
    static propTypes = {
      getSports: PropTypes.func.isRequired,
      sports: PropTypes.array,
    }
    static defaultProps = {
      sports: [],
    }

    componentDidMount() {
      this.props.getSports();
    }

    render() {
      const { state } = this.props;

      switch (state) {
        case LOADING_STATES.LOADING:
          return <ViewLoader />;
        case LOADING_STATES.LOADED:
          return <WrappedComponent {...this.props} />;
        case LOADING_STATES.FAILED:
        default:
          return null;
      }
    }
  }

  const mapStateToProps = state => ({
    state: state.general.states[GENERAL_SPORTS_GET],
    sports: state.general.sports,
  });

  const mapDispatchToProps = {
    getSports,
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithSportsClass);
}

export default WithSports;
