import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Popover, Menu, MenuItem, Button } from '@blueprintjs/core';

import { addQuery } from 'shared/helpers/url';

import styles from './SortByFilter.module.scss';

class SortByFilter extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    defaultFilterOption: PropTypes.string,
    filterName: PropTypes.string,
    sort: PropTypes.string,
  }
  static defaultProps = {
    defaultFilterOption: '-updated_at',
    filterName: 'sort',
    order: 'asc',
  }

  constructor(props) {
    super(props);

    this.state = {
      value: this.defaultFilterName,
    };
  }

  static getDerivedStateFromProps = ({ location, defaultFilterOption, filterName }) => ({
    value: location.query[filterName] || defaultFilterOption,
  })

  setFilter(filter, order) {
    const { history, location } = this.props;
    history.push(addQuery(location, {
      sort: filter,
      order: order,
    }));
  }

  getSortByOptionText() {
    const { options } = this.props;
    const { value } = this.state;
    const option = options.find(opt => opt.value === value) || options[0];
    return option.label;
  }

  render() {
    const { options, popoverProps, buttonProps } = this.props;
    const { value } = this.state;

    return (
      <Fragment>
        <span className={styles.sort}>
          Sort by
        </span>
        <Popover
          {...popoverProps}
        >
          <Button
            {...buttonProps}
            rightIcon="caret-down"
          >
            {this.getSortByOptionText()}
          </Button>
          <Menu>
            {options.map(opt => (
              <MenuItem
                key={opt.value}
                text={opt.label}
                onClick={() => this.setFilter(opt.value)}
                active={opt.value === value}
              />
            ))}
          </Menu>
        </Popover>
      </Fragment>
    );
  }
}

export default withRouter(SortByFilter);
