import * as yup from 'yup';

import { MAX_FILE_DIMENSIONS, SUPPORTED_FORMATS } from 'shared/helpers/files';

export const profileValidations = {
  bio: {
    max: 150,
  },
};

export const profileValidators = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required('First name is required'),
  last_name: yup
    .string(),
  bio: yup
    .string()
    .trim()
    .max(profileValidations.bio.max, 'Biography can\'t have more than 150 characters'),
  picture: yup
    .mixed()
    .required('Picture is required')
    .test(
      'fileSize',
      'File is too large',
      value => {
        if (value && value.size) {
          return value.size <= MAX_FILE_DIMENSIONS;
        }
        return true;
      },
    )
    .test(
      'fileFormat',
      'Unsupported file format',
      value => {
        if (value && value.type) {
          return SUPPORTED_FORMATS.includes(value.type);
        }
        return true;
      },
    ),
});
