import React from 'react';
import { Field } from 'formik';

import { TextArea } from 'shared/components';

import { validations } from 'views/Articles/Articles.validations.js';

const PreambleSection = React.memo(({}) => {
  return (
    <Field
      large
      withCounter
      labelFor="preamble"
      name="preamble"
      type="textarea"
      component={TextArea}
      helperText="Depending on the page, this will be displayed in places where quick summaries are preferable to full content."
      label="Preamble"
      maxLength={validations.preamble.max}
      textAreaProps={{
        rows: 4,
      }}
    />
  );
});

export default PreambleSection;
