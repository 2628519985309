import React from 'react';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { MenuItem, Button } from '@blueprintjs/core';

/*
  Used to enhance MenuItem to allow linking with react-router
*/
const RouterMenuItem = ({ to, text, activeOnlyWhenExact, ...props }) => (
  <Route
    path={to}
    exact={activeOnlyWhenExact}
    children={({ match, history }) => (
      <MenuItem
        active={match}
        text={text}
        onClick={() => history.push(to)}
        {...props}
      />
    )}
  />
);

/*
  Used to enhance Button to allow linking with react-router
*/
const ButtonItem = ({ to, children, ...props }) => (
  <Route
    path={to}
    children={({ history }) => (
      <Button
        onClick={() => history.push(to)}
        {...props}
      >
        {children}
      </Button>
    )}
  />
);

export default {
  RouterMenuItem: withRouter(RouterMenuItem),
  Button: ButtonItem,
};
