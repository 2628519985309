import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';
import objectToFormData from 'object-to-formdata';

import fetch from 'core/fetch';
import CONFIG from 'core/config';

export function getList() {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/betting_cards`,
    method: 'GET',
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export function put(id, data) {
  const source = CancelToken.source();
  const body = objectToFormData(data);

  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/betting_cards/${id}`,
    method: 'PUT',
    data: body,
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export default {
  getList,
  put,
};
