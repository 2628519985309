// LIST
export const NEWSPAPERS_GET = 'NEWSPAPERS_GET';
export const NEWSPAPERS_GET_REQUEST = 'NEWSPAPERS_GET_REQUEST';
export const NEWSPAPERS_GET_SUCCESS = 'NEWSPAPERS_GET_SUCCESS';
export const NEWSPAPERS_GET_FAILURE = 'NEWSPAPERS_GET_FAILURE';

// SINGLE
export const NEWSPAPERS_SINGLE_GET = 'NEWSPAPERS_SINGLE_GET';
export const NEWSPAPERS_SINGLE_GET_REQUEST = 'NEWSPAPERS_SINGLE_GET_REQUEST';
export const NEWSPAPERS_SINGLE_GET_SUCCESS = 'NEWSPAPERS_SINGLE_GET_SUCCESS';
export const NEWSPAPERS_SINGLE_GET_FAILURE = 'NEWSPAPERS_SINGLE_GET_FAILURE';

// CREATE
export const NEWSPAPERS_POST = 'NEWSPAPERS_POST';
export const NEWSPAPERS_POST_REQUEST = 'NEWSPAPERS_POST_REQUEST';
export const NEWSPAPERS_POST_SUCCESS = 'NEWSPAPERS_POST_SUCCESS';
export const NEWSPAPERS_POST_FAILURE = 'NEWSPAPERS_POST_FAILURE';

// EDIT
export const NEWSPAPERS_PUT = 'NEWSPAPERS_PUT';
export const NEWSPAPERS_PUT_REQUEST = 'NEWSPAPERS_PUT_REQUEST';
export const NEWSPAPERS_PUT_SUCCESS = 'NEWSPAPERS_PUT_SUCCESS';
export const NEWSPAPERS_PUT_FAILURE = 'NEWSPAPERS_PUT_FAILURE';

// DELETE
export const NEWSPAPERS_DELETE = 'NEWSPAPERS_DELETE';
export const NEWSPAPERS_DELETE_REQUEST = 'NEWSPAPERS_DELETE_REQUEST';
export const NEWSPAPERS_DELETE_SUCCESS = 'NEWSPAPERS_DELETE_SUCCESS';
export const NEWSPAPERS_DELETE_FAILURE = 'NEWSPAPERS_DELETE_FAILURE';

// OTHERS
export const NEWSPAPERS_CREATE_NEW = 'NEWSPAPERS_CREATE_NEW';
export const NEWSPAPERS_CANCEL = 'NEWSPAPERS_CANCEL';
export const NEWSPAPERS_CHANGES_UNDO = 'NEWSPAPERS_CHANGES_UNDO';
export const NEWSPAPERS_CHANGES_UNDO_FAILURE = 'NEWSPAPERS_CHANGES_UNDO_FAILURE';
export const NEWSPAPERS_ADD_TO_LIST = 'NEWSPAPERS_ADD_TO_LIST';

export default {
  NEWSPAPERS_GET,
  NEWSPAPERS_GET_REQUEST,
  NEWSPAPERS_GET_SUCCESS,
  NEWSPAPERS_GET_FAILURE,
  NEWSPAPERS_SINGLE_GET,
  NEWSPAPERS_SINGLE_GET_REQUEST,
  NEWSPAPERS_SINGLE_GET_SUCCESS,
  NEWSPAPERS_SINGLE_GET_FAILURE,
  NEWSPAPERS_POST,
  NEWSPAPERS_POST_REQUEST,
  NEWSPAPERS_POST_SUCCESS,
  NEWSPAPERS_POST_FAILURE,
  NEWSPAPERS_PUT,
  NEWSPAPERS_PUT_REQUEST,
  NEWSPAPERS_PUT_SUCCESS,
  NEWSPAPERS_PUT_FAILURE,
  NEWSPAPERS_DELETE,
  NEWSPAPERS_DELETE_REQUEST,
  NEWSPAPERS_DELETE_SUCCESS,
  NEWSPAPERS_DELETE_FAILURE,
  NEWSPAPERS_CREATE_NEW,
  NEWSPAPERS_CANCEL,
  NEWSPAPERS_CHANGES_UNDO,
  NEWSPAPERS_CHANGES_UNDO_FAILURE,
  NEWSPAPERS_ADD_TO_LIST,
};
