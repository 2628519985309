
import React, { useState } from 'react';
import { noop } from 'lodash';
import moment from 'moment';

import CONFIG from 'core/config';
import { DateInput } from '@blueprintjs/datetime';
import { InputGroup } from '@blueprintjs/core';

const fieldTypes = {
  date: 'date',
  text: 'text',
};

const EditableCell = ({
  cell: { value: initialValue },
  column,
  row: { original, index },
  handleSaveCellData = noop,
}) => {
  const { editableCellProps = noop, editFieldType, validation } = column;
  const fieldProps = editableCellProps({ initialValue });
  const [ inputIntent, setInputIntent ] = useState('');

  const onDateChange = value => {
    handleSaveCellData(original, {
      [fieldProps.name]: value,
    });
  };
  const onChange = e => {
    const val = e.target.value;
    let isValid = true;

    if(validation) {
      isValid = validation(val);
      isValid ? setInputIntent('') : setInputIntent('danger');
    }

    handleSaveCellData(original, {
      [fieldProps.name]: val,
    }, val, index, column.id, isValid );
  };

  switch (editFieldType) {
    case fieldTypes.date:
      return (
        <DateInput
          formatDate={date => moment(date).format(CONFIG.settings.dateDisplayFormat)}
          parseDate={str => moment(str, CONFIG.settings.dateDisplayFormat).toDate()}
          onChange={onDateChange}
          {...fieldProps}
        />
      );
    case fieldTypes.text:
      return (
        <InputGroup
          onChange={onChange}
          {...fieldProps}
          intent={inputIntent}
        />
      );

    default:
      return initialValue;
  }
};

EditableCell.fieldTypes = fieldTypes;

export default EditableCell;
