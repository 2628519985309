import React, { useEffect, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { EditorState, ContentState, convertToRaw, convertFromRaw, CompositeDecorator } from 'draft-js';
import Editor, { composeDecorators } from 'draft-js-plugins-editor';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import createVideoPlugin from 'draft-js-video-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from 'draft-js-buttons';
import { widgetPlugin as createWidgetPlugin } from 'matchday-widgets';
import 'matchday-widgets/dist/matchday-widgets.umd.css';
import { EditableText } from '@blueprintjs/core';

import 'draft-js-image-plugin/lib/plugin.css';

import CONFIG from 'core/config';
import { moveSelectionToEnd } from 'shared/helpers/editor';
import { FormikAutoSave } from 'shared/hocs';
import { UserSelectField } from 'shared/components';
import Types from 'shared/types/Articles.types';

import Separator from 'shared/components/PageHeader/components/Separator';

import {
  VideoFrame,
  createDndFileUploadPlugin,
  createPdfPlugin,
  FeaturedImageField,
  MediaUploads,
} from './components';

import styles from './ArticleView.module.scss';
import alignmentToolStyles from './AlignmentTool.module.scss';

const linkPlugin = createLinkPlugin({
  theme: styles,
  placeholder: 'http://',
});

const staticToolbarPlugin = createToolbarPlugin({
  theme: { buttonStyles: styles, toolbarStyles: {} },
});
const { Toolbar } = staticToolbarPlugin;

const focusPlugin = createFocusPlugin();
const blockDndPlugin = createBlockDndPlugin();
const alignmentPlugin = createAlignmentPlugin({
  theme: { alignmentToolStyles, buttonStyles: alignmentToolStyles },
});
const { AlignmentTool } = alignmentPlugin;

const imagePlugin = createImagePlugin({
  decorator: composeDecorators(
    focusPlugin.decorator,
    blockDndPlugin.decorator,
    alignmentPlugin.decorator,
  ),
});

const pdfPlugin = createPdfPlugin({
  decorator: composeDecorators(
    focusPlugin.decorator,
    blockDndPlugin.decorator,
    alignmentPlugin.decorator,
  ),
});

const videoPlugin = createVideoPlugin({
  videoComponent: VideoFrame,
});

const dragNDropFileUploadPlugin = createDndFileUploadPlugin({
  modifier: imagePlugin.addImage,
});

const widgetPlugin = createWidgetPlugin({
  sportxpertPublicApiKey: CONFIG.settings.sportxpertToken,
});

const plugins = [
  staticToolbarPlugin,
  imagePlugin,
  videoPlugin,
  pdfPlugin,
  alignmentPlugin,
  focusPlugin,
  blockDndPlugin,
  dragNDropFileUploadPlugin,
  linkPlugin,
  widgetPlugin,
];

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    character => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
};

const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

const decorators = [{
  strategy: findLinkEntities,
  component: Link,
}];

const saveArticle = async(
  article,
  { editorState, ...values },
  { setSubmitting, setErrors, edit },
  showToast
) => {
  try {
    const body = convertToRaw(editorState.getCurrentContent());

    await edit(
      article.slug,
      article,
      {
        ...values,
        body,
      },
      null,
      null,
      showToast
    );
  } catch (err) {
    const error = err && err.error ? err.error.description : 'Something went wrong';
    setErrors({
      _error: error,
      ...err.errors,
    });
    setSubmitting(false);
    return Promise.reject(error);
  }
  setSubmitting(false);
};

const getInitialValues = (article = {}) => {
  const { body, header, picture, preamble, signature, sport, sportxpert_id, tags } = article;
  const state = body ? convertFromRaw(body) : ContentState.createFromText('');

  return {
    user_id: article.signature.id,
    body,
    header,
    picture, preamble, signature, sport, sportxpert_id, tags,
    editorState: EditorState.createWithContent(state, new CompositeDecorator(decorators)),
  };
};

const formikEnhancer = withFormik({
  mapPropsToValues: ({ article }) => getInitialValues(article),
  validationSchema: props => props.validationSchema,
  displayName: 'CreateArticleForm',
  handleSubmit: (values, { props: { article, edit }, ...props }) =>
    saveArticle(article, values, { ...props, edit }, true),
});

// Auto Save function
const autoSaveEnhancer = FormikAutoSave({
  compare: {
    editorState: (prevValue, newValue) => {
      return prevValue.getCurrentContent().getPlainText() === newValue.getCurrentContent().getPlainText();
    },
  },
  onSave: (values, { article, edit, ...props }) => {
    return saveArticle(article, values, { ...props, edit }, false);
  },
});

const propTypes = {
  edit: PropTypes.func.isRequired,
  article: Types.articleItemShape,
};

const defaultProps = {
  initialValues: { header: '', body: '' },
};

export const Article = ({
  values, bindSubmitForm,
  handleSubmit, setFieldValue, submitForm,
}) => {
  const header = useRef({});
  const editor = useRef({});

  useEffect(() => {
    // Fix header input height
    header.current.toggleEditing();
    // Set cursor to end of editor
    setTimeout(() => {
      const newState = moveSelectionToEnd(values.editorState);
      setFieldValue('editorState', newState);
    });
    // Bind the submission handler remotely
    bindSubmitForm(() => submitForm);
  }, []);

  const focus = () => {
    editor.current.focus();
  };

  const renderToolbar = () => {
    const toolbar = (
      <Toolbar>
        {externalProps => (
          <Fragment>
            <HeadlineTwoButton {...externalProps} />
            <HeadlineThreeButton {...externalProps} />
            <Separator />
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            <UnorderedListButton {...externalProps} />
            <OrderedListButton {...externalProps} />
            <BlockquoteButton {...externalProps} />
            <linkPlugin.LinkButton {...externalProps} />
            <Separator {...externalProps} />
            <MediaUploads
              imagePlugin={imagePlugin}
              videoPlugin={videoPlugin}
              pdfPlugin={pdfPlugin}
              widgetPlugin={widgetPlugin}
              values={values}
              setFieldValue={setFieldValue}
            />
          </Fragment>
        )}
      </Toolbar>
    );

    const container = document.getElementById('articleToolbar');
    if (container) {
      return ReactDOM.createPortal(toolbar, container);
    }
    return null;
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        {renderToolbar()}
        <Field
          large
          inline
          labelFor="user_id"
          name="user_id"
          type="text"
          placeholder="Unassigned"
          component={UserSelectField}
          buttonProps={{
            minimal: true,
            small: true,
            intent: 'primary',
            alignText: 'right',
          }}
          popoverProps={{ minimal: true }}
          validateOnChange={false}
        />
        <FeaturedImageField
          allowClear={['content_check'].indexOf(values.status) === -1}
        />
        <EditableText
          multiline
          confirmOnEnterKey
          ref={header}
          className={styles.title}
          id="header"
          title="header"
          rows={1}
          placeholder="Add article title"
          type="text"
          value={values.header}
          onChange={value => setFieldValue('header', value, false)}
        />
        <div className={styles.editorWrapper} onClick={focus}>
          <Editor
            ref={editor}
            editorState={values.editorState}
            onChange={state => setFieldValue('editorState', state, false)}
            decorators={decorators}
            plugins={plugins}
          />
          <AlignmentTool />
        </div>
      </form>
    </Fragment>
  );
};

Article.propTypes = propTypes;
Article.defaultProps = defaultProps;

export default formikEnhancer(autoSaveEnhancer(Article));
