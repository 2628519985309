import {
  AUTH_LOGIN,
} from 'data/constants';
import API from 'data/fetch';

export const login = data => dispatch => {
  const promise = API.auth.login(data);

  return dispatch({
    type: AUTH_LOGIN,
    promise,
  });
};

export default {
  login,
};
