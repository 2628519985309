import React, { Fragment } from 'react';
import { Field } from 'formik';
import { Button } from '@blueprintjs/core';

import { Input, UserSelectField, ErrorBlock } from 'shared/components';

const ArticleCreator = ({ errors, isSubmitting }) => {
  return (
    <Fragment>
      <div className="col-sm-6">
        <Field
          large
          labelFor="header"
          name="header"
          type="text"
          component={Input}
          label="Title"
        />
      </div>
      <Field
        large
        withAssignSelf
        labelFor="user_id"
        name="user_id"
        type="text"
        placeholder="Unassigned"
        component={UserSelectField}
        label="Assignee"
        popoverProps={{ minimal: true }}
      />
      <br />
      <ErrorBlock
        errors={errors}
        className="m-b-20"
      />
      <Button
        large
        type="submit"
        disabled={isSubmitting}
        loading={isSubmitting}
        intent="primary"
      >
        Create draft
      </Button>
    </Fragment>
  );
};

export default ArticleCreator;
