export const selectStrategy = (type, strategies) => {
  switch (type) {
    case 'articles':
      return strategies.article;
    case 'blogs':
      return strategies.blog;

    default:
      return strategies.default;
  }
};
