import {
  LOADING_STATES,

  NEWSPAPERS_POST_REQUEST,
  NEWSPAPERS_POST_SUCCESS,
  NEWSPAPERS_POST_FAILURE,

  NEWSPAPERS_GET_REQUEST,
  NEWSPAPERS_GET_SUCCESS,
  NEWSPAPERS_GET_FAILURE,

  NEWSPAPERS_DELETE_SUCCESS,

  NEWSPAPERS_PUT_REQUEST,
  NEWSPAPERS_PUT_SUCCESS,
  NEWSPAPERS_PUT_FAILURE,

  NEWSPAPERS_CANCEL,
} from 'data/constants';

const defaultState = {
  state: LOADING_STATES.INITIAL,
  list: [],
  error: null,
};

export default function newspapersList(state = defaultState, action) {
  switch (action.type) {
    case NEWSPAPERS_POST_REQUEST:
    case NEWSPAPERS_GET_REQUEST:
    case NEWSPAPERS_PUT_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      };
    case NEWSPAPERS_POST_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        list: [
          ...state.list,
          action.req,
        ],
      };
    case NEWSPAPERS_GET_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        list: action.data,
      };
    case NEWSPAPERS_POST_FAILURE:
    case NEWSPAPERS_GET_FAILURE:
    case NEWSPAPERS_PUT_FAILURE:
      return {
        ...state,
        state: LOADING_STATES.FAILED,
        error: action.error.message,
      };
    case NEWSPAPERS_PUT_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        list: state.list.map(newspaper => newspaper.id === action.req.id
          ? action.req
          : newspaper
        ),
      };
    case NEWSPAPERS_DELETE_SUCCESS:
      return {
        ...state,
        list: state.list.filter(newspaper => newspaper.id !== action.meta.id),
      };
    case NEWSPAPERS_CANCEL:
      return defaultState;
    default:
      return state;
  }
}
