import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import FastClick from 'fastclick';
import { ReactQueryConfigProvider } from 'react-query';
import { pdfjs } from 'react-pdf/dist/entry.webpack';

import history from 'core/history';
import configureStore from 'data/store';
import sagas from 'data/sagas';

import 'shared/styles/Base.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import registerTrackingTools from './trackingTools';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Make taps on links and buttons work fast on mobiles
FastClick.attach(document.body);

// Start up the Redux Store
const initialState = {};
const store = configureStore(initialState);
store.runSagas(sagas);

const queryConfig = {
  suspense: true,
};

ReactDOM.render((
  <ReactQueryConfigProvider config={queryConfig}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </ReactQueryConfigProvider>
), document.getElementById('root'));


registerTrackingTools();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
