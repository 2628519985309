import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Formik } from 'formik';

import CreatorForm from './CreatorForm.redux';

import { addPost } from 'data/actions/posts';

import { contentValidators } from './Creator.validators';
import { kinds } from 'views/ContentCreator/ContentCreator.const';

let Creator = ({ location, addPost }) => {
  const createArticle = useCallback(async(values, actions) => {
    try {
      const { query } = location;
      const payload = {
        ...values,
        meta: {
          actions,
        },
      };

      if (query.eventId) {
        payload.sportxpert_id = query.eventId;
        payload.sportxpert_type = 'match';
      }

      await addPost(payload);

      if (values.file) {
        actions.setFieldValue('file', undefined);
      }
    } catch (err) {
      const errors = err && err.errors ? err.errors : { _error: 'Something went wrong' };
      if (errors.sportxpert_id) {
        errors._error = `This event ${errors.sportxpert_id}`;
      }
      actions.setErrors(errors);
    }
    actions.setSubmitting(false);
  }, [location.query.eventId]);

  return (
    <Fragment>
      <Helmet
        title="Content creator"
      />
      <div className="m-t-20">
        <div className="row">
          <div className="col-sm-12">
            <h2>Content creator</h2>
            <Formik
              enableReinitialize
              onSubmit={createArticle}
              validationSchema={contentValidators}
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{ header: '', type: kinds.article, newspaper_kind_id: 2 }}
              component={CreatorForm}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = {
  addPost,
};

Creator = connect(
  null,
  mapDispatchToProps
)(Creator);

export default Creator;
