import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';

import {
  LOADING_STATES,
  AUTH_GET,
  PROFILE_GET,
  AUTH_CANCEL,
} from 'data/constants';
import { SuspenseErrorBoundary } from 'shared/hocs';

import AdminRoot from './AdminRoot';
import Auth from './views/Auth';

import styles from './App.module.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.authorize();
  }

  componentWillUnmount() {
    this.props.cancelAuthorize();
  }

  authorize() {
    this.props.authorize();
  }

  render() {
    const { state } = this.props;

    switch (state) {
      case LOADING_STATES.INITIAL:
      case LOADING_STATES.FAILED:
        return (
          <div className={styles.root}>
            <Auth />
          </div>
        );
      case LOADING_STATES.LOADED:
        return (
          <SuspenseErrorBoundary>
            <AdminRoot />
          </SuspenseErrorBoundary>
        );
      case LOADING_STATES.LOADING:
        return (
          <div className={styles.root}>
            <Spinner size="150" />
          </div>
        );
      default:
        return null;
    }
  }
}

const mapStateToProps = state => ({
  state: state.general.states[PROFILE_GET],
});

const mapDispatchToProps = dispatch => ({
  authorize: () => dispatch({
    type: AUTH_GET,
  }),
  cancelAuthorize: () => dispatch({
    type: AUTH_CANCEL,
  }),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(App);
