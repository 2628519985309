import {
  PROFILE_GET_SUCCESS,
  PROFILE_PUT_SUCCESS,
  PROFILE_UPDATE,
  AUTH_LOGOUT,
} from 'data/constants';

const defaultState = {
  data: null,
};

export default function me(state = defaultState, action) {
  switch (action.type) {
    case PROFILE_GET_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case PROFILE_PUT_SUCCESS:
      return {
        ...state,
        data: action.req,
      };
    case PROFILE_UPDATE:
      return {
        ...state,
        data: action.profile,
      };
    case AUTH_LOGOUT:
      return defaultState;
    default:
      return state;
  }
}
