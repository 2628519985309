import React from 'react';
import moment from 'moment';


import './NewspaperListItem.scss';

const NewspaperListItem = ({ item, no, lastColumnElement }) => {
  const hasAppliesForDate = item.applied_from && item.applied_to;
  const displayDate = hasAppliesForDate
    ? `${moment(item.applied_from).format('DD MMM’YY')} - ${moment(item.applied_to).format('DD MMM’YY')}`
    : item.published_at ? moment(item.published_at).format('DD MMM’YY') : '';

  return (
    <div className="NewspaperListItem">
      <div>{no}.</div>
      <div>
        <a
          href={item.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.name}
        </a>
      </div>
      <div>{item.issue_number}</div>
      <div>{displayDate}</div>
      <div>
        {lastColumnElement}
      </div>
    </div>
  );
};

export default NewspaperListItem;
