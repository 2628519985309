import { all, put, call, take, takeEvery } from 'redux-saga/effects';

import {
  PROFILE_CANCEL,
  PROFILE_PUT_SUCCESS,
  PROFILE_CHANGES_UNDO,
  PROFILE_CHANGES_UNDO_FAILURE,
} from 'data/constants';

import API from 'data/fetch';

function* editProfile(action) {
  if (action.successEffect) {
    yield action.successEffect();
  }
}

function* undoAction(action) {
  try {
    // revert to previous state
    yield call(() => API.profile.put(action.profile));
    // Add article back to the list
    yield put(action.effect(action.profile));
  } catch (err) {
    yield put({
      type: PROFILE_CHANGES_UNDO_FAILURE,
      err,
      tryAgainData: {
        ...action,
      },
    });
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([
      takeEvery(PROFILE_PUT_SUCCESS, editProfile),
      takeEvery(PROFILE_CHANGES_UNDO, undoAction),
    ]);

    yield all(tasks);

    yield take(PROFILE_CANCEL);
    yield all(tasks.map(task => task.cancel()));
  }
}
