import React, { Fragment, useCallback } from 'react';
import { Collapse } from 'shared/components';

import {
  SidebarItem, PublishingSection, PreambleSection, AdvancedSection, LinkingSportXpertSection,
  CategorySection, TagsSection,
} from './components';

const SettingsItems = ({
  type, article,
  values, setFieldValue,
  openedSections = [],
  archiveArticle, deleteArticle,
}) => {
  const articleItems = (
    <Fragment>
      <li>
        <Collapse
          triggerElement={useCallback(props => <SidebarItem text="Preamble" {...props} />)}
          isOpen={openedSections.includes('PreambleSection')}
        >
          <PreambleSection />
        </Collapse>
      </li>
      <li>
        <Collapse
          triggerElement={useCallback(props => <SidebarItem text="Sport Xpert linking" {...props} />)}
          isOpen={openedSections.includes('LinkingSportXpertSection')}
        >
          <LinkingSportXpertSection
            values={values}
            setFieldValue={setFieldValue}
          />
        </Collapse>
      </li>
    </Fragment>
  );

  return (
    <Fragment>
      <li>
        <Collapse
          triggerElement={useCallback(props => <SidebarItem text="Publishing" {...props} />)}
          isOpen={openedSections.includes('PublishingSection')}
        >
          <PublishingSection />
        </Collapse>
      </li>
      {type === 'articles' && articleItems}
      <li>
        <Collapse
          triggerElement={useCallback(props => <SidebarItem text="Advanced" {...props} />)}
          isOpen={openedSections.includes('AdvancedSection')}
        >
          <AdvancedSection
            article={article}
            archiveArticle={archiveArticle}
            deleteArticle={deleteArticle}
          />
        </Collapse>
      </li>
      <li>
        <Collapse
          triggerElement={useCallback(props => <SidebarItem text="Metadata" {...props} />)}
          isOpen={openedSections.includes('CategorySection')}
        >
          <CategorySection />
          <TagsSection />
        </Collapse>
      </li>
    </Fragment>
  );
};


export default SettingsItems;
