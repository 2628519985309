import Rollbar from 'rollbar';

import CONFIG from 'core/config';

function registerRollbar() {
  const payloadScrubber = payload => {
    payload.client.javascript.plugins = [];
  };
  // eslint-disable-next-line
  new Rollbar({
    enabled: !CONFIG.isDev,
    accessToken: CONFIG.settings.rollbarToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureIp: false,
    scrubHeaders: [
      'Authorization',
    ],
    scrubFields: [
      'email',
      'password',
      'new_password',
      'first_name',
      'last_name',
      'picture',
    ],
    scrubTelemetryInputs: true,
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          guess_uncaught_frames: true,
        },
      },
      environment: 'production',
    },
    transform: payloadScrubber,
  });
}

export default function register() {
  registerRollbar();
}
