import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Creator from './views/Creator';

const ArticlesRoot = () => (
  <Switch>
    <Route path="/content-creator" component={Creator} />
  </Switch>
);

export default ArticlesRoot;
