import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';

import fetch from 'core/fetch';
import CONFIG from 'core/config';

export function login(data) {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/session`,
    method: 'POST',
    data,
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;

  return promise;
}

export function logout() {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/session`,
    method: 'DELETE',
  };

  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;

  return promise;
}

export default {
  login,
  logout,
};
