import React, { Fragment } from 'react';
import { NonIdealState, Button, Intent } from '@blueprintjs/core';
import { useTable } from 'react-table';
import { useQuery } from 'react-query';

import { Pagination } from 'shared/components';

import API from 'data/fetch';
import { QUERY_PER_PAGE } from 'data/constants';
import { ValidationToast } from 'shared/components';

const columns = [
  {
    Header: 'Name',
    columns: [
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
    ],
  },
  {
    Header: 'Info',
    columns: [
      {
        Header: 'Email',
        accessor: 'email',
      },
    ],
  },
  {
    Header: 'Actions',
    columns: [{
      Header: ' ',
      accessor: 'id',
      Cell: ({ cell: { value } }) => (
        <Button
          onClick={() => handlePasswordGeneration(value)}
          intent="primary"
        >
          Change Password
        </Button>
      ),
    }],
  },
];

const handlePasswordGeneration = async userId => {
  try {
    await API.users.generatePassword(userId);

    ValidationToast.show({
      message: 'Password has been changed and sent to the user',
      intent: Intent.SUCCESS,
    });
  } catch (error) {
    ValidationToast.show({
      message: error.message,
      intent: Intent.DANGER,
    });
  }
};

export const UserList = React.memo(({ location, match }) => {
  const { data } = useQuery(
    [
      'guest_users',
      {
        roles: 'guest',
        per_page: QUERY_PER_PAGE,
        ...location.query,
        ...match.params,
      },
    ],
    API.users.getList
  );
  const { meta, users } = data.json();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: users,
  });

  if (meta.total_count === 0) {
    return (
      <NonIdealState
        icon="bookmark"
        title="No subscribers here"
      />
    );
  }

  return (
    <Fragment>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            }
          )}
        </tbody>
      </table>

      <Pagination
        count={meta.total_count}
        limit={location.query.limit || QUERY_PER_PAGE}
      />
    </Fragment>
  );
});

export default UserList;
