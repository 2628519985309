import React from 'react';
import { RadioGroup as Group, Radio } from '@blueprintjs/core';

const RadioGroup = ({
  label, items = [], onChange,
  field,
  form,
  ...props
}) => {
  const value = field.value || '';
  const error = form.errors[field.name];

  const handleChange = value => {
    field.onChange(value);
    if (onChange) {
      onChange(value);
    }
  };

  return items.length ? (
    <Group
      label={label}
      selectedValue={String(value)}
      {...props}
      {...field}
      onChange={handleChange}
    >
      {items.map(item => (
        <Radio
          key={item.value}
          name={props.name}
          label={item.label || item.value}
          value={String(item.value)}
        />
      ))}
      {!!error && (
        <div className="text-red">{error}</div>
      )}
    </Group>
  ) : null;
};

export default RadioGroup;
