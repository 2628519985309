import React, { Fragment, useMemo, useEffect } from 'react';
import { Field } from 'formik';
import { FormGroup } from '@blueprintjs/core';

import { RadioGroup, Checkbox } from 'shared/components';

import BettingCardList from 'views/BettingCards/views/List';

const BettingCardsForm = ({ bettingCards, values, setFieldValue }) => {
  useEffect(() => {
    setFieldValue('week_day', '0', false);
    setFieldValue('kind', 'V64', false);
  }, []);

  const bettingKinds = useMemo(() => bettingCards.reduce((acc, bettingCard) => {
    if (!acc.find(item => item.value === bettingCard.kind)) {
      acc.push({
        value: bettingCard.kind,
        label: bettingCard.kind,
      });
    }

    return acc;
  }, []), [bettingCards.length]);

  return (
    <Fragment>
      <FormGroup>
        <Field
          inline
          name="kind"
          component={RadioGroup}
          label="Choose kind"
          items={bettingKinds}
        />
      </FormGroup>
      <Field
        name="jackpot"
        component={Checkbox}
        label="Jackpot"
      />

      <div className="m-t-30">
        <BettingCardList
          filters={[
            { kind: values.kind },
            { week_day: Number(values.week_day) },
            { jackpot: !!values.jackpot },
          ]}
        />
      </div>
    </Fragment>
  );
};

export default BettingCardsForm;
