import React, { Fragment, useState, useCallback } from 'react';
import { Popover, Intent, Classes } from '@blueprintjs/core';

import { Button } from 'shared/components';
import { getMoveValidationSchema } from 'views/Articles/Articles.validations';

import PublishModal from '../PublishModal';

const ContinueButton = ({
  item, postType,
  recoverFromArchive, continueToNext, saveArticle,
}) => {
  const [isPublishOverlayOpen, setIsPublishOverlayOpen] = useState(false);
  const moveValidationSchema = getMoveValidationSchema(item.status, postType);

  const moveToSpellcheck = useCallback(redirect => {
    continueToNext('spell_check', {
      status: 'spell_check',
    }, redirect);
  });

  const moveToContentCheck = redirect => {
    continueToNext('content_check', {
      status: 'content_check',
    }, redirect);
  };

  const moveToPublished = values => {
    continueToNext('approved', {
      ...values,
      status: 'approved',
    }, true);
  };

  if (item.archived_at) {
    return (
      <Popover
        popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      >
        <Button
          minimal
          intent={Intent.SUCCESS}
        >
          Restore article
        </Button>
        <div>
          <h4>Restore article</h4>
          <p>Do you want to restore this article?</p>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Button
              intent={Intent.PRIMARY}
              className={Classes.POPOVER_DISMISS}
              onClick={recoverFromArchive}
            >
              Yes, restore
            </Button>
          </div>
        </div>
      </Popover>
    );
  }

  switch (item.status) {
    case 'draft':
      return (
        <Popover
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        >
          <Button
            minimal
            intent={Intent.PRIMARY}
          >
            Continue
          </Button>
          <div>
            <h4>Move to spellcheck section</h4>
            <p>If your article's ready you can make a spell check right away or move it to “Spellcheck” section and do it later.</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
              <Button
                className="m-r-10"
                onClick={() => moveToSpellcheck(true)}
              >
                Move to spellcheck
              </Button>
              <Button
                intent={Intent.PRIMARY}
                className={Classes.POPOVER_DISMISS}
                onClick={() => moveToSpellcheck(false)}
              >
                Spellcheck now
              </Button>
            </div>
          </div>
        </Popover>
      );
    case 'spell_check':
      return (
        <Popover
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        >
          <Button
            minimal
            intent={Intent.PRIMARY}
          >
            Continue
          </Button>
          <div>
            <h4>Move to content check section</h4>
            <p>If your article was spell checked you can make a content check right away or move it to the “Content check” section and do it later.</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
              <Button
                className="m-r-10"
                onClick={() => moveToContentCheck(true)}
              >
                Move to content check
              </Button>
              <Button
                intent={Intent.PRIMARY}
                className={Classes.POPOVER_DISMISS}
                onClick={() => moveToContentCheck(false)}
              >
                Content check now
              </Button>
            </div>
          </div>
        </Popover>
      );
    case 'content_check':
      return (
        <Fragment>
          <Button
            minimal
            intent={Intent.SUCCESS}
            onClick={() => setIsPublishOverlayOpen(true)}
          >
            Ready to publish?
          </Button>
          {isPublishOverlayOpen && (
            <PublishModal
              isOpen
              onClose={() => setIsPublishOverlayOpen(false)}
              article={item}
              type={postType}
              validationSchema={moveValidationSchema}
              publish={moveToPublished}
            />
          )}
        </Fragment>
      );
    case 'approved':
      return (
        <Popover
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
        >
          <Button
            minimal
            intent={Intent.PRIMARY}
          >
            Save changes
          </Button>
          <div>
            <h4>Save changes</h4>
            <p>Do you want to save changes and make them visible to page visitors?</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
              <Button
                intent={Intent.PRIMARY}
                className={Classes.POPOVER_DISMISS}
                onClick={saveArticle}
              >
                Yes, I want to save changes
              </Button>
            </div>
          </div>
        </Popover>
      );
    default:
      return null;
  }
};

export default ContinueButton;
