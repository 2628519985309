
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import 'react-image-crop/dist/ReactCrop.css';

import UploadMedia from '../UploadMedia';
import Dropzone from '../UploadMedia/components/Dropzone';
import Confirmation from '../UploadMedia/components/Confirmation';

class UploadPDF extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onUpload: PropTypes.func,
  }
  static defaultProps = {
    isOpen: true,
    onClose: noop,
    onUpload: noop,
  }

  render() {
    const { isOpen, onClose, onUpload } = this.props;

    return (
      <UploadMedia
        isOpen={isOpen}
        onClose={onClose}
        onUpload={onUpload}
        panelStack={[{
          component: Dropzone,
          title: 'Select document',
          props: {
            accept: 'application/pdf',
            maxSize: 10000000,
          },
        }, {
          component: Confirmation,
          title: 'Confirm document',
        }]}
      />
    );
  }
}

export default UploadPDF;
