import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { UploadPDF } from 'shared/components';

export class AddMedia extends Component {
  static propTypes = {
    editorState: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.addFile = this.addFile.bind(this);
  }

  addFile(file) {
    const { modifier, editorState, onChange, onClose } = this.props;
    onChange(modifier(editorState, file));
    onClose();
  }

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <UploadPDF
        isOpen={isOpen}
        onClose={onClose}
        onUpload={this.addFile}
      />
    );
  }
}

export default AddMedia;
