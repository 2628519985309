import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';

import fetch from 'core/fetch';
import CONFIG from 'core/config';

export function getList(params) {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/users`,
    method: 'GET',
    params,
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export function get({ id }) {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/users/${id}`,
    method: 'GET',
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export const generatePassword = userId => {
  const generatedPassword = Math.random().toString(36).substring(4);

  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/users/${userId}`,
    method: 'PUT',
    params: {
      password: generatedPassword,
    },
  };

  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
};
