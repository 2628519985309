import React, { Fragment, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Label, NonIdealState } from '@blueprintjs/core';

import { DataList } from 'shared/components';
import { BettingCardListItem } from './components';

import { editBettingCard } from 'data/actions/bettingCards';

import { withFilters } from 'shared/helpers/filters';

export const BettingCardList = ({ items = [], filters, editBettingCard }) => {
  const filteredItems = useMemo(
    () => items.filter(withFilters(filters)),
    [Object.values(filters), items.length]
  );
  const handleEditBettingCard = useCallback(editBettingCard);

  return filteredItems.length ? (
    <Fragment>
      <Label>Published content</Label>
      <DataList
        list={filteredItems.map(item => ({
          key: item.id,
          component: (
            <BettingCardListItem
              item={item}
              editBettingCard={handleEditBettingCard}
            />
          ),
        }))}
      />
    </Fragment>
  ) : (
    <NonIdealState
      icon="database"
      title="No newspapers in selected category"
    />
  );
};

const mapStateToProps = state => ({
  items: state.bettingCardsList.list,
});

const mapDispatchToProps = dispatch => ({
  editBettingCard: data => dispatch(editBettingCard(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BettingCardList);
