import React, { useCallback } from 'react';
import { Checkbox as CheckboxBP } from '@blueprintjs/core';

import { inputTypes } from './Forms.types';

const propTypes = inputTypes;

const defaultProps = {
  placeholder: null,
  form: {},
  field: {},
};

export const Checkbox = ({
  placeholder,
  onChange,
  field,
  ...props
}) => {
  const handleChange = useCallback(e => {
    if (typeof field.onChange === 'function') {
      field.onChange(e);
    }

    if (typeof onChange === 'function') {
      onChange(e.target.checked);
    }
  }, []);

  return (
    <CheckboxBP
      id={field.name}
      placeholder={placeholder}
      {...field}
      {...props}
      onChange={handleChange}
    />
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
