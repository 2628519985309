import React from 'react';
import PropTypes from 'prop-types';
import { Callout, Intent } from '@blueprintjs/core';


const propTypes = {
  errors: PropTypes.object,
};

const defaultProps = {
  errors: null,
};

export const ErrorBlock = ({ errors, ...props }) => {
  if (errors && errors._error) {
    return (
      <Callout
        intent={Intent.DANGER}
        {...props}
      >
        {errors._error}
      </Callout>
    );
  }
  return null;
};

ErrorBlock.propTypes = propTypes;
ErrorBlock.defaultProps = defaultProps;

export default ErrorBlock;
