import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Divider } from '@blueprintjs/core';

import styles from './StatsOverview.module.scss';

export class StatsOverview extends Component {
  static propTypes = {
    stats: PropTypes.shape({
      total: PropTypes.number.isRequired,
      draft: PropTypes.number.isRequired,
      spell_check: PropTypes.number.isRequired,
      content_check: PropTypes.number.isRequired,
      scheduled: PropTypes.number.isRequired,
      published: PropTypes.number.isRequired,
    }).isRequired,
  }
  static defaultProps = {
    stats: {},
  }

  render() {
    const { stats, linkTo } = this.props;

    return (
      <Fragment>
        <Link
          className={classNames(styles.stat, styles.horizontal)}
          to={`/${linkTo}`}
        >
          in total
          <strong>{stats.total}</strong>
        </Link>
        <Divider />
        <Link
          className={styles.stat}
          to={`/${linkTo}/draft`}
        >
          Drafts
          <strong>{stats.draft}</strong>
        </Link>
        <Divider />
        <Link
          className={styles.stat}
          to={`/${linkTo}/spell_check`}
        >
          Spellcheck
          <strong>{stats.spell_check}</strong>
        </Link>
        <Divider />
        <Link
          className={styles.stat}
          to={`/${linkTo}/content_check`}
        >
          Content check
          <strong>{stats.content_check}</strong>
        </Link>
        <Divider />
        <Link
          className={styles.stat}
          to={`/${linkTo}/scheduled`}
        >
          Scheduled
          <strong>{stats.scheduled}</strong>
        </Link>
        <Divider />
        <Link
          className={styles.stat}
          to={`/${linkTo}/published`}
        >
          Published
          <strong>{stats.published}</strong>
        </Link>
      </Fragment>
    );
  }
}

export default StatsOverview;
