import React, { Fragment, useState } from 'react';
import { Collapse } from '@blueprintjs/core';

const CustomCollapse = ({ triggerElement, isOpen: isOpenInitially, children }) => {
  const [isOpen, setIsOpen] = useState(isOpenInitially);

  return (
    <Fragment>
      {triggerElement({ isOpen, setIsOpen })}

      <Collapse isOpen={isOpen}>
        <div className="p-t-10 p-l-15 p-b-10 p-r-15">
          {children}
        </div>
      </Collapse>
    </Fragment>
  );
};
export default CustomCollapse;
