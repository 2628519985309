import React from 'react';
import { connect } from 'react-redux';

import ArticleRoot from './ArticleRoot';

import { BLOGS_SINGLE_GET, BLOGS_CANCEL } from 'data/constants';

import { editBlog, deleteBlog, undoBlogChange } from 'data/actions/blogs';

import { MainViewHandler } from 'shared/hocs';

const ArticleContainer = ({ blog, ...props  }) => {
  return (
    <ArticleRoot
      item={blog}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  return {
    state: state.blogsSingle.state,
    blog: state.blogsSingle.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    undo: (article, effect) => dispatch(undoBlogChange(article, effect)),
    edit: (id, prevData, nextData, success, undo, showToast) => dispatch(editBlog(id, prevData, nextData, success, undo, showToast)),
    remove: post => dispatch(deleteBlog(post, true)),
    fetchData: params => dispatch({
      type: BLOGS_SINGLE_GET,
      params,
    }),
    cancelFetch: () => dispatch({
      type: BLOGS_CANCEL,
    }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainViewHandler(ArticleContainer));
