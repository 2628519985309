import React, { Fragment, useEffect } from 'react';
import { groupBy } from 'lodash';
import moment from 'moment';
import { Spinner } from '@blueprintjs/core';

import { DataList, SeasonSelectField } from 'shared/components';
import { useQuerySearching } from 'shared/hooks';
import { getList } from 'data/fetch/events.fetch';
import EventItem from '../EventItem';
import { addMonths } from 'shared/helpers/date';

const fetchAction = data => {
  const params = {
    per_page: 50,
    from: new Date().toISOString(),
    to: addMonths(new Date(), 1).toISOString(),
  };
  if (!!data.term) {
    params.seasonUuid = data.term;
    return getList(params);
  }

  return () => {};
};

const EventList = ({}) => {
  const [
    query,
    fetching,
    handleQueryChange,
  ] = useQuerySearching(fetchAction);
  const eventsByMonth = groupBy(query.results, event => moment(event.scheduled_start_date).startOf('month').format('MMMM YYYY'));

  useEffect(() => {
    handleQueryChange('');
  }, []);

  return (
    <Fragment>
      <SeasonSelectField
        label="Select season"
        placeholder="Select season"
        value={query.term}
        onChange={handleQueryChange}
      />
      {fetching ? (
        <Spinner size="150" />
      ) : (
        Object.keys(eventsByMonth).map(month => (
          <div key={month}>
            <h3 className="m-b-5">{month}</h3>
            <DataList
              list={eventsByMonth[month]
                .sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
                .map(event => ({
                  key: event.id,
                  component: (
                    <EventItem {...event} />
                  ),
                }))}
            />
          </div>
        ))
      )}
    </Fragment>
  );
};

export default EventList;
