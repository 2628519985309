import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Formik, Field } from 'formik';
import { Card, Button } from '@blueprintjs/core';

import { editProfile, undoProfileChange, updateProfile } from 'data/actions/profile';

import { Input, TextArea, ImageUploadInput, ErrorBlock } from 'shared/components';

import { profileValidators, profileValidations } from 'views/User/User.validations';

class UserSettings extends Component {
  static propTypes = {
    edit: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.editProfile = this.editProfile.bind(this);
  }

  async editProfile(values, actions) {
    const { details, edit, undo } = this.props;
    try {
      // Action to perform when undoing change
      const undoEffect = () => undo(details);

      await edit(details, values, undoEffect);
    } catch (err) {
      const error = err && err.error ? err.error.description : 'Something went wrong';
      actions.setErrors({
        _error: error,
      });
    }
    actions.setSubmitting(false);
  }

  render() {
    const { details } = this.props;

    return (
      <Fragment>
        <Helmet
          title="Details"
        />
        <div className="row">
          <div className="col-sm-7">
            <Card>
              <Formik
                enableReinitialize
                onSubmit={this.editProfile}
                validationSchema={profileValidators}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                  ...details,
                  picture: details.picture.url,
                }}
                render={({
                  values,
                  errors,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className="m-b-10">
                    <Field
                      cropCircle
                      labelFor="picture"
                      name="picture"
                      type="picture"
                      component={ImageUploadInput}
                      label="Picture"
                      placeholder="Choose your picture..."
                      width={150}
                      height={150}
                      value={values.picture}
                      error={errors.picture}
                    />
                    <div className="row">
                      <div className="col-sm-6">
                        <Field
                          large
                          className="bp3-large"
                          labelFor="first_name"
                          name="first_name"
                          type="text"
                          component={Input}
                          label="First name"
                          value={values.first_name}
                          error={errors.first_name}
                        />
                      </div>
                      <div className="col-sm-6">
                        <Field
                          large
                          className="bp3-large"
                          labelFor="last_name"
                          name="last_name"
                          type="text"
                          component={Input}
                          label="Last name"
                          value={values.last_name}
                          error={errors.last_name}
                        />
                      </div>
                    </div>
                    <Field
                      large
                      disabled
                      labelFor="email"
                      name="email"
                      type="email"
                      component={Input}
                      label="Email"
                      value={values.email}
                      error={errors.email}
                    />
                    <Field
                      large
                      withCounter
                      labelFor="bio"
                      name="bio"
                      type="text"
                      component={TextArea}
                      label="Biography"
                      maxLength={profileValidations.bio.max}
                      value={values.bio}
                      error={errors.bio}
                    />
                    <ErrorBlock
                      errors={errors}
                      className="m-b-20"
                    />
                    <Button
                      large
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      intent="primary"
                    >
                      Save profile
                    </Button>
                  </form>
                )}
              />
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  details: state.me.data,
});

const mapDispatchToProps = dispatch => ({
  undo: article =>
    dispatch(undoProfileChange(article, updateProfile)),
  edit: (prevData, nextData, undo) => dispatch(editProfile(prevData, nextData, null, undo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettings);
