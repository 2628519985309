import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Button, ButtonGroup } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { useQuery } from 'react-query';

import { ImageLabel } from 'shared/components';

import API from 'data/fetch';

function escapeRegExpChars(text) {
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'); // eslint-disable-line
}

function highlightText(text, query) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter(word => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join('|'), 'gi');
  const tokens = [];
  while (true) { // eslint-disable-line
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

export const renderUser = (user, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  const text = `${user.first_name || ''} ${user.last_name || ''}`;
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={user.id}
      onClick={handleClick}
      text={(
        <ImageLabel
          imageSrc={user.picture && user.picture.url}
          imageAlt={`${text}'s thumbnail`}
          label={highlightText(text, query)}
        />
      )}
    />
  );
};

export const filterUser = (query, user) => {
  return `${user.id}. ${(user.first_name || '').toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};

export const UserSelect = ({
  placeholder,
  loading,
  buttonProps,
  onChange,
  allowClear,
  value,
  ...props
}) => {
  const { data } = useQuery(
    [
      'admin_users',
      {
        roles: 'operator,admin',
      },
    ],
    API.users.getList
  );
  const { users } = data.json();
  const current = useMemo(() => users.find(user => user.id === parseInt(value, 10)), [value]);

  return (
    <ButtonGroup>
      <Select
        items={users}
        itemPredicate={filterUser}
        itemRenderer={renderUser}
        onItemSelect={value => onChange(value.id)}
        noResults={<MenuItem disabled text="No users found" />}
        {...props}
      >
        <Button
          rightIcon="caret-down"
          loading={loading}
          text={current ? (
            <ImageLabel
              label={`${current.first_name} ${current.last_name}`}
            />
          ) : placeholder}
          {...buttonProps}
        />
      </Select>
      {allowClear && current && (
        <Button
          icon="cross"
          onClick={e => {
            e.preventDefault();
            onChange(null);
            return false;
          }}
        />
      )}
    </ButtonGroup>
  );
};

UserSelect.propTypes = {
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  allowClear: PropTypes.bool,
};

UserSelect.defaultProps = {
  allowClear: false,
  placeholder: 'Select user...',
  onChange: null,
  loading: false,
};

export default UserSelect;
