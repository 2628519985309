import { EditorState } from 'draft-js';

import { postPicture } from 'data/fetch/media.fetch';

export default function onDropFile({ modifier }) {
  return async function onDropFileInner(selection, files, { getEditorState, setEditorState }) {
    setEditorState(EditorState.acceptSelection(getEditorState(), selection));
    let editorState = getEditorState();
    await Promise.all(files.map(async file => {
      const { data } = await postPicture(file);
      editorState = modifier(editorState, data.url);
    }));
    setEditorState(editorState);
    return;
  };
}
