import React, { Fragment, useCallback } from 'react';
import moment from 'moment';
import { FormGroup, Popover, Button } from '@blueprintjs/core';
import { DateRangePicker } from '@blueprintjs/datetime';

import { addMonths } from 'shared/helpers/date';

import { inputTypes } from '../Forms.types';

const propTypes = inputTypes;

const defaultProps = {
  placeholder: null,
  intent: 'default',
  onChange: null,
  minDate: addMonths(new Date(), -25),
  maxDate: addMonths(new Date(), 25),
};

const renderDateRanges = dates => {
  const dateFormat = 'MMM Do YYYY';
  if(!dates) return null;
  const from = dates[0] ? moment(dates[0]).format(dateFormat) : 'Today';
  const to = dates[1] ? moment(dates[1]).format(dateFormat) : 'Forever';
  return (
    <Fragment>
      {from} - {to}
    </Fragment>
  );
};

const getButtonText = dates => {
  if(!dates) return 'Select interval';
  return renderDateRanges(dates);
};

export const RangePicker = ({
  helperText,
  labelFor,
  intent,
  field,
  form,
  buttonProps,
  onChange,
  ...props
}) => {
  const error = form.errors[field.name];
  const handleChange = useCallback(values => {
    if (field.name) {
      form.setFieldValue(field.name, values);
    }

    if (typeof onChange === 'function') {
      onChange(values);
    }
  });

  return (
    <FormGroup
      helperText={error ? error : helperText}
      labelFor={labelFor}
      intent={error ? 'danger' : intent}
      {...props}
    >
      <Popover>
        <Button
          fill
          {...buttonProps}
        >
          {getButtonText(field.value)}
        </Button>
        <DateRangePicker
          id={labelFor}
          intent={error ? 'danger' : intent}
          {...props}
          {...field}
          defaultValue={new Date()}
          value={(field.value || []).length ? field.value : new Date(field.value)}
          onChange={handleChange}
        />
      </Popover>
    </FormGroup>
  );
};

RangePicker.propTypes = propTypes;
RangePicker.defaultProps = defaultProps;

export default RangePicker;
