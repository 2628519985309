import _BPEnhancers from './BlueprintJSEnhancers';
import _Forms from './Forms';
import _Filters from './Filters';
import _Dropzone from './Dropzone';
import _Articles from './Articles';
import _Toasts from './Toast';
import _Header from './Header';
import _Navigation from './Navigation';
import _DataList from './DataList';
import _Pagination from './Pagination';
import _ViewLoader from './ViewLoader';
import _PageHeader from './PageHeader';
import _ImageLabel from './ImageLabel';
import _UserSelect from './UserSelect';
import _ImageUpload from './ImageUpload';
import _Collapse from './Collapse';
import _FileButton from './FileButton';
import _ResourceListPage from './ResourceListPage';
import _AccessControl from './AccessControl';
import _Overlay from './Overlay';
import _CropAndUploadImage from './MediaUploaders/CropAndUploadImage';
import _UploadMedia from './MediaUploaders/UploadMedia';
import _UploadPDF from './MediaUploaders/UploadPDF';

export const Input = _Forms.Input;
export const TextArea = _Forms.TextArea;
export const FileInput = _Forms.FileInput;
export const Checkbox = _Forms.Checkbox;
export const Switch = _Forms.Switch;
export const Select = _Forms.SelectInput;
export const MultiSelect = _Forms.MultiSelectInput;
export const TagsSelectField = _Forms.TagsSelectField;
export const DropzoneInput = _Forms.DropzoneInput;
export const UserSelectField = _Forms.UserSelectField;
export const EventSelectField = _Forms.EventSelectField;
export const SeasonSelectField = _Forms.SeasonSelectField;
export const SportSelectField = _Forms.SportSelectField;
export const ImageUploadInput = _Forms.ImageUploadInput;
export const DatePicker = _Forms.DatePicker;
export const DateRangePicker = _Forms.DateRangePicker;
export const ErrorBlock = _Forms.ErrorBlock;
export const RadioGroup = _Forms.RadioGroup;

export const DateRangeFilter = _Filters.DateRangeFilter;
export const SortByFilter = _Filters.SortByFilter;

export const AppToast = _Toasts.AppToast;
export const ValidationToast = _Toasts.ValidationToast;

export const ArticleItem = _Articles.ArticleItem;
export const ArticleActions = _Articles.ArticleActions;
export const ArticleStatusLabel = _Articles.ArticleStatusLabel;

export const RouterMenuItem = _BPEnhancers.RouterMenuItem;
export const Button = _BPEnhancers.Button;

export const Header = _Header;
export const Navigation = _Navigation;
export const ViewLoader = _ViewLoader;
export const DataList = _DataList;
export const Pagination = _Pagination;
export const PageHeader = _PageHeader;
export const ImageLabel = _ImageLabel;
export const UserSelect = _UserSelect;
export const Dropzone = _Dropzone;
export const ImageUpload = _ImageUpload;
export const Collapse = _Collapse;
export const FileButton = _FileButton;
export const CropAndUploadImage = _CropAndUploadImage;
export const UploadMedia = _UploadMedia;
export const UploadPDF = _UploadPDF;
export const ResourceListPage = _ResourceListPage;
export const AccessControl = _AccessControl;
export const Overlay = _Overlay;

export { default as Table } from './Table';
export { default as ErrorFallback } from './ErrorFallback';

export default {
  ..._BPEnhancers,
  ..._Forms,
  ..._Filters,
  ..._Articles,
  ..._Toasts,
  Header,
  Navigation,
  ViewLoader,
  DataList,
  Pagination,
  PageHeader,
  ImageLabel,
  UserSelect,
  Dropzone,
  ImageUpload,
  CropAndUploadImage,
  UploadMedia,
  UploadPDF,
  ResourceListPage,
  AccessControl,
  Overlay,
};
