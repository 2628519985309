import React, { Fragment, useCallback } from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router';
import { OverflowList, Popover, Menu, Button, Boundary, Position } from '@blueprintjs/core';
import { NavLink } from 'react-router-dom';

import { PageHeader, RouterMenuItem, SortByFilter } from 'shared/components';

import SearchForm from './components/SearchForm';

import { removeQuery, getParentUrl } from 'shared/helpers/url';

import styles from './ResourceListPage.module.scss';

export let ResourceListPage = ({
  title, overviewItems = [], sortBy,
  headerLeftElement = <div />, headerRightElement, searchElement, children,
  location, match, defaultSearchElementHidden,
}) => {
  const getDestinationUrl = useCallback(key => {
    let path = { ...location };
    path = removeQuery(path, ['page']);
    path.pathname = key === 'all' ? getParentUrl(match.url, 1) : `${getParentUrl(match.url, 1)}/${key}`;
    return path;
  });

  return (
    <Fragment>
      <Helmet
        title={title}
      />
      <PageHeader className="between-xs m-b-20">
        {headerLeftElement}
        {headerRightElement}
      </PageHeader>
      {!!overviewItems.length && (
        <div className="row m-b-10">
          <div className="col-xs-12">
            <OverflowList
              className="middle-xs"
              collapseFrom={Boundary.END}
              overflowRenderer={items => (
                <Popover>
                  <Button
                    minimal
                    className="m-l-10"
                    icon="more"
                  />
                  <Menu>
                    {items.map(item => (
                      <RouterMenuItem
                        activeOnlyWhenExact
                        key={item.key}
                        to={getDestinationUrl(item.key)}
                        text={item.title}
                      />
                    ))}
                  </Menu>
                </Popover>
              )}
              visibleItemRenderer={item => {
                const isActive = () => (match.params.tab === item.key)
                || (item.key === 'all' && !match.params.tab);

                return (
                  <NavLink
                    key={item.key}
                    to={getDestinationUrl(item.key)}
                    className={styles.tab}
                    activeClassName={styles.active}
                    id={item.key}
                    isActive={isActive}
                  >
                    {item.title}
                  </NavLink>
                );
              }}
              items={overviewItems}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-8">
          <div className="row">
            {searchElement}
            {!defaultSearchElementHidden &&  <SearchForm className="m-l-15" />}
          </div>
        </div>
        {sortBy && (
          <div className="col-sm-4 text-right">
            <SortByFilter
              options={sortBy}
              popoverProps={{
                position: Position.BOTTOM_RIGHT,
              }}
            />
          </div>
        )}
      </div>

      <div className={styles.content}>
        {children}
      </div>
    </Fragment>
  );
};

ResourceListPage = withRouter(ResourceListPage);

export default ResourceListPage;
