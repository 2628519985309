import React, { useState, Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import moment from 'moment';
import classNames from 'classnames';
import { ButtonGroup, Spinner, Intent, Callout, Tag } from '@blueprintjs/core';

import { openNavigation, closeNavigation } from 'data/actions/general';
import Types from 'shared/types/Articles.types';

import { PageHeader, Button, ArticleStatusLabel, ValidationToast } from 'shared/components';
import { WithSports } from 'shared/hocs';
import Separator from 'shared/components/PageHeader/components/Separator';

import ArticleView from './ArticleView';
import { ContinueButton, Sidebar } from './components';
import { sections as SidebarSections } from './components/Sidebar';

import { getEditValidationSchema, getMoveValidationSchema } from 'views/Articles/Articles.validations';
import { selectStrategy } from 'views/Articles/Articles.utils';

import styles from './ArticleView.module.scss';

const propTypes = {
  openNav: PropTypes.func.isRequired,
  closeNav: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  item: Types.articleItemShape,
};

export let Article = ({
  item,
  match, history,
  edit, undo, remove,
  openNav, closeNav,
}) => {
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [lastAutoSaved, setLastAutoSaved] = useState(null);
  const [autoSaveError, setAutoSaveError] = useState(null);
  const [openedSidebarSections, setOpenedSidebarSections] = useState(null);
  const [submitArticleForm, setSubmitArticleForm] = useState(() => {});

  const type = useMemo(() => match.params.type, [match.params.type]);
  const editValidationSchema = useMemo(() => getEditValidationSchema(item.status, type), [item.status, type]);
  const moveValidationSchema = useMemo(() => getMoveValidationSchema(item.status, type), [item.status, type]);

  const openSidebarSection = section => {
    const newSections = openedSidebarSections
      ? [
        ...openedSidebarSections,
        section,
      ]
      : [section];

    setOpenedSidebarSections(newSections);
  };
  useEffect(() => {
    closeNav();

    return openNav;
  }, []);

  const continueToNext = async(status = 'all', data = {}, redirect = false) => {
    const successEffect = () => {
      let tab = status;
      if (status === 'approved') {
        if (moment(data.published_at).isAfter()) {
          tab = 'scheduled';
        } else {
          tab = 'published';
        }
      }
      redirect && history.push(`/${type}/${tab}`);
    };
    const undoEffect = () => undo(item, () => {
      redirect && history.replace(`/${type}/${item.slug}`);
    });
    let valid = false;

    try {
      valid = await moveValidationSchema.validate({
        ...item,
        ...data,
      });
    } catch(err) {
      ValidationToast.show({
        message: err.message,
        intent: Intent.DANGER,
      });
      Promise.reject(err);
    }

    if (valid) {
      try {
        await edit(
          item.slug,
          item,
          data,
          successEffect,
          undoEffect,
          true
        );
      } catch (err) {
        Promise.reject(err);
      }
    }
  };

  const recoverFromArchive = async() => {
    const undoEffect = () => undo(item);

    try {
      await edit(
        item.slug,
        item,
        {
          ...item,
          archived_at: null,
        },
        null,
        undoEffect,
        true
      );
    } catch (err) {
      Promise.reject(err);
    }
  };

  const saveArticle = e => {
    if (submitArticleForm) {
      submitArticleForm(e);
    }
  };

  return (
    <div className={styles.root}>
      <Helmet
        title="Create article"
      />
      <PageHeader fixed>
        <Button
          minimal
          to={`/${type}`}
          icon="arrow-left"
        >
          Return to all {type}
        </Button>
        <Separator />
        <div id="articleToolbar" />
        {!item.archived_at && (
          <Button
            to={`/preview?page=https://beta.vitippa.se/&return=/articles/${item.slug}`}
            target="_blank"
            rel="noopener noreferrer"
            className="m-l-10"
            icon="share"
          >
            Preview
          </Button>
        )}
        <div className={classNames(styles.actions, 'align-right')}>
          <div className={classNames(
            styles.savingStatus,
            'm-r-20', {
              [styles.success]: !isAutoSaving && lastAutoSaved && !autoSaveError,
              [styles.error]: autoSaveError,
            }
          )}
          >
            {isAutoSaving ?
              <Fragment><Spinner size="15" className="m-r-10" /> Saving changes...</Fragment> :
              autoSaveError ?
                `Error while saving: ${autoSaveError}` :
                lastAutoSaved ?
                  `Saved ${moment(lastAutoSaved).fromNow()}` :
                  null
            }
          </div>
          <div className="m-r-20">
            <ArticleStatusLabel
              status={item.status}
              publishedAt={item.published_at}
              archiveAt={item.archived_at}
            />
          </div>
          <ButtonGroup>
            <ContinueButton
              item={item}
              postType={type}
              recoverFromArchive={recoverFromArchive}
              continueToNext={continueToNext}
              saveArticle={saveArticle}
            />
            <Button
              minimal
              onClick={() => setOpenedSidebarSections([])}
              icon="cog"
            />
          </ButtonGroup>
        </div>
      </PageHeader>
      <div className={styles.contentWrapper}>
        <div className="row">
          <div className="col-md-6">
            <div className="m-b-20">
              <h4 className="in-block">
                {selectStrategy(type, {
                  article: 'Article',
                  blog: 'Blog post',
                })}
              </h4>
              <Tag
                round
                interactive
                className="m-l-20"
                onClick={() => openSidebarSection(SidebarSections.CategorySection)}
              >
                {item.sport ? item.sport.name : 'Select category'}
              </Tag>
            </div>
            <ArticleView
              article={item}
              validationSchema={editValidationSchema}
              edit={edit}
              setIsAutoSaving={setIsAutoSaving}
              setLastAutoSaved={setLastAutoSaved}
              setAutoSaveError={setAutoSaveError}
              bindSubmitForm={setSubmitArticleForm}
              autoSavingEnabled={item.status !== 'published'}
            />
          </div>
          <div className="col-md-4 col-md-offset-2">
            {item.status === 'published' && (
              <Callout
                intent={Intent.WARNING}
                icon="warning-sign"
                title="Autosaving and preview are disabled"
              >
                This article is published and visible to visitors of your page.
                We disabled automatic saving so you can make changes to this article
                before they become available to visitors.
              </Callout>
            )}
          </div>
        </div>
      </div>
      <Sidebar
        openedSections={openedSidebarSections}
        onClose={() => setOpenedSidebarSections(null)}
        type={type}
        edit={edit}
        undo={undo}
        remove={remove}
        article={{
          ...item,
          user_id: item.signature.id,
        }}
      />
    </div>
  );
};

Article.propTypes = propTypes;

const mapDispatchToProps = {
  openNav: openNavigation,
  closeNav: closeNavigation,
};

Article = WithSports(Article);

Article = connect(
  null,
  mapDispatchToProps
)(Article);

export default Article;
