
import React from 'react';
import classNames from 'classnames';

import styles from './PageHeader.module.scss';

const PageHeader = ({ className = '', children, fixed = false }) => (
  <header
    className={classNames(styles.root, className, {
      [styles.fixed]: fixed,
    })}
  >
    {children}
  </header>
);

export default PageHeader;
