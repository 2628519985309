import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AccessControl } from 'shared/components';

import List from './views/List';

const SubscribersRoot = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}/:status(active|disabled)`}
      render={props => (
        <AccessControl
          allowedPermissions={[AccessControl.PERMISSIONS.admin, AccessControl.PERMISSIONS.technician]}
          renderNoAccess={allowedPermissions => <AccessControl.NoAccess permissionsNeeded={allowedPermissions} />}
        >
          <List {...props} />
        </AccessControl>
      )}
    />

    <Redirect to={`${match.url}/active?page=1`} />
  </Switch>
);

export default SubscribersRoot;
