// GET
export const PROFILE_GET = 'PROFILE_GET';
export const PROFILE_GET_REQUEST = 'PROFILE_GET_REQUEST';
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';
export const PROFILE_GET_FAILURE = 'PROFILE_GET_FAILURE';

// EDIT
export const PROFILE_PUT = 'PROFILE_PUT';
export const PROFILE_PUT_REQUEST = 'PROFILE_PUT_REQUEST';
export const PROFILE_PUT_SUCCESS = 'PROFILE_PUT_SUCCESS';
export const PROFILE_PUT_FAILURE = 'PROFILE_PUT_FAILURE';

// PASSWORD
export const PROFILE_PASSWORD_PUT = 'PROFILE_PASSWORD_PUT';
export const PROFILE_PASSWORD_PUT_REQUEST = 'PROFILE_PASSWORD_PUT_REQUEST';
export const PROFILE_PASSWORD_PUT_SUCCESS = 'PROFILE_PASSWORD_PUT_SUCCESS';
export const PROFILE_PASSWORD_PUT_FAILURE = 'PROFILE_PASSWORD_PUT_FAILURE';

// OTHERS
export const PROFILE_CANCEL = 'PROFILE_CANCEL';
export const PROFILE_CHANGES_UNDO = 'PROFILE_CHANGES_UNDO';
export const PROFILE_CHANGES_UNDO_FAILURE = 'PROFILE_CHANGES_UNDO_FAILURE';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';

export default {
  PROFILE_GET,
  PROFILE_GET_REQUEST,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAILURE,
  PROFILE_PUT,
  PROFILE_PUT_REQUEST,
  PROFILE_PUT_SUCCESS,
  PROFILE_PUT_FAILURE,
  PROFILE_PASSWORD_PUT,
  PROFILE_PASSWORD_PUT_REQUEST,
  PROFILE_PASSWORD_PUT_SUCCESS,
  PROFILE_PASSWORD_PUT_FAILURE,
  PROFILE_CANCEL,
  PROFILE_CHANGES_UNDO,
  PROFILE_CHANGES_UNDO_FAILURE,
  PROFILE_UPDATE,
};
