import _Details from './Details';
import _Password from './Password';

export const Details = _Details;
export const Password = _Password;

export default {
  Details,
  Password,
};
