import { Position, Toaster } from '@blueprintjs/core';

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToast = Toaster.create({
  position: Position.TOP,
  usePortal: true,
});

/** Singleton toaster instance. Create separate instances for different options. */
export const ValidationToast = Toaster.create({
  position: Position.TOP_RIGHT,
  usePortal: true,
});

export default {
  AppToast,
  ValidationToast,
};
