import { AppToast } from 'shared/components';

import {
  PROFILE_PUT_SUCCESS,
  PROFILE_PUT_FAILURE,
  PROFILE_PASSWORD_PUT_SUCCESS,
  ARTICLES_PUT_SUCCESS,
  ARTICLES_PUT_FAILURE,
  ARTICLES_DELETE_FAILURE,
  ARTICLES_CHANGES_UNDO_FAILURE,
} from 'data/constants';

export default function notificationMiddleware({ dispatch }) {
  return next => ({ showToast = true, ...action }) => {
    let options;

    if (showToast) {
      switch (action.type) {
        case PROFILE_PUT_SUCCESS:
          options = {
            message: 'Profile updated successfully!',
            intent: 'SUCCESS',
            action: {
              onClick: action.undoEffect,
              text: 'Undo',
            },
          };
          break;
        case PROFILE_PASSWORD_PUT_SUCCESS:
          options = {
            message: 'Password updated successfully!',
            intent: 'SUCCESS',
          };
          break;
        case ARTICLES_PUT_SUCCESS:
          options = {
            message: 'Article updated successfully!',
            intent: 'SUCCESS',
            action: {
              onClick: action.undoEffect,
              text: 'Undo',
            },
          };
          break;
        case ARTICLES_CHANGES_UNDO_FAILURE:
          options = {
            message: 'Ups, something went wrong when undoing your changes',
            intent: 'DANGER',
            action: {
              onClick: () => dispatch(action.tryAgainData),
              text: 'Try again',
            },
          };
          break;
        case PROFILE_PUT_FAILURE:
        case ARTICLES_PUT_FAILURE:
        case ARTICLES_DELETE_FAILURE:
          options = {
            message: 'Ups, something went wrong.',
            intent: 'DANGER',
          };
          if (action.tryAgain) {
            options.action = {
              onClick: action.tryAgain,
              text: 'Try again',
            };
          }
          break;
        default:
          options = null;
      }

      if (options) {
        AppToast.show(options);
      }
    }

    next(action);
  };
}
