import React, { Fragment, useCallback } from 'react';
import { NonIdealState, Intent } from '@blueprintjs/core';
import { useTable, useExpanded } from 'react-table';
import moment from 'moment';
import { useQuery, useMutation } from 'react-query';
import { throttle } from 'lodash';

import { Pagination, Table, ValidationToast } from 'shared/components';

import CONFIG from 'core/config';
import API from 'data/fetch';
import { QUERY_PER_PAGE } from 'data/constants';

const getActiveSubscription = item => {
  try {
    return item.subscriptions[0];
  } catch (error) {
    return {};
  }
};

const getJoinedContentCategories = item => {
  try {
    return getActiveSubscription(item).content_categories
      .map(category => category.name)
      .join(' + ');
  } catch (error) {
    return '';
  }
};

const columns = [
  {
    Header: () => null,
    id: 'expander',
    Cell: ({ row }) => {
      const activeSubscription = getActiveSubscription(row.original);

      return activeSubscription.address ? (
        <span {...row.getExpandedToggleProps()}>
          {row.isExpanded ? '👇' : '👉'}
        </span>
      ) : null;
    },
  },
  {
    Header: 'User',
    columns: [
      {
        Header: 'First Name',
        accessor: 'first_name',
      },
      {
        Header: 'Last Name',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
    ],
  },
  {
    Header: 'Subscription',
    columns: [
      {
        Header: 'Price (SEK/net)',
        accessor: 'subscriptions[0].total',
      },
      {
        id: 'subscription_category',
        Header: 'Category',
        accessor: item => getJoinedContentCategories(item),
      },
    ],
  },
  {
    Header: 'Validity',
    columns: [
      {
        id: 'created_at',
        Header: 'Start',
        accessor: item => moment(getActiveSubscription(item).created_at)
          .format(CONFIG.settings.dateDisplayFormat),
      },
      {
        id: 'expired_at',
        Header: 'End',
        Cell: props => props.cell.value ? <Table.EditableCell {...props} /> : 'Unknown',
        editFieldType: Table.EditableCell.fieldTypes.date,
        editableCellProps: item => {
          const defaultValue = new Date(item.initialValue);

          return {
            defaultValue,
            name: 'expired_at',
            maxDate: new Date((defaultValue.getFullYear() + 10).toString()),
          };
        },
        accessor: item => getActiveSubscription(item).expired_at,
      },
    ],
  },
];

export const SubscribesList = React.memo(({ location, match }) => {
  const { data } = useQuery(
    [
      'subscribers',
      {
        per_page: QUERY_PER_PAGE,
        ...location.query,
        ...match.params,
      },
    ],
    API.subscribers.getList
  );
  const { meta, users } = data.json();

  const [mutateSubscription] = useMutation(API.subscribers.subscriptions.put);
  const handleSaveCellData = useCallback(throttle(async(original, data) => {
    const activeSubscription = getActiveSubscription(original);

    try {
      await mutateSubscription({
        id: activeSubscription.id,
        data,
      }, {
        refetchQueries: ['subscribers'],
      });

      ValidationToast.show({
        message: 'End date has been changed',
        intent: Intent.SUCCESS,
      });
    } catch (error) {
      ValidationToast.show({
        message: error.message || 'Try again',
        intent: Intent.DANGER,
      });
    }
  }, 1000), [mutateSubscription]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    flatColumns,
  } = useTable(
    {
      columns,
      data: users,
      handleSaveCellData,
    },
    useExpanded
  );

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const activeSubscription = getActiveSubscription(row.original);
    const hasAddress = !!activeSubscription.address;

    return hasAddress && row.isExpanded ? (
      <tr>
        <td colSpan={flatColumns.length}>
          <div>
            {activeSubscription.address.street}
            <br />
            {activeSubscription.address.postal_code} {activeSubscription.address.city}
          </div>
        </td>
      </tr>
    ) : null;
  }, []);

  if (meta.total_count === 0) {
    return (
      <NonIdealState
        icon="bookmark"
        title="No subscribers here"
      />
    );
  }

  return (
    <Fragment>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <Fragment key={rowProps.key}>
                <tr {...rowProps}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
                {renderRowSubComponent({ row })}
              </Fragment>
            );
          })}
        </tbody>
      </table>

      <Pagination
        count={meta.total_count}
        limit={location.query.limit || QUERY_PER_PAGE}
      />
    </Fragment>
  );
});

export default SubscribesList;
