import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Card, Intent } from '@blueprintjs/core';

import { DASHBOARD_GET, DASHBOARD_CANCEL } from 'data/constants';

import Types from 'shared/types/Articles.types';

import { MainViewHandler } from 'shared/hocs';

import { DataList, ArticleItem, Button } from 'shared/components';

import StatsOverview from './components/StatsOverview';
import EventList from './components/EventList';

import styles from './Dashboard.module.scss';

export class Dashboard extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    fetchData: PropTypes.func.isRequired,
    cancelFetch: PropTypes.func.isRequired,
    articles: PropTypes.arrayOf(PropTypes.shape(Types.articlesItemProperties)).isRequired,
  }

  render() {
    const { overview, articles } = this.props;

    return (
      <Fragment>
        <Helmet
          title="Dashboard"
        />
        <div className="row">
          <div className="col-xs-12">
            <Card className={classNames(styles.overview, 'm-b-20')}>
              <h2 className="m-b-0">Articles overview</h2>
              <div className={styles.stats}>
                <StatsOverview linkTo="articles" stats={overview.articles} />
              </div>
            </Card>
          </div>
          <div className="col-xs-12">
            <Card className={classNames(styles.overview, 'm-b-20')}>
              <h2 className="m-b-0">Blogs overview</h2>
              <div className={styles.stats}>
                <StatsOverview linkTo="blogs" stats={overview.blog_posts} />
              </div>
            </Card>
          </div>
          <div className="col-md-6">
            <Card>
              <div className="row">
                <div className="col-xs-8">
                  <h2>Upcoming events</h2>
                </div>
              </div>
              <EventList />
            </Card>
          </div>
          <div className="col-md-6">
            <Card>
              <div className="row">
                <div className="col-xs-8">
                  <h2>Articles to be published</h2>
                </div>
                <div className="col-xs-4 end-xs">
                  <Button
                    minimal
                    to="/articles"
                    intent={Intent.PRIMARY}
                  >
                    View all
                  </Button>
                </div>
              </div>
              <DataList
                list={articles
                  .filter(article => article.status !== 'approved')
                  .map(article => ({
                    key: article.slug,
                    component: (
                      <ArticleItem {...article} />
                    ),
                  }))}
              />
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  state: state.dashboard.state,
  articles: state.dashboard.articles,
  overview: state.overview.data,
});

const mapDispatchToProps = dispatch => ({
  fetchData: params => dispatch({
    type: DASHBOARD_GET,
    params,
  }),
  cancelFetch: () => dispatch({
    type: DASHBOARD_CANCEL,
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainViewHandler(Dashboard));
