import React from 'react';

import { MultiSelect } from 'shared/components';

const items = [ 'v64', 'v86', 'v75', 'gs75' ];

const TagsSelectField = props => {
  return (
    <MultiSelect
      items={items}
      createNewItemFromQuery={null}
      createNewItemRenderer={null}
      {...props}
    />
  );
};

TagsSelectField.defaultProps = {
  field: {},
  form: {},
};

export default TagsSelectField;
