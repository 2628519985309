import {
  PROFILE_PUT,
  PROFILE_PASSWORD_PUT,
  PROFILE_UPDATE,
  PROFILE_CHANGES_UNDO,
} from 'data/constants';
import API from 'data/fetch';

export const editProfile = (prevData, nextData, successEffect = null, undoEffect = null, showToast = true) => dispatch => {
  const promise = API.profile.put(nextData);

  return dispatch({
    type: PROFILE_PUT,
    promise,
    prevData,
    successEffect,
    undoEffect,
    showToast,
  });
};

export const editPassword = data => dispatch => {
  const promise = API.profile.putPassword(data);

  return dispatch({
    type: PROFILE_PASSWORD_PUT,
    promise,
  });
};

export const undoProfileChange = (profile, effect) => dispatch => dispatch({
  type: PROFILE_CHANGES_UNDO,
  profile,
  effect,
});

export const updateProfile = profile => dispatch => dispatch({
  type: PROFILE_UPDATE,
  profile,
});

export default {
  editProfile,
  editPassword,
  undoProfileChange,
  updateProfile,
};
