import React, { Component } from 'react';
import { PanelStack } from '@blueprintjs/core';

import 'react-image-crop/dist/ReactCrop.css';

import styles from '../../UploadMedia.module.scss';

class Stack extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 100,
    };

    this.panel = React.createRef();

    this.calculateHeight = this.calculateHeight.bind(this);
  }

  componentDidMount() {
    this.calculateHeight();
  }

  componentDidUpdate() {
    this.calculateHeight();
  }

  sumChildrenHeights(element, selector) {
    const borderCount = 2;
    const nodeList = element.querySelectorAll(selector);
    const addHeights = (sum, el) => sum + (!el ? 0 : el.clientHeight) + borderCount;
    return Array.prototype.slice.call(nodeList).reduce(addHeights, 0);
  }

  // Called by PanelStack hooks and also on each render
  calculateHeight(panelAddedOrRemoved) {
    if (!this.panel.current) return;

    const { height } = this.state;

    // Select children and count their height
    const childrenSelector = '.bp3-panel-stack-view:not(.bp3-panel-stack-exit) > *';
    const contentHeight = this.sumChildrenHeights(this.panel.current, childrenSelector);
    // Conditionally setHeight to avoid infinite loop
    const triggeredByCallback = Boolean(panelAddedOrRemoved);
    const heightChanged = height !== contentHeight;

    if (triggeredByCallback || heightChanged) {
      this.setState({
        height: contentHeight,
      });
    }
  }

  render() {
    const { panelStack } = this.props;
    const { height } = this.state;

    return (
      <div
        ref={this.panel}
        className={styles.stack}
        style={{ height: `${height}px` }}
      >
        <PanelStack
          onOpen={this.calculateHeight}
          onClose={this.calculateHeight}
          initialPanel={{
            component: panelStack[0].component,
            title: panelStack[0].title,
            props: {
              ...this.props,
              ...panelStack[0].props,
              resize: this.calculateHeight,
            },
          }}
        />
      </div>
    );
  }
}

export default Stack;
