import React, { Fragment } from 'react';
import { NonIdealState, Intent } from '@blueprintjs/core';
import { Button } from 'shared/components';

const defaultProps = {
  icon: 'applications',
  title: 'This page does not exist',
  desc: (
    <Fragment>
      <p>The page your are trying to access does not exist.</p>
      <p>Press the "Go to dashboard" button bellow <br /> to go back to the start</p>
    </Fragment>
  ),
  path: '/',
  buttonText: 'Go to dashboard',
};

const ErrorFallback = ({ icon, title, desc, path, buttonText }) => {
  return (
    <NonIdealState
      icon={icon}
      title={title}
      description={desc}
      action={(
        <Button
          large
          to={path}
          intent={Intent.PRIMARY}
        >
          {buttonText}
        </Button>
      )}
    />
  );
};

ErrorFallback.defaultProps = defaultProps;
export default ErrorFallback;
