
import React from 'react';

import styles from './Separator.module.scss';

const Separator = () => (
  <div className={styles.root} />
);

export default Separator;
