import React from 'react';
import { Spinner } from '@blueprintjs/core';

import styles from './ViewLoader.module.scss';

const Loader = () => (
  <div className={styles.root}>
    <Spinner size="80" />
  </div>
);

export default Loader;
