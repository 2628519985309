import React from 'react';
import { Field } from 'formik';
import { TagsSelectField } from 'shared/components';

const TagsSection = ({ ...props }) => {
  return (
    <Field
      name="tags"
      component={TagsSelectField}
      label="Select tags"
      placeholder="Select tags"
      {...props}
    />
  );
};

export default TagsSection;
