import React, { useMemo } from 'react';
import { Field } from 'formik';
import { FormGroup } from '@blueprintjs/core';

import { RadioGroup } from 'shared/components';
import { ArticleCreator, NewspaperUploader, BettingCardsForm } from './components';

import { selectStrategy } from './Creator.utils';
import { kinds, kindsLabels } from 'views/ContentCreator/ContentCreator.const';

const Content = React.memo(({ kind, ...props }) => {
  return selectStrategy(kind, {
    post: <ArticleCreator {...props} />,
    newspaper: <NewspaperUploader {...props} />,
    bettingCard: <BettingCardsForm {...props} />,
    default: null,
  });
});

const kindsItems = Object.values(kinds).map(kind => ({
  label: kindsLabels[kind],
  value: kind,
}));

const CreatorForm = ({ categories, handleSubmit, values, ...props }) => {
  const { type: selectedType } = values;
  const categoryKey = useMemo(() => selectStrategy(selectedType, {
    post: 'sport_id',
    newspaper: 'newspaper_kind_id',
    bettingCard: 'week_day',
  }), [selectedType]);

  const onCategoryChange = useMemo(() => selectStrategy(selectedType, {
    newspaper: () => {
      props.setFieldValue('applied_from', undefined);
      props.setFieldValue('applied_to', undefined);
      props.setFieldValue('applied_for', undefined);
      props.setFieldValue('issue_number', undefined);
      props.setFieldValue('file', undefined);
      props.setFieldValue('published_at', undefined);
    },
  }), [selectedType]);

  return (
    <form onSubmit={handleSubmit} className="m-b-10">
      <FormGroup>
        <Field
          inline
          name="type"
          component={RadioGroup}
          label="Choose kind"
          items={kindsItems}
        />
      </FormGroup>
      <FormGroup>
        <Field
          inline
          name={categoryKey}
          component={RadioGroup}
          label="Choose category"
          onChange={onCategoryChange}
          items={categories}
        />
      </FormGroup>

      <Content
        kind={selectedType}
        categoryKey={categoryKey}
        categories={categories}
        values={values}
        {...props}
      />
    </form>
  );
};

export default CreatorForm;
