import { take, takeLatest, cancel } from 'redux-saga/effects';

import { NEWSPAPERS_GET, NEWSPAPERS_CANCEL } from 'data/constants';

import { getNewspapersList } from './newspapers.saga';

export default function* main() {
  while (true) {
    const task = yield takeLatest([NEWSPAPERS_GET], getNewspapersList);

    yield take(NEWSPAPERS_CANCEL);
    yield cancel(task);
  }
}
