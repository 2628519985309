import React, { useMemo } from 'react';
import { Route } from 'react-router';
import { useQuery } from 'react-query';

import { ResourceListPage, Button } from 'shared/components';
import { SuspenseErrorBoundary } from 'shared/hocs';
import API from 'data/fetch';
import { downloadCSV } from 'shared/helpers/csv';

import SportFilter from './components/SportFilter';
import SubscribesList from './SubscribersList';

export const SubscribesListRoot = ({ match }) => {
  const { data } = useQuery(
    [
      'all_subscribers',
      {
        per_page: 999999999,
        status: 'active',
      },
    ],
    API.subscribers.getList,
    {
      manual: true,
    }
  );
  const { users } = data.json();
  const csvContent = useMemo(() => {
    if (!users) return;
    let csvContent = 'data:text/csv;charset=utf-8,';

    users.forEach(row => {
      csvContent += row.email + '\r\n';
    });

    return csvContent;
  }, [users]);

  const handleDownloadCSV = () => downloadCSV('subscribers.csv', csvContent);

  const overviewItems = useMemo(() => [{
    key: 'active',
    title: 'Subscribers',
  }, {
    key: 'disabled',
    title: 'Cancelled subscriptions',
  }], []);

  return (
    <ResourceListPage
      title="Subscribes"
      overviewItems={overviewItems}
      searchElement={(
        <SportFilter />
      )}
      headerRightElement={(
        <Button intent="primary" onClick={handleDownloadCSV}>Download CSV</Button>
      )}
    >
      <Route
        path={match.path}
        render={props => (
          <SuspenseErrorBoundary>
            <SubscribesList {...props} />
          </SuspenseErrorBoundary>
        )}
      />
    </ResourceListPage>
  );
};

export default SubscribesListRoot;
