import objectToFormData from 'object-to-formdata';

import fetch from 'core/fetch';
import CONFIG from 'core/config';

export function postPicture(data, transformation) {
  const body = {
    image: data,
    transformation,
  };
  const formData = objectToFormData(body);

  const options = {
    url: `${CONFIG.hosts.api}/pictures`,
    method: 'POST',
    data: formData,
  };
  const promise = fetch.request(options);
  return promise;
}

export function postDocument(data, params) {
  const body = new FormData();
  body.append('file', data);

  const options = {
    url: `${CONFIG.hosts.api}/documents`,
    method: 'POST',
    data: body,
    params,
  };
  const promise = fetch.request(options);
  return promise;
}

export default {
  postPicture,
  postDocument,
};
