import React, { useEffect, useCallback } from 'react';
import moment from 'moment';

import CONFIG from 'core/config';

import { Select } from 'shared/components';
import { useQuerySearching } from 'shared/hooks';
import { addMonths } from 'shared/helpers/date';

import { getList, get } from 'data/fetch/events.fetch';

const parseEvents = (events = []) => events.map(event => ({
  label: event.event.name,
  subLabel: moment.utc(event.event.scheduled_started_at).local().format(CONFIG.settings.dateTimeDisplayFormat),
  value: event.event.uuid,
}));

const dataFetcherAction = async({ term }, selectedSeasonId, initialValue) => {
  const params = { seasonUuid: selectedSeasonId };
  if (term) {
    params.searched_phrase = term;
  } else {
    params.from = new Date().toISOString();
    params.to = addMonths(new Date(), 2).toISOString();
  }

  const { data: listResponse } = await getList(params);

  const initialSeasonResponse = initialValue && await (async() => {
    try {
      const { data } = await get(initialValue);

      return data;
    } catch (error) {
      return;
    }
  })();
  const data = listResponse;
  if (initialSeasonResponse) data.unshift(initialSeasonResponse);

  return { data };
};

const EventSelectField = ({
  selectedSeasonId, initialValue,
  loading,
  ...props
}) => {
  const fetchAction = useCallback(
    data => dataFetcherAction(data, selectedSeasonId, initialValue),
    [selectedSeasonId]
  );
  const [
    query,
    fetchingEvents,
    handleQueryChange,
  ] = useQuerySearching(fetchAction);

  const parsedItems = parseEvents(query.results);

  useEffect(() => {
    handleQueryChange('');
  }, [selectedSeasonId]);

  return (
    <Select
      allowClear
      items={parsedItems}
      onQueryChange={handleQueryChange}
      itemPredicate={() => true}
      loading={loading || fetchingEvents}
      {...props}
    />
  );
};

export default EventSelectField;
