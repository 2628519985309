import { all, fork, takeEvery } from 'redux-saga/effects';

import {
  BLOGS_POST_SUCCESS,
  BLOGS_PUT_SUCCESS,
  BLOGS_DELETE_SUCCESS,
  BLOGS_CHANGES_UNDO,
} from 'data/constants';

import blogsListView from './blogsListPage.saga';
import blogsSingleView from './blogsSinglePage.saga';

import { createBlog, editBlog, deleteBlog, undoAction } from './blogs.saga';

export default function* main() {
  const tasks = yield all([
    fork(blogsListView),
    fork(blogsSingleView),
    takeEvery(BLOGS_POST_SUCCESS, createBlog),
    takeEvery(BLOGS_PUT_SUCCESS, editBlog),
    takeEvery(BLOGS_DELETE_SUCCESS, deleteBlog),
    takeEvery(BLOGS_CHANGES_UNDO, undoAction),
  ]);

  yield all(tasks);
}
