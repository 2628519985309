import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Classes, Button, FormGroup, InputGroup } from '@blueprintjs/core';

export class AddVideo extends Component {
  static propTypes = {
    editorState: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    initialValues: { header: '', body: '' },
  }

  constructor(props) {
    super(props);

    this.state = {
      video: null,
    };

    this.updateVideo = this.updateVideo.bind(this);
    this.uploadVideo = this.uploadVideo.bind(this);
  }

  updateVideo(e) {
    this.setState({
      video: e.target.value,
    });
  }

  async uploadVideo() {
    const { modifier, editorState, onChange, onClose } = this.props;
    const { video } = this.state;
    onChange(modifier(editorState, { src: video }));
    onClose();
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { video } = this.state;

    return (
      <Dialog
        isOpen={isOpen}
        onClose={onClose}
        title="Add video"
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            helperText="Only YouTube and Vimeo are supported"
            label="Video link"
            labelFor="video-url"
          >
            <InputGroup
              large
              id="video-url"
              placeholder="Video url"
              onChange={this.updateVideo}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              large
              className="bp3-right"
              onClick={this.uploadVideo}
              intent="primary"
              disabled={!video}
            >
              Add video
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default AddVideo;
