import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop, isEqual, debounce } from 'lodash';
import { connect } from 'formik';

export default function withFormikAutoSave({ onSave = noop, compare = {} }) {
  return function wrapperAutoSave(WrapperComponent) {
    class ComponentWithAutoSave extends PureComponent {
      static propTypes = {
        values: PropTypes.object,
        autoSavingEnabled: PropTypes.bool,
      }
      static defaultProps = {
        autoSavingEnabled: true,
        setAutoSaveError: noop,
        setIsAutoSaving: noop,
        setLastAutoSaved: noop,
      }

      constructor(props) {
        super(props);

        this.save = debounce(this.onSave.bind(this), 1000);
      }

      async componentDidUpdate(prevProps) {
        const { values, autoSavingEnabled, validateOnChange, validateForm, setAutoSaveError } = this.props;
        if (!autoSavingEnabled || !validateOnChange) return;
        let valid = true;

        try {
          Object.entries(values).forEach(([key, value]) => {
            const compareFunction = compare[key] || isEqual;
            const hasValueChanged = !compareFunction(prevProps.values[key], value);
            if (hasValueChanged) {
              throw new Error(`New value for key ${key}: ${JSON.stringify(value, null, 2)}`);
            }
          });
        } catch (error) {
          const result = await validateForm(values);
          const errorKeys = Object.keys(result);
          if (errorKeys.length > 0) {
            valid = false;
            setAutoSaveError(result[errorKeys[0]]);
          }
          if (valid) {
            this.save(values, this.props);
          }
        }
      }

      componentWillUnmount() {
        this.save.cancel();
      }

      async onSave(values, props) {
        props.setIsAutoSaving(true);
        props.setAutoSaveError(undefined);
        try {
          await onSave(values, props);
          props.setIsAutoSaving(false);
          props.setLastAutoSaved(new Date());
        } catch(err) {
          console.log(err);
          props.setAutoSaveError(false);
          props.setIsAutoSaving(err.message);
        }
      }

      render() {
        return <WrapperComponent {...this.props} />;
      }
    }

    return connect(ComponentWithAutoSave);
  };
}
