import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ARTICLES_GET, ARTICLES_CANCEL } from 'data/constants';

import { MainViewHandler } from 'shared/hocs';
import Types from 'shared/types/Articles.types';

import PostsList from './components/PostsList/PostsList.view';

export class ArticlesList extends Component {
  static propTypes = {
    fetchData: PropTypes.func.isRequired,
    cancelFetch: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape(Types.articleItemProperties)).isRequired,
  }

  render() {
    return (
      <PostsList
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => ({
  state: state.articlesList.state,
  count: state.articlesList.count,
  items: state.articlesList.list,
});

const mapDispatchToProps = dispatch => ({
  fetchData: params => dispatch({
    type: ARTICLES_GET,
    params,
  }),
  cancelFetch: () => dispatch({
    type: ARTICLES_CANCEL,
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainViewHandler(ArticlesList));
