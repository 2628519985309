import React, { useMemo } from 'react';
import { Field } from 'formik';
import { FormGroup, Button } from '@blueprintjs/core';
import { useQuery } from 'react-query';

import fetch from 'core/fetch';
import CONFIG from 'core/config';
import { SuspenseErrorBoundary } from 'shared/hocs';

import { Select, Checkbox, EventSelectField, SeasonSelectField } from 'shared/components';

const SelectWrapper = ({ fetchPath, dependsOn = [], ...props }) => {
  const { values } = props.form;
  const dependentParams = dependsOn.reduce((acc, key) => {
    acc[key] = values[key];
    return acc;
  }, {});

  switch (fetchPath) {
    case 'environments/seasons':
      return (
        <SeasonSelectField
          {...props}
        />
      );
    case 'fixtures/events':
      return (
        <EventSelectField
          {...props}
          selectedSeasonId={dependentParams.seasonUuid}
        />
      );

    default:
      break;
  }

  const params = (() => {
    switch (fetchPath) {
      case 'environments/competitions':
        return {
          sportName: 'soccer',
          areaArea_code: CONFIG.settings.sportxpertSupportedAreas,
        };

      default:
        return {};
    }
  })();
  const data = fetchPath
    ? useQuery(
      [
        fetchPath,
        {
          url: `${CONFIG.hosts.sportxpert}/${fetchPath}`,
          headers: {
            'X-SportXpertApiKey': CONFIG.settings.sportxpertToken,
          },
          withCredentials: false,
          params,
        },
      ],
      fetch.request
    ).data.json()
    : [];

  const items = data.map(item => ({
    value: item.uuid,
    label: item.name,
  }));

  return (
    <Select
      items={items}
      {...props}
    />
  );
};

const FieldWrapper = ({ type, fieldTypes, ...props }) => {
  switch (type) {
    case fieldTypes.LIST:
      return (
        <SuspenseErrorBoundary fallback="loading...">
          <Field
            component={SelectWrapper}
            {...props}
          />
        </SuspenseErrorBoundary>
      );
    case fieldTypes.BOOL:
      return (
        <Field
          component={Checkbox}
          {...props}
        />
      );

    default:
      return null;
  }
};

const AddWidgetForm = ({
  widgetsDefinition = {}, fieldTypes = {}, handleSubmit, values, isSubmitting, isValid,
}) => {
  const widgetItems = useMemo(() => Object.keys(widgetsDefinition).map(widgetName => ({
    value: widgetName,
    label: widgetName,
  })));

  const selectedWidgetFields = useMemo(() => {
    const selectedWidget = widgetsDefinition[values.widgetType];

    return selectedWidget ? selectedWidget.fields : {};
  }, [values.widgetType]);

  return (
    <form onSubmit={handleSubmit} className="m-b-10">
      <Field
        name="widgetType"
        component={Select}
        label="Choose widget to add"
        items={widgetItems}
      />

      {Object.entries(selectedWidgetFields).map(([name, data]) => {
        const hasNotDataDependsOn = (data.dependsOn || []).map(dependsOnKey => !!values[dependsOnKey]).includes(false);

        return !hasNotDataDependsOn && (
          <FormGroup key={name}>
            <FieldWrapper
              name={name}
              fieldTypes={fieldTypes}
              {...data}
            />
          </FormGroup>
        );
      })}

      <Button
        large
        type="submit"
        disabled={isSubmitting || !isValid}
        loading={isSubmitting}
        intent="primary"
      >
        Submit
      </Button>
    </form>
  );
};

export default AddWidgetForm;
