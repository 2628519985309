import {
  LOADING_STATES,
  ARTICLES_SINGLE_GET_REQUEST,
  ARTICLES_SINGLE_GET_SUCCESS,
  ARTICLES_SINGLE_GET_FAILURE,
  ARTICLES_PUT_SUCCESS,
  ARTICLES_DELETE_SUCCESS,
  ARTICLES_CHANGES_UNDO,
  ARTICLES_CANCEL,
} from 'data/constants';

import { normalize } from './articleNormalizr';

const defaultState = {
  state: LOADING_STATES.INITIAL,
  count: 0,
  data: {},
  error: null,
};

export default function articlesSingle(state = defaultState, action) {
  switch (action.type) {
    case ARTICLES_SINGLE_GET_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      };
    case ARTICLES_SINGLE_GET_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        data: normalize(action.data),
      };
    case ARTICLES_SINGLE_GET_FAILURE:
      return {
        ...state,
        state: LOADING_STATES.FAILED,
        error: action.error.message,
      };
    case ARTICLES_PUT_SUCCESS:
      return {
        ...state,
        data: normalize(action.req, action.meta),
      };
    case ARTICLES_CHANGES_UNDO:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.article,
        },
      };
    case ARTICLES_CANCEL:
    case ARTICLES_DELETE_SUCCESS:
      return defaultState;
    default:
      return state;
  }
}
