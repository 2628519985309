import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import classNames from 'classnames';
import moment from 'moment';
import { Classes, Button, Intent } from '@blueprintjs/core';

import {
  ImageUploadInput, DatePicker, TextArea, ErrorBlock, EventSelectField, Overlay,
} from 'shared/components';

import { validations } from 'views/Articles/Articles.validations.js';

import styles from './PublishModal.module.scss';

const formikEnhancer = withFormik({
  mapPropsToValues: ({ article }) => article,
  validationSchema: props => props.validationSchema,
  displayName: 'PublishArticleForm',
  handleSubmit: async(
    values, {
      props: { publish },
      setErrors,
      setSubmitting,
    }) => {
    try {
      await publish(values);
    } catch (err) {
      const error = err && err.error ? err.error.description : 'Something went wrong';
      setErrors({
        _error: error,
      });
      setSubmitting(false);
      return Promise.reject(error);
    }
    setSubmitting(false);
  },
});

export class PublishModal extends Component {
  static propTypes = {
    article: PropTypes.shape({
      header: PropTypes.string.isRequired,
      picture: PropTypes.string,
    }).isRequired,
    type: PropTypes.string.isRequired,
  }
  static defaultProps = {
    item: {
      picture: null,
    },
  }

  constructor(props) {
    super(props);

    this.state = {
      isSchedulingMode: !!props.item.published_at,
    };

    this.toggleScheduling = this.toggleScheduling.bind(this);
  }

  componentDidMount() {
    const { values, setFieldValue } = this.props;
    if (!values.published_at) {
      setTimeout(() => setFieldValue('published_at', new Date()), 0);
    }
  }

  maxDate = moment().add(2, 'year').toDate();

  toggleScheduling() {
    const { setFieldValue } = this.props;
    const { isSchedulingMode } = this.state;

    // Reset publish date to now
    setFieldValue('published_at', new Date());

    this.setState({
      isSchedulingMode: !isSchedulingMode,
    });
  }

  render() {
    const {
      isOpen,
      onClose,
      item,
      type,
      values,
      errors,
      handleSubmit,
      isSubmitting,
    } = this.props;
    const { isSchedulingMode } = this.state;

    return (
      <Overlay
        isOpen={isOpen}
        onClose={onClose}
        className={Classes.OVERLAY_SCROLL_CONTAINER}
      >
        <section className="container">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <ErrorBlock
                  errors={errors}
                  className="m-b-20"
                />
                <div className="row">
                  {type === 'articles' && (
                    <div className="col-sm-5">
                      <div className="m-b-20">
                        <Field
                          fill
                          labelFor="picture"
                          name="picture"
                          type="picture"
                          component={ImageUploadInput}
                          placeholder="Include a high-quality image in your article to make it more inviting to readers."
                          value={values.picture}
                          error={errors.picture}
                          width={1200}
                          height={675}
                        />
                      </div>
                      <h1>{item.header}</h1>
                      <Field
                        large
                        isDynamic
                        withCounter
                        name="preamble"
                        type="textarea"
                        component={TextArea}
                        placeholder="Write a article subtitle..."
                        maxLength={validations.preamble.max}
                        textAreaProps={{
                          rows: 2,
                          multiline: true,
                        }}
                      />
                    </div>
                  )}
                  <div className={classNames(styles.left, 'col-sm-5 col-sm-offset-1')}>
                    <div className={styles.top}>
                      <h2 className="m-b-30">Prepare your article for readers</h2>
                      {values.sportxpert_id && (
                        <Field
                          large
                          name="sportxpert_id"
                          component={EventSelectField}
                          label="Link with an event"
                          placeholder="Link with an event"
                          initialValue={values.event && values.event.event.uuid}
                          selectedSeasonId={values.sportxpert_type === 'match'
                            ? values.event && values.event.season.uuid
                            : values.sportxpert_id
                          }
                        />
                      )}
                    </div>
                    <div className={styles.bottom}>
                      {isSchedulingMode && (
                        <div className="m-b-20">
                          <div className={styles.toggles}>
                            <Field
                              large
                              singleMonthOnly
                              className="m-b-5"
                              labelFor="published_at"
                              name="published_at"
                              type="text"
                              component={DatePicker}
                              label="Schedule a time to publish"
                              value={values.published_at}
                              error={errors.published_at}
                              timePrecision="minute"
                              shortcuts={false}
                              maxDate={this.maxDate}
                              canClearSelection={false}
                              buttonProps={{
                                minimal: true,
                                small: true,
                                intent: 'primary',
                              }}
                            />
                          </div>
                          <span className={classNames(Classes.TEXT_SMALL, Classes.TEXT_MUTED)}>
                              This article will be published automatically at the specified time.
                          </span>
                        </div>
                      )}
                      <div className={styles.actions}>
                        <Button
                          large
                          type="submit"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          intent={Intent.PRIMARY}
                          className="m-r-10"
                        >
                          {isSchedulingMode ? 'Schedule to publish' : 'Publish now'}
                        </Button>
                        <Button
                          large
                          minimal
                          type="button"
                          onClick={this.toggleScheduling}
                          disabled={isSubmitting}
                        >
                          {isSchedulingMode ? 'Cancel scheduling' : 'Schedule for later'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </Overlay>
    );
  }
}

export default formikEnhancer(PublishModal);
