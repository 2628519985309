import React from 'react';
import { Suggest } from '@blueprintjs/select';
import { FormGroup, MenuItem, Button } from '@blueprintjs/core';
import { noop } from 'lodash';

import { ViewLoader } from 'shared/components';

const MAX_ITEMS = 10;

const renderItem = (item, { handleClick, modifiers, index }) => {
  if (!modifiers.matchesPredicate || (index > MAX_ITEMS - 1)) {
    return null;
  }

  return (
    <MenuItem
      key={item.value}
      active={modifiers.active}
      disabled={modifiers.disabled}
      text={item.label}
      label={item.subLabel}
      onClick={handleClick}
    />
  );
};

const filterItem = (query, item = {}) => {
  const { label = '' } = item;
  const itemLabel = label.toLowerCase();
  const isMatch = itemLabel && itemLabel.indexOf(query.toLowerCase()) >= 0;

  return isMatch;
};

const CustomSelect = ({
  items, placeholder, allowClear, intent, helperText, onChange = noop,
  form = {}, field = {}, loading,
  ...props
}) => {
  const value = field.value || props.value;
  const error = form.errors && form.errors[field.name];

  const selectedItem = items.find(item => String(item.value) === String(value)) || {};
  const handleChange = data => {
    if (typeof form.setFieldValue === 'function') {
      form.setFieldValue(field.name, data.value);
    }
    if (typeof onChange === 'function') {
      onChange(data.value);
    }
  };

  return (
    <FormGroup
      labelFor={field.name}
      intent={error ? 'danger' : intent}
      helperText={error ? error : helperText}
      {...props}
    >
      <Suggest
        itemRenderer={renderItem}
        itemListRenderer={loading ? ViewLoader : undefined}
        items={items}
        selectedItem={selectedItem}
        itemPredicate={filterItem}
        onItemSelect={handleChange}
        noResults={<MenuItem disabled={true} text="No results." />}
        popoverProps={{
          targetClassName: 'fill',
        }}
        inputProps={{
          autoComplete: 'off',
          placeholder,
          rightElement: allowClear && value ? (
            <Button
              icon="cross"
              onClick={() => handleChange('')}
            />
          ) : null,
        }}
        inputValueRenderer={item => item.label}
        {...props}
      />
    </FormGroup>
  );
};

export default CustomSelect;
