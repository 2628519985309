import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Popover, ButtonGroup, Button, Position } from '@blueprintjs/core';
import { DateRangePicker } from '@blueprintjs/datetime';

import { addQuery } from 'shared/helpers/url';

class DateRangeFilter extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    fromFilterName: PropTypes.string,
    toFilterName: PropTypes.string,
    fromPlaceholder: PropTypes.string,
    toPlaceholder: PropTypes.string,
    allowClear: PropTypes.bool,
  }
  static defaultProps = {
    start: null,
    end: null,
    placeholder: 'Dates',
    fromFilterName: 'from',
    toFilterName: 'until',
    fromPlaceholder: 'Start',
    toPlaceholder: 'End',
    allowClear: false,
  }
  constructor(props) {
    super(props);

    this.state = {
      dateInterval: [],
    };

    this.setFilter = this.setFilter.bind(this);
  }
  static getDerivedStateFromProps = ({ start, end }) => ({
    dateInterval: [
      start && !(start instanceof Date) ? new Date(start) : start,
      end && !(end instanceof Date) ? new Date(end) : end,
    ],
    hasValue: start || end,
  })

  setFilter(dateInterval) {
    const { history, location, fromFilterName, toFilterName } = this.props;
    history.push(addQuery(location, {
      [fromFilterName]: dateInterval[0],
      [toFilterName]: dateInterval[1],
    }));
  }

  renderDateRanges() {
    const { fromPlaceholder, toPlaceholder, start, end } = this.props;
    const dateFormat = 'MMM Do YYYY';
    const from = start ? moment(start).format(dateFormat) : fromPlaceholder;
    const to = end ? moment(end).format(dateFormat) : toPlaceholder;
    return (
      <Fragment>
        {from} - {to}
      </Fragment>
    );
  }

  getButtonText() {
    const { placeholder, start, end } = this.props;
    if(!start && !end) return placeholder;
    return this.renderDateRanges();
  }

  render() {
    const { buttonProps, allowClear, ...props } = this.props;
    const { dateInterval, hasValue } = this.state;

    return (
      <ButtonGroup>
        <Popover
          position={Position.BOTTOM_LEFT}
        >
          <Button
            {...buttonProps}
          >
            {this.getButtonText()}
          </Button>
          <DateRangePicker
            value={dateInterval}
            onChange={this.setFilter}
            {...props}
          />
        </Popover>
        {allowClear && hasValue && (
          <Button
            icon="cross"
            onClick={() => this.setFilter([null, null])}
          />
        )}
      </ButtonGroup>
    );
  }
}

export default withRouter(DateRangeFilter);
