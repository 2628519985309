import { fork, all, take, takeLatest } from 'redux-saga/effects';

import { BLOGS_CANCEL, BLOGS_SINGLE_GET } from 'data/constants';

import { getSingleBlog } from './blogs.saga';

function* postPage() {
  while (true) {
    const task = yield takeLatest([BLOGS_SINGLE_GET], getSingleBlog);

    yield take(BLOGS_CANCEL);
    yield task.cancel();
  }
}

export default function* main() {
  const tasks = yield all([
    fork(postPage),
  ]);

  yield all(tasks);
}
