export default {
  isDev: process.env.NODE_ENV !== 'production',
  settings: {
    name: process.env.REACT_APP_NAME,
    rollbarToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    sportxpertToken: process.env.REACT_APP_SPORTXPERT_API_KEY,
    sportxpertSupportedAreas: process.env.REACT_APP_SPORTXPERT_SUPPORTED_AREAS,
    dateTimeDisplayFormat: 'MMM Do YYYY, h:mm a',
    dateDisplayFormat: 'MMM Do YYYY',
    preambleDefaultLength: 35,
  },
  hosts: {
    publicApi: `${process.env.REACT_APP_API_HOST}`,
    api: `${process.env.REACT_APP_API_HOST}/admin`,
    sportxpert: process.env.REACT_APP_SPORTXPERT_HOST,
  },
};
