import {
  LOADING_STATES,
  GENERAL_OPEN_NAVIGATION,
  GENERAL_CLOSE_NAVIGATION,

  GENERAL_SPORTS_GET,
  GENERAL_NEWSPAPER_KINDS_GET,
} from 'data/constants';
import API from 'data/fetch';

export const openNavigation = () => dispatch => {
  document.body.classList.remove('Navigation-closed');
  return dispatch({
    type: GENERAL_OPEN_NAVIGATION,
  });
};

export const closeNavigation = () => dispatch => {
  document.body.classList.add('Navigation-closed');
  return dispatch({
    type: GENERAL_CLOSE_NAVIGATION,
  });
};

export const getSports = () => (dispatch, getState) => {
  const promise = API.general.getSports();
  const { general } = getState();

  if (general.states.GENERAL_SPORTS_GET !== LOADING_STATES.LOADED) {
    return dispatch({
      type: GENERAL_SPORTS_GET,
      promise,
      tryAgain: () => dispatch(getSports()),
    });
  }
};

export const getNewspaperKinds = () => dispatch => {
  const promise = API.general.getNewspaperKinds();

  return dispatch({
    type: GENERAL_NEWSPAPER_KINDS_GET,
    promise,
    tryAgain: () => dispatch(getSports()),
  });
};


export default {
  openNavigation,
  closeNavigation,
};
