import * as yup from 'yup';

import { selectStrategy } from './Creator.utils';

export const validations = {
};

export const validators = {
  header: yup
    .string()
    .trim()
    .required('Title is required'),
  user_id: yup
    .number()
    .required('Assignee is required'),
  file: yup
    .mixed()
    .required('File is required'),
  sport_id: yup
    .string()
    .trim()
    .required('Content category is required'),
  newspaper_kind_id: yup
    .string()
    .trim()
    .required('Type is required'),
};

export const contentValidators = yup.lazy(value => selectStrategy(value.type, {
  post: yup.object().shape({
    header: validators.header,
    user_id: validators.user_id,
    sport_id: validators.sport_id,
  }),
  newspaper: yup.object().shape({
    file: validators.file,
    newspaper_kind_id: validators.sport_id,
  }),
}));
