import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { ButtonGroup } from '@blueprintjs/core';

import { addQuery } from 'shared/helpers/url';

import { SportSelectField } from 'shared/components';

const SportFilter = ({ history, location }) => {
  function setFilter(sportId) {
    history.push(addQuery(location, {
      content_category_id: sportId,
    }));
  }

  return (
    <ButtonGroup>
      <SportSelectField
        allowClear
        onChange={setFilter}
        value={location.query.content_category_id}
        placeholder="Select sport..."
      />
    </ButtonGroup>
  );
};

SportFilter.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(SportFilter);
