import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';

import fetch from 'core/fetch';
import CONFIG from 'core/config';

export function get() {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/statistics`,
    method: 'GET',
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export default {
  get,
};
