import { convertFromRaw } from 'draft-js';

import CONFIG from 'core/config';

function getDefaultPreamble(content) {
  try {
    const state = convertFromRaw(content);
    const body = state.getPlainText();
    const words = body.split(' ');

    if (words.length <= 1) {
      throw new Error();
    }

    const wordLimited = [];
    let i = 0;
    do {
      words[i] !== '' && wordLimited.push(words[i]);
      i++;
    }
    while (wordLimited.length < CONFIG.settings.preambleDefaultLength);

    return `${wordLimited.join(' ').trim()}...`;
  } catch (error) {
    return null;
  }
}

export const normalize = post => {
  const normalizedData = { ...post };

  normalizedData.preamble = post.preamble || getDefaultPreamble(post.body);

  return normalizedData;
};
