import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Intent } from '@blueprintjs/core';

import { ValidationToast } from 'shared/components';

import { AUTH_LOGOUT } from 'data/constants';

const NotAuthorizedFallback = ({ logout }) => {
  useEffect(() => {
    (async() => {
      await logout();
      ValidationToast.show({
        message: 'Your seesion has been expired',
        intent: Intent.DANGER,
      });
    })();
  }, []);

  return null;
};


export default connect(null, dispatch => ({
  logout: () => dispatch({ type: AUTH_LOGOUT }),
}))(NotAuthorizedFallback);
