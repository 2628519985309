import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Article from './Article.redux';
import Blog from './Blog.redux';

const ArticlesRoot = () => (
  <Switch>
    <Route path="/:type(articles)/:id" component={Article} />
    <Route path="/:type(blogs)/:id" component={Blog} />
  </Switch>
);

export default ArticlesRoot;
