import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router';
import { debounce } from 'lodash';
import { InputGroup } from '@blueprintjs/core';

import { addQuery } from 'shared/helpers/url';

const SearchForm = React.memo(({ className, history, location }) => {
  const [searchValue, setSearchValue] = useState(location.query.searched_phrase);

  const handleChange = e => {
    const { value } = e.target;

    setSearchValue(value);
    changeQuery(value);
  };

  const changeQuery = useCallback(debounce(value => {
    history.push(addQuery(location, {
      searched_phrase: value,
    }));
  }, 500), []);

  return (
    <InputGroup
      className={cx(className)}
      onChange={handleChange}
      placeholder="Search..."
      value={searchValue}
    />
  );
});

SearchForm.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(SearchForm);
