import {
  NEWSPAPERS_POST,
  NEWSPAPERS_PUT,
  NEWSPAPERS_DELETE,
} from 'data/constants';
import API from 'data/fetch';

export const addNewspaper = data => dispatch => {
  const { file, ...params } = data;
  const promise = API.newspapers.post(file, params);

  return dispatch({
    type: NEWSPAPERS_POST,
    promise,
    tryAgain: () => dispatch(addNewspaper(data)),
  });
};

export const editNewspaper = ({ id, file }) => dispatch => {
  const promise = API.newspapers.put(id, file);

  return dispatch({
    type: NEWSPAPERS_PUT,
    promise,
    tryAgain: () => dispatch(editNewspaper({ id, file })),
  });
};

export const removeNewspaper = id => dispatch => {
  const promise = API.newspapers.del(id);

  return dispatch({
    type: NEWSPAPERS_DELETE,
    promise,
    meta: {
      id,
    },
    tryAgain: () => dispatch(removeNewspaper(id)),
  });
};

export default {
  addNewspaper,
  editNewspaper,
  removeNewspaper,
};
