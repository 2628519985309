import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';

import { AccessControl, ResourceListPage, Button } from 'shared/components';
import { SuspenseErrorBoundary } from 'shared/hocs';

import UserList from './views/List';
import API from 'data/fetch';
import { downloadCSV } from 'shared/helpers/csv';


const UsersRoot = ({ match }) => {
  const { data } = useQuery(
    [
      'all_guest_users',
      {
        roles: 'guest',
        per_page: 999999999,
      },
    ],
    API.users.getList,
  );
  const { users } = data.json();

  const csvContent = useMemo(() => {
    if (!users) return;
    let csvContent = 'data:text/csv;charset=utf-8,';

    users.forEach(row => {
      csvContent += row.email + '\r\n';
    });

    return csvContent;
  }, [users]);

  const handleDownloadCSV = () => {
    downloadCSV('guest_users.csv', csvContent);
  };

  return (
    <Switch>
      <Route
        exact
        path={`${match.url}`}
        render={props => (
          <AccessControl
            allowedPermissions={[AccessControl.PERMISSIONS.admin, AccessControl.PERMISSIONS.technician]}
            renderNoAccess={allowedPermissions => <AccessControl.NoAccess permissionsNeeded={allowedPermissions} />}
          >
            <ResourceListPage
              title="User"
              headerRightElement={(
                <Button intent="primary" onClick={handleDownloadCSV}>Download CSV</Button>
              )}
            >
              <SuspenseErrorBoundary>
                <UserList {...props} />
              </SuspenseErrorBoundary>
            </ResourceListPage>
          </AccessControl>
        )}
      />

      <Redirect to={`${match.url}`} />
    </Switch>
  );
};

export default UsersRoot;
