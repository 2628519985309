import React, { useCallback } from 'react';

import { FileButton, Checkbox } from 'shared/components';

import './BettingCardListItem.scss';

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const getSystemNameByStrategy = ({ strategy = 'default', card }) => ({
  V75Jackpot: () => {
    switch (Number(card.price)) {
      case 64:
      case 95:
        return 'early chance';
      case 204:
        return 'dream chance';
      case 127:
      case 305:
        return 'million chance';
      case 509:
      case 191:
        return 'super chance';

      default:
        return 'chance';
    }
  },
  V64: () => {
    return card.week_day === 3 ? 'dream chance' : `${days[card.week_day]} chance`;
  },
  default: () => {
    switch (Number(card.price)) {
      case 64:
        return 'early chance';
      case 95:
        return 'dream chance';
      case 127:
      case 204:
      case 305:
        return 'million chance';
      case 509:
      case 191:
        return 'super chance';
      case 109:
        return 'The dream tip';

      default:
        return 'chance';
    }
  },
}[strategy]());

const getSystemName = card => {
  const isV64 = card.kind === 'V64';
  const isV75Jackpot = card.kind === 'V75' && card.jackpot;
  let strategy = undefined;
  if (isV64) strategy = 'V64';
  if (isV75Jackpot) strategy = 'V75Jackpot';

  return getSystemNameByStrategy({ strategy, card });
};

const BettingCardListItem = ({ item, editBettingCard }) => {
  return (
    <div className="BettingCardListItem">
      <a
        href={item.file}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={`/images/bg-${item.kind.toLowerCase()}.png`}
          alt=""
          width={182}
          height={70}
        />
      </a>
      <div>{item.price} SEK</div>
      <div>{getSystemName(item)}</div>
      <div>
        <FileButton
          onChange={useCallback(file => editBettingCard({ id: item.id, file }), [])}
          inputProps={{
            accept: 'application/pdf',
          }}
        >
          update
        </FileButton>
      </div>
      <div>
        <Checkbox
          name="displayed"
          label="Display on website"
          checked={item.displayed}
          onChange={useCallback(displayed => editBettingCard({ id: item.id, displayed }), [])}
        />
        <Checkbox
          name="download_ready"
          label="System ready"
          checked={item.download_ready}
          onChange={useCallback(downloadReady => editBettingCard({ id: item.id, download_ready: downloadReady }), [])}
        />
      </div>
    </div>
  );
};

export default BettingCardListItem;
