import React from 'react';
import { connect } from 'react-redux';

import { PERMISSIONS } from './AccessControl.const';
import { NoAccess } from './components';

function checkPermissions(userPermissions = [], allowedPermissions = []) {
  return allowedPermissions.some(allowedPermission => userPermissions.includes(allowedPermission));
}

const defaultProps = {
  allowedPermissions: Object.values(PERMISSIONS),
  userPermissions: [],
  renderNoAccess: () => null,
};

let AccessControl = React.memo(({
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
}) => {
  const permitted = checkPermissions(userPermissions, allowedPermissions);

  if (permitted) {
    return children;
  }
  return renderNoAccess(allowedPermissions);
});

AccessControl = connect(state => ({
  userPermissions: [state.me.data.role],
}))(AccessControl);

AccessControl.defaultProps = defaultProps;
AccessControl.PERMISSIONS = PERMISSIONS;
AccessControl.NoAccess = NoAccess;

export default AccessControl;
