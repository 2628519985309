import { all, fork } from 'redux-saga/effects';

import newspapersListView from './newspapersListPage.saga';

export default function* main() {
  const tasks = yield all([
    fork(newspapersListView),
  ]);

  yield all(tasks);
}
