import {
  LOADING_STATES,
  DASHBOARD_GET,
  DASHBOARD_GET_SUCCESS,
  DASHBOARD_GET_FAILURE,
  DASHBOARD_ARTICLES_GET_SUCCESS,
  DASHBOARD_CANCEL,
} from 'data/constants';

const defaultState = {
  state: LOADING_STATES.INITIAL,
  articles: [],
  error: null,
};

export default function dashboard(state = defaultState, action) {
  switch (action.type) {
    case DASHBOARD_GET:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      };
    case DASHBOARD_GET_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
      };
    case DASHBOARD_GET_FAILURE:
      return {
        ...state,
        state: LOADING_STATES.FAILED,
        error: action.error.message,
      };
    case DASHBOARD_ARTICLES_GET_SUCCESS:
      return {
        ...state,
        articles: action.data.articles,
      };
    case DASHBOARD_CANCEL:
      return defaultState;
    default:
      return state;
  }
}
