export const kinds = {
  article: 'article',
  blog: 'blog',
  newspaper_upload: 'newspaper_upload',
  betting_cards: 'betting_cards',
};

export const kindsLabels = {
  article: 'article',
  blog: 'blog',
  newspaper_upload: 'newspaper upload',
  betting_cards: 'andelsspel',
};

export const days = [
  {
    label: 'sunday',
    value: 0,
  },
  {
    label: 'monday',
    value: 1,
  },
  {
    label: 'tuesday',
    value: 2,
  },
  {
    label: 'wednesday',
    value: 3,
  },
  {
    label: 'thursday',
    value: 4,
  },
  {
    label: 'friday',
    value: 5,
  },
  {
    label: 'saturday',
    value: 6,
  },
];
