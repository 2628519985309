import React, { useCallback } from 'react';
import moment from 'moment';
import { FormGroup, Popover, Button } from '@blueprintjs/core';
import { DatePicker as BPDatePicker } from '@blueprintjs/datetime';
import { noop } from 'lodash';

import CONFIG from 'core/config';
import { addMonths } from 'shared/helpers/date';

import { inputTypes } from '../Forms.types';

const propTypes = inputTypes;

const defaultProps = {
  placeholder: null,
  intent: 'default',
  onChange: null,
  minDate: addMonths(new Date(), -25),
  maxDate: addMonths(new Date(), 25),
};

export const DatePicker = ({
  helperText,
  labelFor,
  intent,
  field = {},
  form = {},
  popoverProps,
  buttonProps,
  onChange = noop,
  ...props
}) => {
  const value = field.value || props.value;
  const error = form.errors && form.errors[field.name];

  const handleChange = values => {
    if (typeof form.setFieldValue === 'function') {
      form.setFieldValue(field.name, values);
    }
    if (typeof onChange === 'function') {
      onChange(values);
    }
  };

  const getDateTime = useCallback(date => {
    if (!date) return 'Immediately';
    return moment(date)
      .format(CONFIG.settings[props.timePrecision ? 'dateTimeDisplayFormat' : 'dateDisplayFormat']);
  }, [props.timePrecision]);

  const getButtonText = useCallback(dates => {
    if (!dates) return 'Select date';
    return getDateTime(dates);
  }, []);

  return (
    <FormGroup
      helperText={error ? error : helperText}
      labelFor={labelFor}
      intent={error ? 'danger' : intent}
      {...props}
    >
      <Popover {...popoverProps} >
        <Button
          fill
          {...buttonProps}
        >
          {getButtonText(value)}
        </Button>
        <BPDatePicker
          id={labelFor}
          intent={error ? 'danger' : intent}
          {...props}
          {...field}
          value={value && new Date(value)}
          onChange={handleChange}
        />
      </Popover>
    </FormGroup>
  );
};

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;
