import { all, put, fork, call, take, race } from 'redux-saga/effects';

import {
  DASHBOARD_GET,
  DASHBOARD_GET_SUCCESS,
  DASHBOARD_GET_FAILURE,
  DASHBOARD_ARTICLES_GET_REQUEST,
  DASHBOARD_ARTICLES_GET_SUCCESS,
  DASHBOARD_ARTICLES_GET_FAILURE,
  DASHBOARD_CANCEL,
  OVERVIEW_GET,
} from 'data/constants';

import API from 'data/fetch';

function* getArticles() {
  while (true) {
    yield take([DASHBOARD_GET]);
    yield put({ type: DASHBOARD_ARTICLES_GET_REQUEST });
    yield put({ type: OVERVIEW_GET });
    try {
      const { data } = yield call(() => API.articles.getList({
        states: 'draft,spell_check,content_check',
        page: 1,
        per_page: 5,
      }));
      if (data) yield put({ type: DASHBOARD_ARTICLES_GET_SUCCESS, data });
    } catch (error) {
      yield put({ type: DASHBOARD_ARTICLES_GET_FAILURE, error: error.message });
    }
  }
}

function* initDashboard() {
  while (true) {
    const { success, failure } = yield race({
      // Wait for both requests to be successful
      success: all([
        take(DASHBOARD_ARTICLES_GET_SUCCESS),
      ]),
      // Wait for one of the requests to fail
      failure: take([DASHBOARD_ARTICLES_GET_FAILURE]),
    });

    if (success) {
      yield put({ type: DASHBOARD_GET_SUCCESS });
    } else {
      yield put({ type: DASHBOARD_GET_FAILURE, error: failure.error });
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([
      fork(initDashboard),
      fork(getArticles),
    ]);

    yield all(tasks);

    yield take(DASHBOARD_CANCEL);
    yield all(tasks.map(task => task.cancel()));
  }
}
