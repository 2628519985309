import { addArticle } from '../articles';
import { addBlog } from '../blogs';
import { addNewspaper } from '../newspapers';

import { kinds } from 'views/ContentCreator/ContentCreator.const';

export const addPost = data => dispatch => {
  switch (data.type) {
    case kinds.article:
      return dispatch(addArticle(data));
    case kinds.blog:
      return dispatch(addBlog(data));
    case kinds.newspaper_upload:
      return dispatch(addNewspaper(data));

    default:
      return;
  }
};

export default {
  addPost,
};
