import * as yup from 'yup';
import { EditorState, convertFromRaw } from 'draft-js';

export const validations = {
  preamble: {
    min: 50,
    max: 350,
  },
  body: {
    min: 200,
  },
};

export const validators = {
  header: yup.string()
    .required('Title is required'),
  preamble: yup.string()
    .nullable()
    .min(validations.preamble.min, `Preamble must have at least ${validations.preamble.min} characters`)
    .max(validations.preamble.max, `Preamble can\'t have more than ${validations.preamble.max} characters`),
  body: yup.mixed()
    .required('Content is required')
    .test('bodyLength', 'Content must have at least 200 characters',
      body => {
        if (!body) return false;

        const editorState = EditorState.createWithContent(convertFromRaw(body));
        const content = editorState.getCurrentContent().getPlainText('');
        return content.length > validations.body.min;
      }),
  editorState: yup.mixed()
    .required('Article body is required')
    .test('editorStateLength', 'Content must have at least 200 characters',
      editorState => {
        const content = editorState.getCurrentContent().getPlainText('');
        return content.length > validations.body.min;
      }),
  picture: yup.string()
    .nullable()
    .required('Featured image is required')
    .url('Picture field must be a valid URL'),
};

export function getEditValidationSchema(status, postType) {
  const draftValidationSchema = {
    header: validators.header,
  };
  const contentCheckValidationSchema = {
    ...draftValidationSchema,
    preamble: validators.preamble,
    editorState: validators.editorState,
  };
  const approvedCheckValidationSchema = {
    ...contentCheckValidationSchema,
    picture: validators.picture,
  };

  if (postType === 'blogs') {
    return yup.object().shape(draftValidationSchema);
  }

  switch (status) {
    case 'draft':
      return yup.object().shape(draftValidationSchema);
    case 'content_check':
      return yup.object().shape(contentCheckValidationSchema);
    case 'approved':
      return yup.object().shape(approvedCheckValidationSchema);
    default:
      return yup.object().shape(draftValidationSchema);
  }
}

export function getMoveValidationSchema(status, postType) {
  const draftValidationSchema = {
    header: validators.header,
    body: validators.body,
  };
  const spellCheckValidationSchema = {
    ...draftValidationSchema,
    picture: validators.picture,
  };
  const contentCheckValidationSchema = {
    ...draftValidationSchema,
    preamble: validators.preamble,
  };

  if (postType === 'blogs') {
    return yup.object().shape(draftValidationSchema);
  }

  switch (status) {
    case 'draft':
      return yup.object().shape(draftValidationSchema);
    case 'spell_check':
      return yup.object().shape(spellCheckValidationSchema);
    case 'content_check':
      return yup.object().shape(contentCheckValidationSchema);
    default:
      return yup.object().shape(draftValidationSchema);
  }
}


export function getSidebarValidationSchema(status) {
  const draftValidationSchema = {
    header: validators.header,
  };
  const contentCheckValidationSchema = {
    ...draftValidationSchema,
    preamble: validators.preamble,
  };
  const approvedValidationSchema = {
    ...contentCheckValidationSchema,
    picture: validators.picture,
  };

  switch (status) {
    case 'draft':
      return yup.object().shape(draftValidationSchema);
    case 'content_check':
      return yup.object().shape(contentCheckValidationSchema);
    case 'approved':
      return yup.object().shape(approvedValidationSchema);
    default:
      return yup.object().shape(draftValidationSchema);
  }
}
