import React, { useEffect, useCallback } from 'react';

import { Select } from 'shared/components';
import { useQuerySearching } from 'shared/hooks';

import { getList, get } from 'data/fetch/seasons.fetch';

const parseSeasons = (items = []) => items.map(item => ({
  label: item.name + (item.area ? ` (${item.area.area_code})` : ''),
  value: item.uuid,
}));

const dataFetcherAction = async({ term }, initialValue) => {
  const { data: listResponse } = await getList({ searched_phrase: term });
  const shouldFetchInitialValue = !!initialValue;
  const initialSeasonResponse = shouldFetchInitialValue && await (async() => {
    try {
      const { data } = await get(initialValue);

      return data;
    } catch (error) {
      return;
    }
  })();
  const data = listResponse;
  if (initialSeasonResponse) data.unshift(initialSeasonResponse);

  return { data };
};

const SeasonSelectField = ({
  initialValue,
  loading,
  ...props
}) => {
  const fetchAction = useCallback(data => dataFetcherAction(data, initialValue), []);
  const [
    query,
    fetchingSeasons,
    handleQueryChange,
  ] = useQuerySearching(fetchAction);
  const parsedItems = parseSeasons(query.results);

  useEffect(() => {
    if (!query.results.length) {
      handleQueryChange('');
    }
  }, []);

  return (
    <Select
      allowClear
      items={parsedItems}
      onQueryChange={handleQueryChange}
      itemPredicate={() => true}
      loading={loading || fetchingSeasons}
      {...props}
    />
  );
};

export default SeasonSelectField;
