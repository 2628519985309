// GET
export const OVERVIEW_GET = 'OVERVIEW_GET';
export const OVERVIEW_GET_REQUEST = 'OVERVIEW_GET_REQUEST';
export const OVERVIEW_GET_SUCCESS = 'OVERVIEW_GET_SUCCESS';
export const OVERVIEW_GET_FAILURE = 'OVERVIEW_GET_FAILURE';

// OTHERS
export const OVERVIEW_CANCEL = 'OVERVIEW_CANCEL';

export default {
  OVERVIEW_GET,
  OVERVIEW_GET_REQUEST,
  OVERVIEW_GET_SUCCESS,
  OVERVIEW_GET_FAILURE,
  OVERVIEW_CANCEL,
};
