import { all, fork, takeEvery } from 'redux-saga/effects';

import {
  ARTICLES_POST_SUCCESS,
  ARTICLES_PUT_SUCCESS,
  ARTICLES_DELETE_SUCCESS,
  ARTICLES_CHANGES_UNDO,
} from 'data/constants';

import articlesListView from './articlesListPage.saga';
import articlesSingleView from './articlesSinglePage.saga';

import { createArticle, editArticle, deleteArticle, undoAction } from './articles.saga';

export default function* main() {
  const tasks = yield all([
    fork(articlesListView),
    fork(articlesSingleView),
    takeEvery(ARTICLES_POST_SUCCESS, createArticle),
    takeEvery(ARTICLES_PUT_SUCCESS, editArticle),
    takeEvery(ARTICLES_DELETE_SUCCESS, deleteArticle),
    takeEvery(ARTICLES_CHANGES_UNDO, undoAction),
  ]);

  yield all(tasks);
}
