import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import classnames from 'classnames';
import { Spinner, Icon } from '@blueprintjs/core';

import { LOADING_STATES, AUTH_LOGOUT } from 'data/constants';
import { SMALL_SCREEN_MAX, LARGE_SCREEN_MIN } from 'shared/helpers/window';
import { openNavigation, closeNavigation } from 'data/actions/general';
import { SuspenseErrorBoundary } from 'shared/hocs';

import { Dashboard, Articles, ContentCreator, User, Preview, Newspapers, Subscribers, Users, Settings } from 'views';
import { Header, Navigation, ErrorFallback } from 'shared/components';

import styles from './App.module.scss';

class Root extends Component {
  static propTypes = {
    isNavigationOpen: PropTypes.bool.isRequired,
    openNav: PropTypes.func.isRequired,
    closeNav: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  }

  componentDidUpdate({ location: prevLocation }) {
    const { location, closeNav } = this.props;

    // Close Navigation if the route changed
    // Only for mobile
    if (
      window.innerWidth <= SMALL_SCREEN_MAX &&
      (location.pathname !== prevLocation.pathname ||
        location.search !== prevLocation.search)
    ) {
      closeNav();
    }
  }

  toggleNavigation() {
    const { isNavigationOpen, openNav, closeNav } = this.props;
    if (window.innerWidth > LARGE_SCREEN_MIN) {
      if (!isNavigationOpen) {
        openNav();
      }
    } else if (isNavigationOpen) {
      closeNav();
    }
  }

  render() {
    const { state, isNavigationOpen, user, logout } = this.props;

    switch (state) {
      case LOADING_STATES.INITIAL:
      case LOADING_STATES.LOADING:
        return (
          <div className={styles.root}>
            <Spinner size="150" />
          </div>
        );
      case LOADING_STATES.LOADED:
        return (
          <SuspenseErrorBoundary>
            <div className={styles.wrapper}>
              <div
                className={classnames(styles.sidebar, {
                  [styles.isClosed]: !isNavigationOpen,
                })}
              >
                <div className={styles.sidebarInner}>
                  <Header user={user} />
                  <Navigation isOpen={isNavigationOpen} />
                  <button
                    className={classnames(styles.logout, {
                      [styles.isClosed]: !isNavigationOpen,
                    })}
                    onClick={logout}
                  >
                    <Icon icon="log-out" />
                    <span className={styles.logoutLabel}>Logout</span>
                  </button>
                </div>
              </div>
              <main className={styles.main}>
                <SuspenseErrorBoundary>
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route path="/content-creator" component={ContentCreator} />
                    <Route path="/:type(articles|blogs)" component={Articles} />
                    <Route path="/newspapers" component={Newspapers} />
                    <Route path="/me/:tab?" component={User} />
                    <Route path="/preview" component={Preview} />
                    <Route path="/subscribers" component={Subscribers} />
                    <Route path="/users" component={Users} />
                    <Route path="/settings" component={Settings} />
                    <Route component={ErrorFallback} />
                  </Switch>
                </SuspenseErrorBoundary>
              </main>
            </div>
          </SuspenseErrorBoundary>
        );
      case LOADING_STATES.FAILED:
      default:
        return null;
    }
  }
}

const mapStateToProps = state => ({
  state: LOADING_STATES.LOADED,
  isNavigationOpen: state.general.isNavigationOpen,
  user: state.me.data,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({
    type: AUTH_LOGOUT,
  }),
  openNav: () => dispatch(openNavigation()),
  closeNav: () => dispatch(closeNavigation()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Root)
);
