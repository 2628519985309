import React from 'react';
import classNames from 'classnames';

import DataListItem from './DataListItem';

import styles from './DataList.module.scss';

const DataList = ({ list }) => (
  <ul className={classNames(styles.root, 'bp3-list', 'bp3-list-unstyled')}>
    {list.map(item => (
      <li key={item.key}>
        {item.component}
      </li>
    ))}
  </ul>
);

DataList.Item = DataListItem;

export default DataList;
