import { put, call } from 'redux-saga/effects';

import {
  ARTICLES_GET_REQUEST,
  ARTICLES_GET_SUCCESS,
  ARTICLES_GET_FAILURE,
  ARTICLES_SINGLE_GET_REQUEST,
  ARTICLES_SINGLE_GET_SUCCESS,
  ARTICLES_SINGLE_GET_FAILURE,
  ARTICLES_CHANGES_UNDO_FAILURE,
} from 'data/constants';

import history from 'core/history';
import API from 'data/fetch';

export function* getArticlesList(action) {
  yield put({ type: ARTICLES_GET_REQUEST });
  try {
    const { data } = yield call(() => API.articles.getList(action.params));
    if (data) yield put({ type: ARTICLES_GET_SUCCESS, data });
  } catch (error) {
    yield put({ type: ARTICLES_GET_FAILURE, error: error.message });
  }
}

export function* getSingleArticle(action) {
  yield put({ type: ARTICLES_SINGLE_GET_REQUEST });
  try {
    const { data } = yield call(() => API.articles.get(action.params));
    if (data.sportxpert_type === 'match') {
      const { data: eventData } = yield call(() => API.events.get(data.sportxpert_id));
      data.event = eventData;
    }

    if (data) yield put({ type: ARTICLES_SINGLE_GET_SUCCESS, data });
  } catch (error) {
    yield put({ type: ARTICLES_SINGLE_GET_FAILURE, error: error.message });
  }
}

export function* createArticle(action) {
  yield call(history.push, `/articles/${action.req.slug}`);
}

export function* editArticle(action) {
  if (action.successEffect) {
    yield action.successEffect();
  }
}

export function* undoAction(action) {
  try {
    // revert to previous state
    yield call(() => API.articles.put(action.article.slug, action.article));
    // Run effect if it exists
    if(action.effect) {
      yield action.effect();
    }
  } catch (err) {
    yield put({
      type: ARTICLES_CHANGES_UNDO_FAILURE,
      err,
      tryAgainData: {
        ...action,
      },
    });
  }
}

export function* deleteArticle(action) {
  if (action.redirect) {
    yield call(history.push, '/articles');
  }
}
