import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip, Button, Position } from '@blueprintjs/core';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';

import { openNavigation, closeNavigation } from 'data/actions/general';

import { ImageLabel } from 'shared/components';

import styles from './Header.module.scss';

class Header extends Component {
  static propTypes = {
    profile: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      picture: PropTypes.shape({
        url: PropTypes.string,
      }).isRequired,
    }),
    isNavigationOpen: PropTypes.bool.isRequired,
    openNav: PropTypes.func.isRequired,
    closeNav: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    const { isNavigationOpen, openNav, closeNav } = this.props;

    if (isNavigationOpen) {
      closeNav();
    } else {
      openNav();
    }
  }

  render() {
    const { isNavigationOpen, profile } = this.props;

    return (
      <header
        className={classNames(styles.root, {
          [styles.isClosed]: !isNavigationOpen,
        })}
      >
        <div className={classNames(styles.inner, 'between-xs')}>
          <div className={styles.mainArea}>
            <Tooltip
              className={styles.hamburger}
              content={isNavigationOpen ? 'Close menu' : 'Open menu'}
              position={Position.RIGHT}
            >
              <Button
                large
                minimal
                icon="menu"
                onClick={this.toggleMenu}
              />
            </Tooltip>
            <Link to="/">
              <div
                className={classNames(styles.logo, {
                  [styles.isClosed]: !isNavigationOpen,
                })}
              >
                <img
                  src={process.env.REACT_APP_CLIENT_LOGO}
                  alt={process.env.REACT_APP_CLIENT_NAME}
                />
              </div>
            </Link>
          </div>
          <NavLink
            to="/me"
            className={styles.userButton}
            activeClassName={styles.active}
          >
            <ImageLabel
              imageSrc={profile.picture.url}
              imageAlt={`${profile.first_name} ${profile.last_name}`}
              label={isNavigationOpen && <span className={styles.userLabel}>{`${profile.first_name} ${profile.last_name}`}</span>}
            />
          </NavLink>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.me.data,
  isNavigationOpen: state.general.isNavigationOpen,
});

const mapDispatchToProps = dispatch => ({
  openNav: () => dispatch(openNavigation()),
  closeNav: () => dispatch(closeNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Header);
