import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactCrop, { makeAspectCrop, getPixelCrop } from 'react-image-crop';
import { Button, Intent } from '@blueprintjs/core';

import 'react-image-crop/dist/ReactCrop.css';

import ConfirmationPanel from '../Confirmation';

import uploadMediaStyles from '../../UploadMedia.module.scss';
import cropStyles from './Crop.module.scss';

class CropPanel extends Component {
  static propTypes = {
    imageProps: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }

  constructor(props) {
    super(props);
    this.state = {
      image: {
        ...props.mediaFile,
        preview: URL.createObjectURL(props.mediaFile),
      },
      crop: null,
      pixel: null,
    };

    this.cropper = React.createRef();

    this.onImageLoaded = this.onImageLoaded.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.onCrop = this.onCrop.bind(this);
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(this.state.image.preview);
  }

  getAspectRatio() {
    const { imageProps } = this.props;

    if (imageProps) {
      return {
        aspect: imageProps.width / imageProps.height,
        width: 100,
      };
    }

    return {
      aspect: 1,
      width: 100,
      height: 100,
    };
  }

  onImageLoaded(image) {
    const { resize } = this.props;

    const crop = makeAspectCrop({
      ...this.getAspectRatio(),
      x: 0,
      y: 0,
    }, image.width / image.height);
    const pixel = getPixelCrop(image, crop);
    this.setState({
      crop,
      pixel,
    });
    // Resize parent stack
    resize();
  }

  onChange(crop, pixel) {
    this.setState({
      crop,
      pixel,
    });
  }

  onComplete(crop, pixel) {
    this.setState({
      crop,
      pixel,
    });
  }

  onCrop() {
    const { openPanel, ...props } = this.props;
    const { pixel } = this.state;

    openPanel({
      component: ConfirmationPanel,
      props: {
        ...props,
        pixel,
      },
      title: 'Upload picture',
    });
  }

  render() {
    const { width, height, imageProps = {} } = this.props;
    const { image, crop } = this.state;

    return (
      <Fragment>
        <div className={classnames(
          cropStyles.wrapper,
          {
            [cropStyles['Crop--circle']]: imageProps.cropCircle,
          }
        )}>
          <ReactCrop
            keepSelection
            crop={crop}
            ref={this.cropper}
            minWidth={width}
            minHeight={height}
            src={image.preview}
            onChange={this.onChange}
            onComplete={this.onComplete}
            onImageLoaded={this.onImageLoaded}
          />
        </div>
        <div className={uploadMediaStyles.actions}>
          <Button
            intent={Intent.PRIMARY}
            onClick={this.onCrop}
          >
            Crop picture
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default CropPanel;
