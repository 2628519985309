import addPDF from './modifiers/addPDF';
import PDFComponent from './PDF';

export default (config = {}) => {
  let PDF = config.pdfComponent || PDFComponent;
  if (config.decorator) {
    PDF = config.decorator(PDF);
  }
  return {
    blockRendererFn: (block, { getEditorState }) => {
      if (block.getType() === 'atomic') {
        const contentState = getEditorState().getCurrentContent();
        const entity = block.getEntityAt(0);
        if (!entity) return null;
        const type = contentState.getEntity(entity).getType();
        if (type === 'PDF' || type === 'pdf') {
          return {
            component: PDF,
            editable: false,
          };
        }
        return null;
      }

      return null;
    },
    addPDF,
  };
};

export const PDF = PDFComponent;
