import { all, fork } from 'redux-saga/effects';

import auth from './auth';
import overview from './overview';
import profile from './profile';
import dashboard from './dashboard';

import articles from './articles';
import blogs from './blogs';
import newspapers from './newspapers';

export default function* main() {
  yield all([
    fork(auth),
    fork(overview),
    fork(profile),
    fork(dashboard),

    fork(articles),
    fork(blogs),
    fork(newspapers),
  ]);
}
