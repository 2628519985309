import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware, { END } from 'redux-saga';
import rootReducer from 'data/reducers';
import promiseMiddleware from 'data/middlewares/promises';
import notificationMiddleware from 'data/middlewares/notifications';
import createLogger from 'data/middlewares/logger';

/*
 * @param {Object} initial state to bootstrap our stores with for server-side rendering
 */
export default function configureStore(initialState) {
  // Installs hooks that always keep react-router and redux
  // store in sync
  const sagaMiddleware = createSagaMiddleware();
  const enhancers = [];
  const middleware = [
    thunk,
    promiseMiddleware,
    sagaMiddleware,
    notificationMiddleware,
  ];

  if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());

    const devToolsExtension = window.devToolsExtension;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );
  const store = createStore(rootReducer, initialState, composedEnhancers);

  store.runSagas = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return store;
}
