import { cleanObject } from './misc';

export const addQuery = (current, query) => {
  const location = { ...current };
  location.query = {
    ...location.query,
    ...query,
  };
  location.query = cleanObject(location.query);
  return location;
};

export const removeQuery = (current, queryNames) => {
  const location = { ...current };
  location.query = {
    ...location.query,
  };
  queryNames.forEach(q => delete location.query[q]);
  return location;
};

export const addProtocolToUrl = url => {
  if (!url) return;
  // Pattern to test is it's relative url or not
  const regex = /^https?:\/\//i;
  // Check if url already contains protocol
  if (regex.test(url)) return url;

  return `http://${url}`;
};

export const getParentUrl = (url, nestingLevel = 1) => {
  if (!url) return;

  const splitUrl = url.split('/');
  const parentUrl = splitUrl.slice(0, nestingLevel + 1).join('/');

  return parentUrl;
};

export default {
  addQuery,
  removeQuery,
  addProtocolToUrl,
  getParentUrl,
};
