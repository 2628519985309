import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './views/List';

const NewspapersRoot = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={List} />
  </Switch>
);

export default NewspapersRoot;
