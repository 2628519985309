import React from 'react';
import { Field } from 'formik';

import { ImageUploadInput } from 'shared/components';

const FeaturedImageField = React.memo(props => {
  return (
    <Field
      labelFor="picture"
      name="picture"
      type="picture"
      component={ImageUploadInput}
      placeholder="Choose picture..."
      width={500}
      height={281}
      {...props}
    />
  );
});

FeaturedImageField.defaultProps = {
  validateOnChange: false,
};

export default FeaturedImageField;
