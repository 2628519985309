import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import ReactPaginate from 'react-paginate';

import { addQuery } from 'shared/helpers/url';

import styles from './Pagination.module.scss';

class Pagination extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }
  constructor(props) {
    super(props);

    this.state = {
      page: parseInt(props.location.query.page, 10) || 1,
      pageRange: 5,
    };
    this.setPage = this.setPage.bind(this);
  }

  componentDidMount() {
    this.checkIfValidLastPage();
  }

  checkIfValidLastPage() {
    const { history, location } = this.props;
    const { page } = this.state;
    const pageCount = this.getPageCount;
    // If current page is higher than total number of pages
    // Redirect to valid last page
    if (page > pageCount) {
      history.replace(addQuery(location, {
        page: pageCount,
      }));
    }
  }

  setPage({ selected: page }) {
    const { history, location } = this.props;
    this.setState({
      page: page + 1,
    });
    history.push(addQuery(location, {
      page: page + 1,
    }));
  }

  get getPageCount() {
    const { count, limit } = this.props;
    return Math.ceil(count / limit) || 1;
  }

  render() {
    const { page, pageRange } = this.state;

    return this.getPageCount > 1 ? (
      <ReactPaginate
        disableInitialCallback
        containerClassName={styles.root}
        pageClassName={styles.page}
        pageLinkClassName={styles.link}
        previousClassName={styles.page}
        previousLinkClassName={styles.link}
        nextClassName={styles.page}
        nextLinkClassName={styles.link}
        disabledClassName={styles.disabled}
        breakClassName={styles.link}
        initialPage={page - 1}
        breakLabel={<span>...</span>}
        pageCount={this.getPageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={pageRange}
        onPageChange={this.setPage}
        hrefBuilder={p => `?page=${p}`}
      />
    ) : null;
  }
}

Pagination = withRouter(Pagination);

export default Pagination;
