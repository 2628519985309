import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import classNames from 'classnames';
import { Overlay, Intent } from '@blueprintjs/core';

import history from 'core/history';

import Types from 'shared/types/Articles.types';

import { Button } from 'shared/components';

import SettingsItems from './SettingsItems';
import * as components from './components';

import { getSidebarValidationSchema } from 'views/Articles/Articles.validations';

import './SettingsSidebar.scss';
import styles from './SettingsSidebar.module.scss';

export const sections = Object.keys(components)
  .filter(component => component.includes('Section'))
  .reduce((acc, section) => ({ ...acc, [section]: section }), {});

const saveArticle = async(
  article,
  values,
  { setSubmitting, setErrors, edit, undo },
  showToast
) => {
  try {
    const undoEffect = () => undo(article);
    await edit(
      article.slug,
      article,
      values,
      null,
      undoEffect,
      showToast,
    );
  } catch (err) {
    const errors = err && err.errors ? err.errors : { _error: 'Something went wrong' };
    if (errors.sportxpert_id) {
      switch (values.sportxpert_type) {
        case 'match':
          errors.sportxpert_match_id = errors.sportxpert_id;
          break;
        case 'season':
          errors.sportxpert_season_id = errors.sportxpert_id;
          break;

        default:
          break;
      }
    }
    setErrors(errors);
  } finally {
    setSubmitting(false);
  }
};

const formikEnhancer = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ article }) => ({
    sport_id: !!article.sport && article.sport.id,
    sportxpert_season_id: article.sportxpert_type === 'season' ? article.sportxpert_id : ((article.event || {}).season || {}).uuid,
    sportxpert_match_id: article.sportxpert_type === 'match' ? article.sportxpert_id : undefined,
    ...article,
  }),
  validationSchema: ({ article, type }) => getSidebarValidationSchema(article.status, type),
  displayName: 'SettingsSidebarForm',
  handleSubmit: (values, { props: { article, edit, undo }, ...props }) =>
    saveArticle(article, values, { ...props, edit, undo }, true),
});

const SettingsSidebar = React.memo(({
  article,
  type,
  openedSections,
  onClose,
  edit, remove,
  pristine, isValid, isSubmitting, values, setFieldValue,
  handleChange, handleSubmit,
}) => {
  const archiveArticle = useCallback(async() => {
    await edit(
      article.slug,
      article,
      {
        archived_at: new Date(),
      },
      () => history.push(`/${type}`),
      true,
    );
  }, [article.slug]);

  const deleteArticle = useCallback(async() => {
    await remove(article.slug);
  }, [article.slug, type]);

  return (
    <Fragment>
      <Overlay
        backdropClassName="Backdrop"
        transitionName="Transition"
        isOpen={Array.isArray(openedSections)}
        onClose={onClose}
        enforceFocus={false}
      >
        <div className="Sidebar-root">
          <form
            onChange={handleChange}
            onSubmit={handleSubmit}
          >
            <div className={classNames(styles.header, 'between-xs', 'middle-xs')}>
              <h3 className="m-b-0">Settings</h3>
              <Button
                intent={Intent.PRIMARY}
                disabled={pristine || !isValid || isSubmitting}
                type="submit"
              >
                Save changes
              </Button>
            </div>
            <ul className={classNames(styles.list, 'bp3-list', 'bp3-list-unstyled')}>
              <SettingsItems
                type={type}
                article={article}
                values={values}
                setFieldValue={setFieldValue}
                openedSections={openedSections}
                archiveArticle={archiveArticle}
                deleteArticle={deleteArticle}
              />
            </ul>
          </form>
        </div>
      </Overlay>
    </Fragment>
  );
});

SettingsSidebar.propTypes = {
  article: Types.articleItemShape,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  undo: PropTypes.func.isRequired,
};

export default formikEnhancer(SettingsSidebar);
