// LIST
export const BLOGS_GET = 'BLOGS_GET';
export const BLOGS_GET_REQUEST = 'BLOGS_GET_REQUEST';
export const BLOGS_GET_SUCCESS = 'BLOGS_GET_SUCCESS';
export const BLOGS_GET_FAILURE = 'BLOGS_GET_FAILURE';

// SINGLE
export const BLOGS_SINGLE_GET = 'BLOGS_SINGLE_GET';
export const BLOGS_SINGLE_GET_REQUEST = 'BLOGS_SINGLE_GET_REQUEST';
export const BLOGS_SINGLE_GET_SUCCESS = 'BLOGS_SINGLE_GET_SUCCESS';
export const BLOGS_SINGLE_GET_FAILURE = 'BLOGS_SINGLE_GET_FAILURE';

// CREATE
export const BLOGS_POST = 'BLOGS_POST';
export const BLOGS_POST_REQUEST = 'BLOGS_POST_REQUEST';
export const BLOGS_POST_SUCCESS = 'BLOGS_POST_SUCCESS';
export const BLOGS_POST_FAILURE = 'BLOGS_POST_FAILURE';

// EDIT
export const BLOGS_PUT = 'BLOGS_PUT';
export const BLOGS_PUT_REQUEST = 'BLOGS_PUT_REQUEST';
export const BLOGS_PUT_SUCCESS = 'BLOGS_PUT_SUCCESS';
export const BLOGS_PUT_FAILURE = 'BLOGS_PUT_FAILURE';

// DELETE
export const BLOGS_DELETE = 'BLOGS_DELETE';
export const BLOGS_DELETE_REQUEST = 'BLOGS_DELETE_REQUEST';
export const BLOGS_DELETE_SUCCESS = 'BLOGS_DELETE_SUCCESS';
export const BLOGS_DELETE_FAILURE = 'BLOGS_DELETE_FAILURE';

// OTHERS
export const BLOGS_CREATE_NEW = 'BLOGS_CREATE_NEW';
export const BLOGS_CANCEL = 'BLOGS_CANCEL';
export const BLOGS_CHANGES_UNDO = 'BLOGS_CHANGES_UNDO';
export const BLOGS_CHANGES_UNDO_FAILURE = 'BLOGS_CHANGES_UNDO_FAILURE';
export const BLOGS_ADD_TO_LIST = 'BLOGS_ADD_TO_LIST';
export const BLOGS_REMOVE_FROM_LIST = 'BLOGS_REMOVE_FROM_LIST';

export default {
  BLOGS_GET,
  BLOGS_GET_REQUEST,
  BLOGS_GET_SUCCESS,
  BLOGS_GET_FAILURE,
  BLOGS_SINGLE_GET,
  BLOGS_SINGLE_GET_REQUEST,
  BLOGS_SINGLE_GET_SUCCESS,
  BLOGS_SINGLE_GET_FAILURE,
  BLOGS_POST,
  BLOGS_POST_REQUEST,
  BLOGS_POST_SUCCESS,
  BLOGS_POST_FAILURE,
  BLOGS_PUT,
  BLOGS_PUT_REQUEST,
  BLOGS_PUT_SUCCESS,
  BLOGS_PUT_FAILURE,
  BLOGS_DELETE,
  BLOGS_DELETE_REQUEST,
  BLOGS_DELETE_SUCCESS,
  BLOGS_DELETE_FAILURE,
  BLOGS_CREATE_NEW,
  BLOGS_CANCEL,
  BLOGS_CHANGES_UNDO,
  BLOGS_CHANGES_UNDO_FAILURE,
  BLOGS_ADD_TO_LIST,
  BLOGS_REMOVE_FROM_LIST,
};
