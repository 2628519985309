import PropTypes from 'prop-types';

export const inputTypes = {
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
};

export default {
  inputTypes,
};
