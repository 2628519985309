import { noop } from 'lodash';
import {
  BLOGS_POST,
  BLOGS_PUT,
  BLOGS_DELETE,
  BLOGS_ADD_TO_LIST,
  BLOGS_REMOVE_FROM_LIST,
  BLOGS_CHANGES_UNDO,
} from 'data/constants';
import API from 'data/fetch';

export const addBlog = data => dispatch => {
  const promise = API.blogs.post(data);

  return dispatch({
    type: BLOGS_POST,
    promise,
    tryAgain: () => dispatch(addBlog(data)),
  });
};

export const editBlog = (...args) => dispatch => {
  const [id, prevData, nextData, successEffect = noop, undoEffect = noop, showToast = true] = args;
  const promise = API.blogs.put(id, nextData);
  return dispatch({
    type: BLOGS_PUT,
    promise,
    id,
    prevData,
    successEffect,
    undoEffect,
    showToast,
    tryAgain: () => dispatch(editBlog(...args)),
  });
};

export const deleteBlog = (id, redirect = false) => dispatch => {
  const promise = API.blogs.del(id);

  return dispatch({
    type: BLOGS_DELETE,
    promise,
    id,
    redirect, // Flag deciding if saga should redirect to list or stay in the same page
    tryAgain: () => dispatch(deleteBlog(id, redirect)),
  });
};

export const undoBlogChange = (article, effect) => dispatch => dispatch({
  type: BLOGS_CHANGES_UNDO,
  article,
  effect,
});

export const addBlogToList = article => dispatch => dispatch({
  type: BLOGS_ADD_TO_LIST,
  article,
});

export const removeBlogFromList = id => dispatch => dispatch({
  type: BLOGS_REMOVE_FROM_LIST,
  id,
});

export default {
  addBlog,
  editBlog,
  deleteBlog,
  undoBlogChange,
  addBlogToList,
  removeBlogFromList,
};
