import React, { Fragment, useMemo } from 'react';
import { Field } from 'formik';
import { Button } from '@blueprintjs/core';
import * as yup from 'yup';

import { FileInput, Input, DateRangePicker, DatePicker, ErrorBlock } from 'shared/components';
import NewspapersList from 'views/Newspapers/views/List';

import { WithSports } from 'shared/hocs';

import { validateField } from 'shared/helpers/validators';

let NewspaperUploader = ({
  sports,
  values, setFieldValue, categories, categoryKey, errors, isSubmitting,
}) => {
  const { isNewspaperSelected, isLatestNewsSelected, isHorsesSelected } = useMemo(() => {
    try {
      const selectedNewspaperType = categories
        .find(category => Number(category.meta.id) === Number(values[categoryKey]));
      const selectedSport = sports.find(sport => sport.id === selectedNewspaperType.meta.sport_id);

      const isNewspaperSelected = selectedNewspaperType.meta.type === 'newspaper';
      const isLatestNewsSelected = selectedNewspaperType.meta.type === 'latest_news';
      const isHorsesSelected = selectedSport.name === 'Horses';

      return { isNewspaperSelected, isLatestNewsSelected, isHorsesSelected };
    } catch (error) {
      return {};
    }
  }, [values[categoryKey], categories.length]);

  const handleChangeAppliedFor = ([appliedFrom, appliedTo]) => {
    setFieldValue('applied_from', appliedFrom);
    setFieldValue('applied_to', appliedTo);
  };

  return (
    <Fragment>
      <div className="col-sm-6">
        <Field
          large
          name="file"
          type="text"
          component={FileInput}
          inputProps={{
            accept: 'application/pdf',
          }}
          label="Upload"
        />
        {isNewspaperSelected && (
          <Field
            large
            name="issue_number"
            type="number"
            component={Input}
            label="Newspaper number"
            validate={validateField(
              yup
                .number()
                .nullable()
                .required('Newspaper number is required')
                .positive('Number must be positive')
                .transform((value, originalValue) => typeof originalValue === 'string' ? null : value)
            )}
          />
        )}
        {isHorsesSelected && isLatestNewsSelected ? (
          <Field
            large
            inline
            singleMonthOnly
            name="published_at"
            type="text"
            component={DatePicker}
            label="Newspaper for"
            shortcuts={false}
            validate={validateField(yup.date().required('Publish date is required'))}
            buttonProps={{
              minimal: true,
              small: true,
              intent: 'primary',
              alignText: 'right',
            }}
          />
        ) : (
          <Field
            large
            name="applied_for"
            component={DateRangePicker}
            label="Newspaper for"
            onChange={handleChangeAppliedFor}
            validate={validateField(yup.array().of(yup.date().required('Date is required')))}
          />
        )}
      </div>
      <br />
      <ErrorBlock
        errors={errors}
        className="m-b-20"
      />
      <Button
        large
        type="submit"
        disabled={isSubmitting}
        loading={isSubmitting}
        intent="primary"
      >
        Upload
      </Button>

      <div className="m-t-30">
        <NewspapersList
          filterBy="newspaper_kind.id"
          filterValue={Number(values.newspaper_kind_id)}
        />
      </div>
    </Fragment>
  );
};

NewspaperUploader = WithSports(NewspaperUploader);

export default NewspaperUploader;
