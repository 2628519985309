import React, { Component } from 'react';
import { FormGroup } from '@blueprintjs/core';

import { Dropzone } from 'shared/components';

class DropzoneInput extends Component {
  static defaultProps = {
    placeholder: 'Drop files here, or click in this area.',
  }

  static getDerivedStateFromProps = ({ field }) => {
    if (field) {
      if (field.value !== null) {
        return {
          files: Array.isArray(field.value) ? field.value : [{ // eslint-disable-line
            name: field.name,
            preview: field.value,
          }],
        };
      }
      return {
        files: [],
      };
    }
    return;
  }

  constructor(props) {
    super(props);

    this.state = {
      files: [],
    };
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  render() {
    const {
      error,
      helperText,
      labelFor,
      intent,
      field,
      form,
      ...props
    } = this.props;
    const { files } = this.state;

    return (
      <FormGroup
        helperText={error ? error : helperText}
        labelFor={labelFor}
        intent={error ? 'danger' : intent}
        {...props}
        {...form}
      >
        <Dropzone
          hideDropArea
          {...this.props}
          files={files}
          onDrop={files => form.setFieldValue(field.name, files)}
        />
      </FormGroup>
    );
  }
}

export default DropzoneInput;
