import {
  LOADING_STATES,

  BETTING_CARDS_GET_REQUEST,
  BETTING_CARDS_GET_SUCCESS,
  BETTING_CARDS_GET_FAILURE,

  BETTING_CARDS_PUT_REQUEST,
  BETTING_CARDS_PUT_SUCCESS,
  BETTING_CARDS_PUT_FAILURE,
} from 'data/constants';

const defaultState = {
  state: LOADING_STATES.INITIAL,
  list: [],
  error: null,
};

export default function bettingCardsList(state = defaultState, action) {
  switch (action.type) {
    case BETTING_CARDS_GET_REQUEST:
    case BETTING_CARDS_PUT_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      };
    case BETTING_CARDS_GET_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        list: action.req,
      };
    case BETTING_CARDS_GET_FAILURE:
    case BETTING_CARDS_PUT_FAILURE:
      return {
        ...state,
        state: LOADING_STATES.FAILED,
        error: action.error.message,
      };
    case BETTING_CARDS_PUT_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        list: state.list.map(bettingCard => bettingCard.id === action.req.id
          ? action.req
          : bettingCard
        ),
      };
    default:
      return state;
  }
}
