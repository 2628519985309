import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { Card, Button } from '@blueprintjs/core';

import { passwordRegExp } from 'shared/helpers/validators';

import { editPassword } from 'data/actions/profile';

import { Input, ErrorBlock } from 'shared/components';

class UserPassword extends Component {
  static propTypes = {
    update: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.editProfile = this.editProfile.bind(this);
  }

  schema = yup.object().shape({
    password: yup
      .string()
      .trim()
      .required('Password is required'),
    new_password: yup
      .string()
      .trim()
      .matches(passwordRegExp, 'Password mas have at least one number, one lowercase and one uppercase letter and at least six characters')
      .required('Password is required'),
    repeat_password: yup
      .string()
      .oneOf([yup.ref('new_password'), null], 'Passwords must match')
      .required('Repeat your new password'),
  });

  async editProfile(values, actions) {
    const { update } = this.props;
    try {
      await update(values);
    } catch (err) {
      const error = err && err.error ? err.error.description :
        'Something went wrong. Please make sure your current password is correct.';
      actions.setErrors({
        _error: error,
      });
    }
    actions.setSubmitting(false);
  }

  render() {
    const { details } = this.props;

    return (
      <Fragment>
        <Helmet
          title="Password"
        />
        <div className="row">
          <div className="col-sm-7">
            <Card>
              <Formik
                enableReinitialize
                onSubmit={this.editProfile}
                validationSchema={this.schema}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={details}
                render={({
                  errors,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className="m-b-10">
                    <Field
                      large
                      labelFor="password"
                      name="password"
                      type="password"
                      component={Input}
                      label="Current password"
                      error={errors.password}
                    />
                    <Field
                      large
                      labelFor="new_password"
                      name="new_password"
                      type="password"
                      component={Input}
                      label="New password"
                      error={errors.new_password}
                    />
                    <Field
                      large
                      labelFor="repeat_password"
                      name="repeat_password"
                      type="password"
                      component={Input}
                      label="Repeat password"
                      error={errors.repeat_password}
                    />
                    <ErrorBlock
                      errors={errors}
                      className="m-b-20"
                    />
                    <Button
                      large
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      intent="primary"
                    >
                      Update password
                    </Button>
                  </form>
                )}
              />
            </Card>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  update: data => dispatch(editPassword(data)),
});

export default connect(
  null,
  mapDispatchToProps
)(UserPassword);
