import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { FormGroup, FileInput as FileInputBP } from '@blueprintjs/core';

import { inputTypes } from './Forms.types';

const propTypes = {
  ...inputTypes,
  showThumb: PropTypes.bool,
};

const defaultProps = {
  showThumb: false,
  placeholder: 'Choose file...',
  intent: 'default',
  onChange: null,
};

export class FileInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingThumb: false,
      thumb: null,
    };

    this.updateImage = this.updateImage.bind(this);
  }

  componentDidMount() {
    const { showThumb, value } = this.props;
    if (showThumb && value && value.url) {
      this.setState({ thumb: value.url });
    }
  }

  componentDidUpdate({ field: { value: prevFile }  }) {
    const { showThumb, value: file } = this.props;

    if (!file || !showThumb) return;
    if (!file.lastModified) return;

    if(!isEqual(prevFile, file)) {
      this.setState({ loading: true }, () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.setState({ loadingThumb: false, thumb: reader.result });
        };

        reader.readAsDataURL(file);
      });
    }
  }

  updateImage(file) {
    const { form: { setFieldValue }, field: { name } } = this.props;
    if (file) {
      setFieldValue(name, file);
    }
  }

  renderThumb() {
    const { field } = this.props;
    const { loadingThumb, thumb } = this.state;

    if (loadingThumb) {
      return <p>loading...</p>;
    }

    if (!thumb) return null;

    return (
      <img
        style={{
          border: '1px solid #ccc',
          marginTop: 5,
          padding: 3,
          borderRadius: 3,
        }}
        src={thumb}
        alt={field.value.name}
      />
    );
  }

  render() {
    const {
      showThumb,
      helperText,
      labelFor,
      placeholder,
      intent,
      field,
      form,
      ...props
    } = this.props;
    const error = form.errors[field.name];

    return (
      <FormGroup
        helperText={error ? error : helperText}
        labelFor={labelFor}
        intent={error ? 'danger' : intent}
        {...props}
        {...form}
      >
        <FileInputBP
          className={error ? 'bp3-intent-danger' : intent}
          id={labelFor}
          text={(field.value && field.value.name) || placeholder}
          {...props}
          {...field}
          onBlur={e => this.updateImage(e.currentTarget.control.files[0])}
          onChange={e => this.updateImage(e.currentTarget.control.files[0])}
          onInputChange={e => this.updateImage(e.currentTarget.files[0])}
        />
        {showThumb && this.renderThumb()}
      </FormGroup>
    );
  }
}

FileInput.propTypes = propTypes;
FileInput.defaultProps = defaultProps;

export default FileInput;
