import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';

import { ResourceListPage, Button } from 'shared/components';

import ArticlesList from './ArticlesList.redux';
import BlogsList from './BlogsList.redux';
import UserFilter from './components/UserFilter';

import { selectStrategy } from 'views/Articles/Articles.utils';

export const ArticlesListRoot = ({ overview, match }) => {
  const postsOverview = selectStrategy(match.params.type, {
    article: overview.articles,
    blog: overview.blog_posts,
  });
  const overviewItems = useMemo(() => postsOverview && [{
    key: 'all',
    title: `All (${postsOverview.total})`,
  }, {
    key: 'draft',
    title: `Drafts (${postsOverview.draft})`,
  }, {
    key: 'spell_check',
    title: `Spellcheck (${postsOverview.spell_check})`,
  }, {
    key: 'content_check',
    title: `Content check (${postsOverview.content_check})`,
  }, {
    key: 'scheduled',
    title: `Scheduled (${postsOverview.scheduled})`,
  }, {
    key: 'published',
    title: `Published (${postsOverview.published})`,
  }, {
    key: 'archived',
    title: `Archived (${postsOverview.archived})`,
  }], [postsOverview]);

  return (
    <ResourceListPage
      title="Posts"
      overviewItems={overviewItems}
      sortBy={[{
        label: 'Last edit - from newest',
        value: '-updated_at',
      }, {
        label: 'Last edit - from oldest',
        value: 'updated_at',
      }, {
        label: 'Publish date - from newest',
        value: 'published_at',
      }, {
        label: 'Publish date - from oldest',
        value: '-published_at',
      }, {
        label: 'Title - from A to Z',
        value: 'header',
      }, {
        label: 'Title - from Z to A',
        value: '-header',
      }]}
      headerRightElement={(
        <Button to="/content-creator" icon="plus">Content creator</Button>
      )}
      searchElement={(
        <UserFilter />
      )}
    >
      <Route path="/:type(articles)/:states(all|draft|spell_check|content_check|scheduled|published|archived)?" component={ArticlesList} />
      <Route path="/:type(blogs)/:states(all|draft|spell_check|content_check|scheduled|published|archived)?" component={BlogsList} />
    </ResourceListPage>
  );
};

const mapStateToProps = state => ({
  overview: state.overview.data,
});

export default connect(
  mapStateToProps,
)(ArticlesListRoot);
