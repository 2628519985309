// AUTHORIZATION
export const GENERAL_OPEN_NAVIGATION = 'GENERAL_OPEN_NAVIGATION';
export const GENERAL_CLOSE_NAVIGATION = 'GENERAL_CLOSE_NAVIGATION';

// SPORTS
export const GENERAL_SPORTS_GET = 'GENERAL_SPORTS_GET';
export const GENERAL_SPORTS_GET_REQUEST = 'GENERAL_SPORTS_GET_REQUEST';
export const GENERAL_SPORTS_GET_SUCCESS = 'GENERAL_SPORTS_GET_SUCCESS';
export const GENERAL_SPORTS_GET_FAILURE = 'GENERAL_SPORTS_GET_FAILURE';

// NEWSPAPER KINDS
export const GENERAL_NEWSPAPER_KINDS_GET = 'GENERAL_NEWSPAPER_KINDS_GET';
export const GENERAL_NEWSPAPER_KINDS_GET_REQUEST = 'GENERAL_NEWSPAPER_KINDS_GET_REQUEST';
export const GENERAL_NEWSPAPER_KINDS_GET_SUCCESS = 'GENERAL_NEWSPAPER_KINDS_GET_SUCCESS';
export const GENERAL_NEWSPAPER_KINDS_GET_FAILURE = 'GENERAL_NEWSPAPER_KINDS_GET_FAILURE';

// QUERIES
export const QUERY_PER_PAGE = 10;

export default {
  GENERAL_OPEN_NAVIGATION,
  GENERAL_CLOSE_NAVIGATION,

  GENERAL_SPORTS_GET,
  GENERAL_SPORTS_GET_REQUEST,
  GENERAL_SPORTS_GET_SUCCESS,
  GENERAL_SPORTS_GET_FAILURE,

  QUERY_PER_PAGE,
};
