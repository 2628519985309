import { all, put, fork, call, take } from 'redux-saga/effects';

import {
  ARTICLES_GET_SUCCESS,
  ARTICLES_POST_SUCCESS,
  ARTICLES_PUT_SUCCESS,
  ARTICLES_DELETE_SUCCESS,
  ARTICLES_CHANGES_UNDO,

  BLOGS_GET_SUCCESS,
  BLOGS_POST_SUCCESS,
  BLOGS_PUT_SUCCESS,
  BLOGS_DELETE_SUCCESS,
  BLOGS_CHANGES_UNDO,

  OVERVIEW_GET,
  OVERVIEW_GET_REQUEST,
  OVERVIEW_GET_SUCCESS,
  OVERVIEW_GET_FAILURE,
  OVERVIEW_CANCEL,
} from 'data/constants';

import API from 'data/fetch';

const articlesEffects = [
  ARTICLES_GET_SUCCESS,
  ARTICLES_POST_SUCCESS,
  ARTICLES_PUT_SUCCESS,
  ARTICLES_DELETE_SUCCESS,
  ARTICLES_CHANGES_UNDO,
];
const blogsEffects = [
  BLOGS_GET_SUCCESS,
  BLOGS_POST_SUCCESS,
  BLOGS_PUT_SUCCESS,
  BLOGS_DELETE_SUCCESS,
  BLOGS_CHANGES_UNDO,
];

function* getOverview() {
  while (true) {
    yield take([OVERVIEW_GET, ...articlesEffects, ...blogsEffects]);
    yield put({ type: OVERVIEW_GET_REQUEST });
    try {
      const { data } = yield call(API.overview.get);
      if (data) yield put({ type: OVERVIEW_GET_SUCCESS, data });
    } catch (error) {
      yield put({ type: OVERVIEW_GET_FAILURE, error: error.message });
    }
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([
      fork(getOverview),
    ]);

    yield all(tasks);

    yield take(OVERVIEW_CANCEL);
    yield tasks.map(task => task.cancel());
  }
}
