import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonGroup, Button } from '@blueprintjs/core';

import styles from './ScreenSizeSwitcher.module.scss';

import smartphone from './images/smartphone-dark.svg';
import tablet from './images/tablet-dark.svg';
import monitor from './images/monitor-dark.svg';

class ScreenSizeSwitcher extends PureComponent {
  static propTypes = {
    defaultDevice: PropTypes.oneOf(['smartphone', 'tablet', 'monitor']),
  }
  static defaultProps = {
    defaultDevice: 'smartphone',
  }

  constructor(props) {
    super(props);

    this.devices = [{
      key: 'smartphone',
      icon: smartphone,
    }, {
      key: 'tablet',
      icon: tablet,
    }, {
      key: 'monitor',
      icon: monitor,
    }];

    this.state = {
      selected: props.defaultDevice,
      landscape: false,
    };

    this.select = this.select.bind(this);
  }

  select(device) {
    const { onChange } = this.props;
    const { selected, landscape } = this.state;
    const newLandscape = device === selected && !landscape && device !== 'monitor';
    this.setState({
      selected: device,
      landscape: newLandscape,
    });
    onChange(device, newLandscape);
  }

  render() {
    const { selected, landscape } = this.state;
    return (
      <div className={styles.root}>
        <ButtonGroup>
          {this.devices.map(device => (
            <Button
              key={device.key}
              className={classNames(styles.device, device.key, {
                [styles.landscape]: landscape && selected === device.key,
                [styles.rotate]: selected === device.key && device.key !== 'monitor',
              })}
              active={selected === device.key}
              onClick={() => this.select(device.key)}
            >
              <img
                src={device.icon}
                alt={device.key}
              />
            </Button>
          ))}
        </ButtonGroup>
      </div>
    );
  }
}

export default ScreenSizeSwitcher;
