import { take, takeLatest, cancel } from 'redux-saga/effects';

import { BLOGS_GET, BLOGS_CANCEL } from 'data/constants';

import { getBlogsList } from './blogs.saga';

export default function* main() {
  while (true) {
    const task = yield takeLatest([BLOGS_GET], getBlogsList);

    yield take(BLOGS_CANCEL);
    yield cancel(task);
  }
}
