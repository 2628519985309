import React, { Component, Fragment } from 'react';
import { noop } from 'lodash';
import { Button, Intent } from '@blueprintjs/core';
import { Document, Page } from 'react-pdf/dist/entry.webpack';

import { postDocument } from 'data/fetch/media.fetch';

import styles from '../../UploadMedia.module.scss';

class ConfirmPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
    };

    this.uploadDocument = this.uploadDocument.bind(this);
  }

  async uploadDocument() {
    const { onUpload, mediaFile } = this.props;

    this.setState({
      uploading: true,
    });

    try {
      const { data } = await postDocument(mediaFile);
      onUpload(data);
    } catch(err) {
      noop();
    }

    this.setState({
      uploading: false,
    });
  }

  render() {
    const { mediaFile, resize } = this.props;
    const { uploading } = this.state;

    return (
      <Fragment>
        <div className={styles.resultWrapper}>
          <Document
            file={mediaFile.preview}
          >
            <Page
              pageNumber={1}
              className={styles.page}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              onLoadSuccess={() => resize(true)}
              height={500}
            />
          </Document>
        </div>
        <div className={styles.actions}>
          <Button
            intent={Intent.PRIMARY}
            onClick={this.uploadDocument}
            loading={uploading}
          >
            Upload document
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default ConfirmPDF;
