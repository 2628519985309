import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Dropzone } from 'shared/components';

import styles from '../../UploadMedia.module.scss';

class DropzonePanel extends Component {
  static propTypes = {
    accept: PropTypes.string,
    maxSize: PropTypes.number,
  }
  static defaultProps = {
    accept: 'image/x-png,image/jpeg,application/pdf',
    maxSize: 10000000,
  }
  constructor(props) {
    super(props);

    this.openNextPanel = this.openNextPanel.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openNextPanel(mediaFile) {
    const { openPanel, panelStack, ...props } = this.props;
    openPanel({
      component: panelStack[1].component,
      title: panelStack[1].title,
      props: {
        ...props,
        ...panelStack[1].props,
        mediaFile,
      },
    });
  }

  onDrop(files) {
    const { onDrop } = this.props;
    const mediaFile = files[0];
    onDrop(mediaFile);
    this.openNextPanel(mediaFile);
  }

  render() {
    const { accept, maxSize } = this.props;
    return (
      <Fragment>
        <div className={styles.dropzoneWrapper}>
          <Dropzone
            ref={this.dropzone}
            hideDropArea
            accept={accept}
            maxSize={maxSize}
            className={styles.dropzone}
            onDrop={this.onDrop}
          />
        </div>
      </Fragment>
    );
  }
}

export default DropzonePanel;
