import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NonIdealState } from '@blueprintjs/core';

import Types from 'shared/types/Articles.types';

import { DataList, ArticleItem, Pagination } from 'shared/components';

export class PostsList extends Component {
  static propTypes = {
    fetchData: PropTypes.func.isRequired,
    cancelFetch: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape(Types.articleItemProperties)).isRequired,
  }

  render() {
    const { location, match, count, items } = this.props;
    const { type } = match.params;

    if (count === 0) {
      return (
        <NonIdealState
          icon="bookmark"
          title="No articles here"
          description="There are no articles in this section"
        />
      );
    }

    return (
      <Fragment>
        <DataList
          list={items.map(item => ({
            key: item.slug,
            component: (
              <Link to={`/${type}/${item.slug}`}>
                <ArticleItem {...item} />
              </Link>
            ),
          }))}
        />
        <Pagination
          count={count}
          limit={location.query.limit || 10}
        />
      </Fragment>
    );
  }
}

export default PostsList;
