import React, { Fragment } from 'react';
import { Field } from 'formik';

import { DatePicker } from 'shared/components';

const PublishingSection = React.memo(({}) => {
  return (
    <Fragment>
      <Field
        large
        inline
        singleMonthOnly
        className="align-items-center text-right"
        labelFor="published_at"
        name="published_at"
        type="text"
        component={DatePicker}
        label="Publish"
        timePrecision="minute"
        shortcuts={false}
        buttonProps={{
          minimal: true,
          small: true,
          intent: 'primary',
          alignText: 'right',
        }}
      />
    </Fragment>
  );
});

export default PublishingSection;
