import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';
import request from 'axios';

import CONFIG from 'core/config';

export function getList(params) {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.sportxpert}/fixtures/events`,
    headers: {
      'X-SportXpertApiKey': CONFIG.settings.sportxpertToken,
    },
    withCredentials: false,
    method: 'GET',
    params: {
      per_page: 20,
      ...params,
    },
  };
  const promise = request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export function get(id) {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.sportxpert}/fixtures/events/${id}`,
    headers: {
      'X-SportXpertApiKey': CONFIG.settings.sportxpertToken,
    },
    method: 'GET',
  };
  const promise = request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export default {
  getList,
  get,
};
