import React, { useState, Fragment } from 'react';
import { Popover, Menu, MenuItem } from '@blueprintjs/core';

import 'draft-js-image-plugin/lib/plugin.css';

import { Button } from 'shared/components';

import { AddImage, AddVideo, AddPDF, AddWidget } from './components';

const MediaUploads = ({
  imagePlugin, videoPlugin, pdfPlugin, widgetPlugin,
  values,
  setFieldValue,
}) => {
  const [imageUploadModule, setImageUploadModule] = useState(false);
  const [videoUploadModule, setVideoUploadModule] = useState(false);
  const [pdfUploadModule, setPdfUploadModule] = useState(false);
  const [widgetModule, setWidgetModule] = useState(false);

  return (
    <Fragment>
      <Popover
        content={(
          <Menu>
            <MenuItem text="Add image" icon="media" onClick={() => setImageUploadModule(true)} />
            <MenuItem text="Add video" icon="video" onClick={() => setVideoUploadModule(true)} />
            <MenuItem text="Add PDF" icon="document" onClick={() => setPdfUploadModule(true)} />
            <MenuItem text="Add widget" icon="document" onClick={() => setWidgetModule(true)} />
          </Menu>
        )}
      >
        <Button rightIcon="caret-down" icon="plus" text="Add" />
      </Popover>

      <AddImage
        isOpen={imageUploadModule}
        onChange={state => setFieldValue('editorState', state, false)}
        onClose={() => setImageUploadModule(false)}
        editorState={values.editorState}
        modifier={imagePlugin.addImage}
      />
      <AddVideo
        isOpen={videoUploadModule}
        onChange={state => setFieldValue('editorState', state, false)}
        onClose={() => setVideoUploadModule(false)}
        editorState={values.editorState}
        modifier={videoPlugin.addVideo}
      />
      <AddPDF
        isOpen={pdfUploadModule}
        onChange={state => setFieldValue('editorState', state, false)}
        onClose={() => setPdfUploadModule(false)}
        editorState={values.editorState}
        modifier={pdfPlugin.addPDF}
      />
      <AddWidget
        isOpen={widgetModule}
        onChange={state => setFieldValue('editorState', state, false)}
        onClose={() => setWidgetModule(false)}
        editorState={values.editorState}
        // modifier={widgetPlugin.addWidget}
        widgetPlugin={widgetPlugin}
      />
    </Fragment>
  );
};

export default MediaUploads;
