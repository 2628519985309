import { all, put, fork, call, take } from 'redux-saga/effects';

import {
  AUTH_GET,
  PROFILE_GET_REQUEST,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAILURE,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_CANCEL,
} from 'data/constants';

import history from 'core/history';
import API from 'data/fetch';

function* authorize() {
  while (true) {
    yield take([AUTH_GET]);
    yield put({ type: PROFILE_GET_REQUEST });
    try {
      const { data } = yield call(() => API.profile.get());
      if (data) yield put({ type: PROFILE_GET_SUCCESS, data });
    } catch (error) {
      yield put({ type: PROFILE_GET_FAILURE, error: error.message });
    }
  }
}

function* login() {
  while (true) {
    yield take([AUTH_LOGIN_SUCCESS]);
    // Get the user profile
    yield put({ type: AUTH_GET });
  }
}

function* logout() {
  while (true) {
    yield take([AUTH_LOGOUT]);
    yield call(() => API.auth.logout());
    history.push('/');
  }
}

export default function* main() {
  while (true) {
    const tasks = yield all([
      fork(authorize),
      fork(login),
      fork(logout),
    ]);

    yield all(tasks);

    yield take(AUTH_CANCEL);
    yield tasks.map(task => task.cancel());
  }
}
