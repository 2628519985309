import {
  LOADING_STATES,
  PROFILE_GET_REQUEST,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAILURE,
  GENERAL_OPEN_NAVIGATION,
  GENERAL_CLOSE_NAVIGATION,

  GENERAL_SPORTS_GET_REQUEST,
  GENERAL_SPORTS_GET_SUCCESS,
  GENERAL_SPORTS_GET_FAILURE,

  GENERAL_NEWSPAPER_KINDS_GET_SUCCESS,
  AUTH_LOGOUT,
} from 'data/constants';

const defaultState = {
  states: {
    PROFILE_GET: LOADING_STATES.INITIAL,
    GENERAL_SPORTS_GET: LOADING_STATES.INITIAL,
    GENERAL_NEWSPAPER_KINDS_GET: LOADING_STATES.INITIAL,
  },
  isNavigationOpen: true,
  sports: [],
  newspaperKinds: [],
};

export default function general(state = defaultState, action) {
  switch (action.type) {
    case GENERAL_OPEN_NAVIGATION:
      return {
        ...state,
        isNavigationOpen: true,
      };
    case GENERAL_CLOSE_NAVIGATION:
      return {
        ...state,
        isNavigationOpen: false,
      };
    case PROFILE_GET_REQUEST:
    case GENERAL_SPORTS_GET_REQUEST:
      return {
        ...state,
        states: {
          ...state.states,
          [action.type.replace('_REQUEST', '')]: LOADING_STATES.LOADING,
        },
      };
    case PROFILE_GET_FAILURE:
    case GENERAL_SPORTS_GET_FAILURE:
      return {
        ...state,
        states: {
          ...state.states,
          [action.type.replace('_FAILURE', '')]: LOADING_STATES.FAILED,
        },
      };
    case PROFILE_GET_SUCCESS:
      return {
        ...state,
        states: {
          ...state.states,
          [action.type.replace('_SUCCESS', '')]: LOADING_STATES.LOADED,
        },
      };
    case GENERAL_SPORTS_GET_SUCCESS:
      return {
        ...state,
        sports: action.req,
        states: {
          ...state.states,
          [action.type.replace('_SUCCESS', '')]: LOADING_STATES.LOADED,
        },
      };
    case GENERAL_NEWSPAPER_KINDS_GET_SUCCESS:
      return {
        ...state,
        newspaperKinds: action.req,
      };
    case AUTH_LOGOUT:
      return defaultState;
    default:
      return state;
  }
}
