import React, { Suspense } from 'react';
import { Spinner } from '@blueprintjs/core';

import { ErrorFallback } from 'shared/components';
import NotAuthorizedFallback from './components/NotAuthorizedFallback';

class SuspenseErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    const { response = {} } = error;
    return { status: response.status || 'internal' };
  }

  static defaultProps = {
    fallbacks: {
      403: () => <NotAuthorizedFallback />,
    },
  }

  constructor(props) {
    super(props);
    this.state = { status: null };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  renderFallbackUI() {
    const customFallbackRederer = this.props.fallbacks[this.state.status];
    const fallbackElement = customFallbackRederer ? customFallbackRederer() : <ErrorFallback />;

    return fallbackElement;
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.state.status
          ? this.renderFallbackUI()
          : (
            <Suspense fallback={this.props.fallback || <Spinner /> }>
              {this.props.children}
            </Suspense>
          )}
      </div>
    );
  }
}

export default SuspenseErrorBoundary;
