import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { Card, Button } from '@blueprintjs/core';

import { login } from 'data/actions/auth';

import { Input, ErrorBlock } from 'shared/components';

import styles from './Login.module.scss';

export class LoginForm extends Component {
  static propTypes = {
    loginUser: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
  }

  schema = yup.object().shape({
    email: yup
      .string()
      .email('Email is invalid')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required'),
  });

  async login(values, actions) {
    try {
      await this.props.loginUser(values);
    } catch (err) {
      const error = err && err.error ? err.error.description : 'Something went wrong';
      actions.setErrors({
        _error: error,
      });
    }
    actions.setSubmitting(false);
  }

  render() {
    return (
      <Fragment>
        <Helmet
          title="Login"
        />
        <div className={styles.logo}>
          <img
            src={process.env.REACT_APP_CLIENT_LOGO}
            alt={process.env.REACT_APP_CLIENT_NAME}
          />
        </div>
        <h1 className="text-center">Login to your account</h1>
        <Card>
          <Formik
            onSubmit={this.login}
            validationSchema={this.schema}
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{ email: '', password: '' }}
            render={({
              values,
              errors,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} className="m-b-10">
                <Field
                  large
                  className="bp3-large"
                  labelFor="emailAuth"
                  name="email"
                  type="email"
                  component={Input}
                  label="Email"
                  value={values.email}
                  error={errors.email}
                />
                <Field
                  large
                  labelFor="passwordAuth"
                  name="password"
                  type="password"
                  component={Input}
                  label="Password"
                  value={values.password}
                  error={errors.password}
                />
                <ErrorBlock
                  errors={errors}
                  className="m-b-20"
                />
                <Button
                  fill
                  large
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  intent="success"
                >
                  Sign in
                </Button>
              </form>
            )}
          />
        </Card>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loginUser: data => dispatch(login(data)),
});

LoginForm = connect(
  null,
  mapDispatchToProps
)(LoginForm);

export default LoginForm;
