import { noop } from 'lodash';
import {
  ARTICLES_POST,
  ARTICLES_PUT,
  ARTICLES_DELETE,
  ARTICLES_ADD_TO_LIST,
  ARTICLES_REMOVE_FROM_LIST,
  ARTICLES_CHANGES_UNDO,
} from 'data/constants';
import API from 'data/fetch';

export const addArticle = data => dispatch => {
  const promise = API.articles.post(data);

  return dispatch({
    type: ARTICLES_POST,
    promise,
    tryAgain: () => dispatch(addArticle(data)),
  });
};

export const editArticle = (...args) => dispatch => {
  const [id, prevData, nextData, successEffect = noop, undoEffect = noop, showToast = true] = args;
  const promise = Promise.all([
    API.articles.put(id, nextData),
    new Promise(async resolve => {
      if (prevData.sportxpert_type === 'match') {
        const { data: eventData } = await API.events.get(nextData.sportxpert_id);
        resolve(eventData);
      } else {
        resolve({});
      }
    }),
  ]).then(([article, event]) => ({
    ...article.data,
    event,
  }));

  return dispatch({
    type: ARTICLES_PUT,
    promise,
    id,
    prevData,
    nextData,
    successEffect,
    undoEffect,
    showToast,
    tryAgain: () => dispatch(editArticle(...args)),
  });
};

export const deleteArticle = (id, redirect = false) => dispatch => {
  const promise = API.articles.del(id);

  return dispatch({
    type: ARTICLES_DELETE,
    promise,
    id,
    redirect, // Flag deciding if saga should redirect to list or stay in the same page
    tryAgain: () => dispatch(deleteArticle(id, redirect)),
  });
};

export const undoArticleChange = (article, effect) => dispatch => dispatch({
  type: ARTICLES_CHANGES_UNDO,
  article,
  effect,
});

export const addArticleToList = article => dispatch => dispatch({
  type: ARTICLES_ADD_TO_LIST,
  article,
});

export const removeArticleFromList = id => dispatch => dispatch({
  type: ARTICLES_REMOVE_FROM_LIST,
  id,
});

export default {
  addArticle,
  editArticle,
  deleteArticle,
  undoArticleChange,
  addArticleToList,
  removeArticleFromList,
};
