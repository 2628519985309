import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ImageLabel.module.scss';

class ImageLabel extends Component {
  static propTypes = {
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.node.isRequired,
  }

  static defaultProps = {
    imageSrc: null,
    imageAlt: null,
    className: '',
  }

  state = {
    imageLoaded: false,
    imageError: false,
  }

  render() {
    const { imageSrc, imageAlt, label, className, ...props } = this.props;
    const { imageLoaded, imageError } = this.state;

    return (
      <div className={classNames(styles.root, className)} {...props}>
        <div
          className={classNames(
            styles.image, {
              'bp3-skeleton': !imageLoaded && !imageError && imageSrc,
              [styles.loaded]: imageLoaded,
              [styles.error]: imageError || !imageSrc,
            })}
        >
          {imageSrc && (
            <img
              src={imageSrc}
              alt={imageAlt || label}
              onLoad={() => this.setState({ imageLoaded: true })}
              onError={() => this.setState({ imageError: true })}
            />
          )}
        </div>
        {label && (
          <span className={styles.label}>
            {label}
          </span>
        )}
      </div>
    );
  }
}

export default ImageLabel;
