import { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';

import fetch from 'core/fetch';
import CONFIG from 'core/config';

export function getList(params) {
  const source = CancelToken.source();
  const options = {
    cancelToken: source.token,
    url: `${CONFIG.hosts.api}/plans`,
    method: 'GET',
    params,
  };
  const promise = fetch.request(options);
  promise[CANCEL] = source.cancel;
  return promise;
}

export const pricingList = {
  put: function put({ id, data }) {
    const source = CancelToken.source();
    const options = {
      cancelToken: source.token,
      url: `${CONFIG.hosts.api}/plans/${id}`,
      method: 'PUT',
      data,
    };
    const promise = fetch.request(options);
    promise[CANCEL] = source.cancel;
    return promise;
  },
};

