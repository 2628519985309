import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';

import { inputTypes } from './Forms.types';

const propTypes = inputTypes;

const defaultProps = {
  placeholder: null,
  intent: 'default',
  onChange: null,
};

export const Input = ({
  helperText,
  labelFor,
  placeholder,
  intent,
  field,
  onChange,
  form,
  ...props
}) => {
  const error = form.errors[field.name];

  return (
    <FormGroup
      helperText={error ? error : helperText}
      labelFor={labelFor}
      intent={error ? 'danger' : intent}
      {...props}
    >
      <InputGroup
        id={labelFor}
        placeholder={placeholder}
        intent={error ? 'danger' : intent}
        {...props}
        {...field}
        value={form.values[field.name]}
        onChange={onChange || field.onChange}
      />
    </FormGroup>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
