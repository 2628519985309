import React from 'react';
import { AnchorButton, Icon } from '@blueprintjs/core';
import classNames from 'classnames';

import Login from './views/Login';

const AuthView = () => (
  <div className="container">
    <div className="row">
      <div className="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
        {/* We can add routing for registration and recover password here */}
        <Login />
        <div className={classNames('m-t-20', 'text-center')}>
          <AnchorButton
            minimal
            href="#"
            text="Terms &amp; conditions"
          />
          <Icon icon="slash" iconSize="14" />
          <AnchorButton
            minimal
            href="#"
            text="Privacy policy"
          />
        </div>
      </div>
    </div>
  </div>
);

export default AuthView;
