import { fork, all, take, takeLatest } from 'redux-saga/effects';

import { ARTICLES_CANCEL, ARTICLES_SINGLE_GET } from 'data/constants';

import { getSingleArticle } from './articles.saga';

function* postPage() {
  while (true) {
    const task = yield takeLatest([ARTICLES_SINGLE_GET], getSingleArticle);

    yield take(ARTICLES_CANCEL);
    yield task.cancel();
  }
}

export default function* main() {
  const tasks = yield all([
    fork(postPage),
  ]);

  yield all(tasks);
}
