import React from 'react';
import { Switch as SwitchBP } from '@blueprintjs/core';

import { inputTypes } from './Forms.types';

const propTypes = inputTypes;

const defaultProps = {
  placeholder: null,
};

export const Switch = ({
  labelFor,
  placeholder,
  field,
  ...props
}) => (
  <SwitchBP id={labelFor} placeholder={placeholder} {...props} {...field} />
);

Switch.propTypes = propTypes;
Switch.defaultProps = defaultProps;

export default Switch;
