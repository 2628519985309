
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import 'react-image-crop/dist/ReactCrop.css';

import UploadMedia from '../UploadMedia';
import Dropzone from '../UploadMedia/components/Dropzone';
import Crop from '../UploadMedia/components/Crop';
import Confirmation from '../UploadMedia/components/Confirmation';

class UploadPicture extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onUpload: PropTypes.func,
    imageProps: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }
  static defaultProps = {
    isOpen: true,
    onClose: noop,
    onUpload: noop,
  }

  render() {
    const { isOpen, onClose, onUpload, imageProps } = this.props;

    return (
      <UploadMedia
        isOpen={isOpen}
        onClose={onClose}
        onUpload={onUpload}
        panelStack={[{
          component: Dropzone,
          title: 'Select picture',
          props: {
            accept: 'image/png, image/x-png,image/jpeg',
          },
        }, {
          component: Crop,
          title: 'Crop picture',
          props: {
            imageProps,
          },
        }, {
          component: Confirmation,
          title: 'Confirm picture',
          props: {
            imageProps,
          },
        }]}
      />
    );
  }
}

export default UploadPicture;
