import React from 'react';
import { Field } from 'formik';
import { SportSelectField } from 'shared/components';

const CategorySection = ({ ...props }) => {
  return (
    <Field
      large
      labelFor="sport_id"
      name="sport_id"
      component={SportSelectField}
      label="Select category"
      placeholder="Select category"
      {...props}
    />
  );
};

export default CategorySection;
