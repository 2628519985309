import PropTypes from 'prop-types';

export const articleStatuses = PropTypes.oneOf(
  ['draft', 'spell_check', 'content_check', 'scheduled', 'approved', 'archived']
).isRequired;

export const articleItemProperties = {
  slug: PropTypes.string.isRequired,
  header: PropTypes.string,
  preamble: PropTypes.string,
  body: PropTypes.object,
  status: articleStatuses,
  picture: PropTypes.string,
  published_at: PropTypes.string,
  archived_at: PropTypes.string,
  tags: PropTypes.array,
  signature: PropTypes.shape({
    url: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    job_title: PropTypes.string,
    last_name: PropTypes.string,
    phone_number: PropTypes.string,
    picture: PropTypes.string,
  }).isRequired,
};

export const articleItemShape = PropTypes.shape(articleItemProperties).isRequired;

export default {
  articleStatuses,
  articleItemProperties,
  articleItemShape,
};
