import { put, call } from 'redux-saga/effects';

import {
  NEWSPAPERS_GET_REQUEST,
  NEWSPAPERS_GET_SUCCESS,
  NEWSPAPERS_GET_FAILURE,
} from 'data/constants';

import API from 'data/fetch';

export function* getNewspapersList(action) {
  yield put({ type: NEWSPAPERS_GET_REQUEST });
  try {
    const { data } = yield call(() => API.newspapers.getList(action.params));
    if (data) yield put({ type: NEWSPAPERS_GET_SUCCESS, data });
  } catch (error) {
    yield put({ type: NEWSPAPERS_GET_FAILURE, error: error.message });
  }
}
