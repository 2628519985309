import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '@blueprintjs/core';

import { Button, ImageUpload } from 'shared/components';

import { inputTypes } from './Forms.types';

const propTypes = {
  ...inputTypes,
  fill: PropTypes.bool,
};

const defaultProps = {
  fill: false,
  placeholder: 'Choose image...',
  intent: 'default',
  onChange: null,
};

export const FileInput = ({
  allowClear,
  helperText,
  labelFor,
  placeholder,
  width,
  height,
  fill,
  cropCircle,
  intent,
  field,
  form,
  validateOnChange = false,
  ...props
}) => {
  const error = form.errors[field.name];

  const removeImage = useCallback(() => {
    form.setFieldValue(field.name, null, validateOnChange);
  });

  return (
    <FormGroup
      helperText={error ? error : helperText}
      labelFor={labelFor}
      intent={error ? 'danger' : intent}
      {...props}
      {...form}
    >
      <ImageUpload
        id={labelFor}
        placeholder={placeholder}
        image={field.value}
        width={width}
        height={height}
        fill={fill}
        cropCircle={cropCircle}
        onChange={image => form.setFieldValue(field.name, image, false, validateOnChange)}
      />
      {field.value && allowClear && (
        <Button
          small
          minimal
          onClick={removeImage}
          intent="danger"
          className="m-t-5"
        >
          Remove featured image
        </Button>
      )}
    </FormGroup>
  );
};

FileInput.propTypes = propTypes;
FileInput.defaultProps = defaultProps;

export default FileInput;
