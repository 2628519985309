import React, { Fragment, useRef, useCallback } from 'react';
import { Button } from '@blueprintjs/core';

const FileButton = ({ children, onChange, inputProps = {}, ...props }) => {
  const inputEl = useRef({});

  const handleOnChange = useCallback(e => {
    const file = e.target.files[0];

    onChange(file);
  });

  return (
    <Fragment>
      <input
        ref={inputEl}
        type="file"
        onChange={handleOnChange}
        style={{ display: 'none' }}
        {...inputProps}
      />
      <Button
        type="button"
        onClick={() => inputEl.current.click()}
        {...props}
      >
        {children}
      </Button>
    </Fragment>
  );
};

export default FileButton;
