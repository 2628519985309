import React, { Component } from 'react';

import ConfirmPDF from './ConfirmPDF';
import ConfirmPicture from './ConfirmPicture';

class Confirmation extends Component {
  render() {
    const { mediaFile } = this.props;

    switch (mediaFile.type) {
      case 'application/pdf':
        return <ConfirmPDF {...this.props} />;
      case 'image/x-png':
      case 'image/png':
      case 'image/jpeg':
        return <ConfirmPicture {...this.props} />;
      default:
        return null;
    }
  }
}

export default Confirmation;
