import { useState, useCallback } from 'react';
import { debounce } from 'lodash';

export default function useQuerySearching(fetchAction) {
  const [query, setQuery] = useState({ results: [], term: '' });
  const [fetching, setFetching] = useState(false);

  const fetchData = async term => {
    try {
      setFetching(true);
      const response = await fetchAction({
        term,
      });
      setQuery({
        results: response.data || [],
        term,
      });
    } catch (error) {
      setQuery({
        results: [],
        term,
      });
      console.log('TCL: error', error);
    } finally {
      setFetching(false);
    }
  };

  const handleQueryChange = useCallback(debounce(async term => {
    fetchData(term);
  }, 700), [fetchAction]);

  return [query, fetching, handleQueryChange];
}
