import { put, call } from 'redux-saga/effects';

import {
  BLOGS_GET_REQUEST,
  BLOGS_GET_SUCCESS,
  BLOGS_GET_FAILURE,
  BLOGS_SINGLE_GET_REQUEST,
  BLOGS_SINGLE_GET_SUCCESS,
  BLOGS_SINGLE_GET_FAILURE,
  BLOGS_CHANGES_UNDO_FAILURE,
} from 'data/constants';

import history from 'core/history';
import API from 'data/fetch';

export function* getBlogsList(action) {
  yield put({ type: BLOGS_GET_REQUEST });
  try {
    const { data } = yield call(() => API.blogs.getList(action.params));
    if (data) yield put({ type: BLOGS_GET_SUCCESS, data });
  } catch (error) {
    yield put({ type: BLOGS_GET_FAILURE, error: error.message });
  }
}

export function* getSingleBlog(action) {
  yield put({ type: BLOGS_SINGLE_GET_REQUEST });
  try {
    const { data } = yield call(() => API.blogs.get(action.params));

    if (data) yield put({ type: BLOGS_SINGLE_GET_SUCCESS, data });
  } catch (error) {
    yield put({ type: BLOGS_SINGLE_GET_FAILURE, error: error.message });
  }
}

export function* createBlog(action) {
  yield call(history.push, `/blogs/${action.req.slug}`);
}

export function* editBlog(action) {
  if (action.successEffect) {
    yield action.successEffect();
  }
}

export function* undoAction(action) {
  try {
    // revert to previous state
    yield call(() => API.blogs.put(action.article.slug, action.article));
    // Run effect if it exists
    if(action.effect) {
      yield action.effect();
    }
  } catch (err) {
    yield put({
      type: BLOGS_CHANGES_UNDO_FAILURE,
      err,
      tryAgainData: {
        ...action,
      },
    });
  }
}

export function* deleteBlog(action) {
  if (action.redirect) {
    yield call(history.push, '/blogs');
  }
}
