import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CropAndUploadImage } from 'shared/components';

export class AddImage extends Component {
  static propTypes = {
    editorState: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    initialValues: { header: '', body: '' },
  }

  constructor(props) {
    super(props);

    this.addImage = this.addImage.bind(this);
  }

  async addImage(image) {
    const { modifier, editorState, onChange, onClose } = this.props;
    onChange(modifier(editorState, image));
    onClose();
  }

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <CropAndUploadImage
        isOpen={isOpen}
        onClose={onClose}
        onUpload={this.addImage}
      />
    );
  }
}

export default AddImage;
