import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Toaster, Position, Intent } from '@blueprintjs/core';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import classNames from 'classnames';

import { formatBytes } from 'shared/helpers/files';

import styles from './Dropzone.module.scss';

class DropzoneComponent extends Component {
  static propTypes = {
    files: PropTypes.array,
    maxSize: PropTypes.number,
  }
  static defaultProps = {
    files: [],
    maxSize: 10000000,
  }

  constructor(props) {
    super(props);

    this.toast = React.createRef();

    this.state = {
      files: props.files,
    };

    this.onDrop = this.onDrop.bind(this);
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  onDrop(acceptedFiles, rejectedFiles) {
    const { onDrop } = this.props;

    if (rejectedFiles.length) {
      rejectedFiles.forEach(file => {
        this.toast.current.show({
          position: Position.BOTTOM,
          intent: Intent.DANGER,
          message: `File ${file.name} is too big.`,
        });
      });
    }

    if (!acceptedFiles.length) return;

    acceptedFiles.forEach(file => {
      file.preview = URL.createObjectURL(file);
    });

    this.setState({
      files: acceptedFiles,
    });

    onDrop && onDrop(acceptedFiles);
  }

  renderThumbnail(file) {
    switch (file.type) {
      case 'application/pdf':
        return (
          <Document
            file={file.preview}
          >
            <Page
              className={styles.page}
              pageNumber={1}
            />
          </Document>
        );
      default:
        return (
          <img
            src={file.preview}
            className={styles.img}
            alt={file.name}
          />
        );
    }
  }

  renderThumbs(getRootProps) {
    const { files } = this.state;

    if (files.length === 0) return null;

    return (
      <aside className={styles.thumbsContainer}>
        {files.map(file => (
          <div className={styles.thumb} key={file.name} {...getRootProps()}>
            <div className={styles.thumbInner}>
              {this.renderThumbnail(file)}
            </div>
          </div>
        ))}
      </aside>
    );
  }

  renderDropArea(getRootProps) {
    const { maxSize, hideDropArea, className } = this.props;
    const { files } = this.state;

    if (hideDropArea && files.length) return null;

    return (
      <div className={classNames(styles.root, className)} {...getRootProps()}>
        <span className={styles.hint}>Drop files here, or click in this area.</span>
        <span className={styles.limits}>Maximum file size: {formatBytes(maxSize, 0)}</span>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        <Dropzone
          {...this.props}
          onDrop={this.onDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <Fragment>
              <input {...getInputProps()} />
              {this.renderDropArea(getRootProps, getInputProps)}
              {this.renderThumbs(getRootProps)}
            </Fragment>
          )}
        </Dropzone>
        <Toaster
          ref={this.toast}
        />
      </Fragment>
    );
  }
}

export default DropzoneComponent;
