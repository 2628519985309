import React, { Component } from 'react';
import classNames from 'classnames';
import { Document, Page } from 'react-pdf/dist/entry.webpack';

import { formatBytes } from 'shared/helpers/files';

import styles from './PDF.module.scss';

export default class Image extends Component {
  render() {
    const {
      block, // eslint-disable-line no-unused-vars
      className,
      ...otherProps
    } = this.props;
    // leveraging destructuring to omit certain properties from props
    const {
      blockProps, // eslint-disable-line no-unused-vars
      customStyleMap, // eslint-disable-line no-unused-vars
      customStyleFn, // eslint-disable-line no-unused-vars
      decorator, // eslint-disable-line no-unused-vars
      forceSelection, // eslint-disable-line no-unused-vars
      offsetKey, // eslint-disable-line no-unused-vars
      selection, // eslint-disable-line no-unused-vars
      tree, // eslint-disable-line no-unused-vars
      contentState, // eslint-disable-line no-unused-vars
      blockStyleFn, // eslint-disable-line no-unused-vars
      ...elementProps
    } = otherProps;
    const data = contentState.getEntity(block.getEntityAt(0)).getData();
    const size = formatBytes(data.byte_size);
    return (
      <div
        className={classNames(styles.root, className)}
        {...elementProps}
      >
        Document preview
        <Document
          file={data.url}
        >
          <Page
            pageNumber={1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            width={280}
          />
        </Document>
        <span className={styles.name}>
          {data.name}
        </span>
        <span className={styles.size}>
          ({size})
        </span>
      </div>
    );
  }
}
