import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';
import { Button, Intent } from '@blueprintjs/core';

import { postPicture } from 'data/fetch/media.fetch';

import styles from '../../UploadMedia.module.scss';

class ConfirmPicture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileBlob: URL.createObjectURL(props.mediaFile),
      uploading: false,
      preview: null,
    };

    this.uploadImage = this.uploadImage.bind(this);
  }

  async componentDidMount() {
    this.setState({
      preview: await this.getCroppedImage(),
    });
  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(this.state.fileBlob);
    URL.revokeObjectURL(this.state.preview);
  }

  getCroppedImage() {
    const { pixel } = this.props;
    const { fileBlob } = this.state;

    return new Promise((resolve, reject) => {
      try {
        // Create Image object
        const image = new Image();
        image.src = fileBlob;
        // Create canvas
        const canvas = document.createElement('canvas');
        canvas.width = pixel.width;
        canvas.height = pixel.height;
        const ctx = canvas.getContext('2d');
        // Draw image in canvas once it's loaded
        image.onload = () => {
          ctx.drawImage(
            image,
            pixel.x,
            pixel.y,
            pixel.width,
            pixel.height,
            0,
            0,
            pixel.width,
            pixel.height
          );

          // Sync process
          const resultingImage = canvas.toDataURL('image/jpeg');

          resolve(resultingImage);
        };
      } catch(err) {
        reject(err);
      }
    });
  }

  async uploadImage() {
    const { onUpload, mediaFile, pixel } = this.props;

    this.setState({
      uploading: true,
    });

    try {
      const { data } = await postPicture(mediaFile, {
        width: pixel.width,
        height: pixel.height,
        x_coordinate: pixel.x,
        y_coordinate: pixel.y,
      });
      onUpload(data.url);
    } catch(err) {
      noop();
    }

    this.setState({
      uploading: false,
    });
  }

  render() {
    const { resize, imageProps = {} } = this.props;
    const { uploading, preview } = this.state;

    return (
      <Fragment>
        <div className={classnames(
          styles.resultWrapper,
          {
            [styles['resultWrapper--circle']]: imageProps.cropCircle,
          }
        )}>
          <img src={preview} alt="Crop result" onLoad={() => { resize(); } } />
        </div>
        <div className={styles.actions}>
          <Button
            intent={Intent.PRIMARY}
            onClick={this.uploadImage}
            loading={uploading}
          >
            Upload picture
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default ConfirmPicture;
