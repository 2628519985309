import {
  LOADING_STATES,
  OVERVIEW_GET_REQUEST,
  OVERVIEW_GET_SUCCESS,
  OVERVIEW_GET_FAILURE,
} from 'data/constants';

const defaultState = {
  state: LOADING_STATES.INITIAL,
  data: {},
  error: null,
};

export default function overview(state = defaultState, action) {
  switch (action.type) {
    case OVERVIEW_GET_REQUEST:
      return {
        ...state,
        state: LOADING_STATES.LOADING,
      };
    case OVERVIEW_GET_SUCCESS:
      return {
        ...state,
        state: LOADING_STATES.LOADED,
        data: action.data,
      };
    case OVERVIEW_GET_FAILURE:
      return {
        ...state,
        state: LOADING_STATES.FAILED,
        error: action.error.message,
      };
    default:
      return state;
  }
}
