import React from 'react';
import PropTypes from 'prop-types';
import ResponsiveEmbed from 'react-responsive-embed';

import helpers from 'shared/helpers/video';

const YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
const VIMEO_PREFIX = 'https://player.vimeo.com/video/';

const getSrc = ({ src }) => {
  if (helpers.isYoutube(src)) {
    const { srcID } = helpers.getYoutubeSrc(src);
    return `${YOUTUBE_PREFIX}${srcID}`;
  }
  if (helpers.isVimeo(src)) {
    const { srcID } = helpers.getVimeoSrc(src);
    return `${VIMEO_PREFIX}${srcID}`;
  }
  return;
};

const VideoFrame = ({
  blockProps,
  theme,
}) => {
  const src = getSrc(blockProps);

  if (src) {
    return <ResponsiveEmbed allowFullScreen src={src} />;
  }

  return (<div className={theme.invalidVideoSrc}>invalid video source</div>);
};

VideoFrame.propTypes = {
  blockProps: PropTypes.object.isRequired,
};
export default VideoFrame;
