import React from 'react';
import { connect } from 'react-redux';

import ArticleRoot from './ArticleRoot';

import { ARTICLES_SINGLE_GET, ARTICLES_CANCEL } from 'data/constants';

import { editArticle, deleteArticle, undoArticleChange } from 'data/actions/articles';

import { MainViewHandler } from 'shared/hocs';

const ArticleContainer = ({ article, ...props }) => {
  return (
    <ArticleRoot
      item={article}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  return {
    state: state.articlesSingle.state,
    article: state.articlesSingle.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    undo: (article, effect) => dispatch(undoArticleChange(article, effect)),
    edit: (id, prevData, nextData, success, undo, showToast) => dispatch(editArticle(id, prevData, nextData, success, undo, showToast)),
    remove: post => dispatch(deleteArticle(post, true)),
    fetchData: params => dispatch({
      type: ARTICLES_SINGLE_GET,
      params,
    }),
    cancelFetch: () => dispatch({
      type: ARTICLES_CANCEL,
    }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainViewHandler(ArticleContainer));
