import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { noop } from 'lodash';

import CreatorFormView from './CreatorForm.view';

import { getSports, getNewspaperKinds } from 'data/actions/general';
import { getBettingCards } from 'data/actions/bettingCards';

import { selectStrategy } from './Creator.utils';
import { days } from 'views/ContentCreator/ContentCreator.const';

const getCategoriesItems = categories => categories.map(category => ({
  label: category.name,
  value: category.id,
  meta: category,
}));

let CreatorForm = ({
  sports, newspaperKinds, bettingCards, values,
  getSports, getNewspaperKinds, getBettingCards,
  ...props
}) => {
  const { type: selectedType } = values;

  const sportsItems = useMemo(() => getCategoriesItems(sports), [sports.length]);
  const newspaperKindsItems = useMemo(() => getCategoriesItems(newspaperKinds), [newspaperKinds.length]);
  const categories = selectStrategy(selectedType, {
    post: sportsItems,
    newspaper: newspaperKindsItems,
    bettingCard: days,
    default: [],
  });

  useEffect(() => {
    const action = selectStrategy(selectedType, {
      post: !sports.length ? getSports : noop,
      newspaper: !newspaperKinds.length ? getNewspaperKinds : noop,
      bettingCard: !bettingCards.length ? getBettingCards : noop,
      default: noop,
    });

    action();
  }, [selectedType]);

  return (
    <CreatorFormView
      categories={categories}
      values={values}
      bettingCards={bettingCards}
      {...props}
    />
  );
};

CreatorForm = connect(state => ({
  sports: state.general.sports,
  newspaperKinds: state.general.newspaperKinds,
  bettingCards: state.bettingCardsList.list,
}), {
  getSports,
  getNewspaperKinds,
  getBettingCards,
})(CreatorForm);

export default CreatorForm;
