import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './DeviceFrame.module.scss';

class DeviceFrame extends Component {
  static propTypes = {
    content: PropTypes.node.isRequired,
    device: PropTypes.oneOf(['smartphone', 'tablet', 'monitor']),
  }
  static defaultProps = {
    device: 'smartphone',
  }

  constructor(props) {
    super(props);

    this.renderSmartPhone = this.renderSmartPhone.bind(this);
    this.renderTablet = this.renderTablet.bind(this);
    this.renderMonitor = this.renderMonitor.bind(this);
  }

  devices = [{
    key: 'smartphone',
    render: () => this.renderSmartPhone(),
  }, {
    key: 'tablet',
    render: () => this.renderTablet(),
  }, {
    key: 'monitor',
    render: () => this.renderMonitor(),
  }]

  renderSmartPhone() {
    const { landscape, children } = this.props;
    return (
      <div className={classNames(styles.device, styles.iphoneX, {
        [styles.landscape]: landscape,
      })}
      >
        <div className={styles.notch}>
          <div className={styles.camera} />
          <div className={styles.speaker} />
        </div>
        <div className={styles.topBar} />
        <div className={styles.sleep} />
        <div className={styles.bottomBar} />
        <div className={styles.volume} />
        <div className={styles.overflow}>
          <div className={classNames(styles.shadow, styles.shadowTR)} />
          <div className={classNames(styles.shadow, styles.shadowTL)} />
          <div className={classNames(styles.shadow, styles.shadowBR)} />
          <div className={classNames(styles.shadow, styles.shadowBL)} />
        </div>
        <div className={styles.innerShadow} />
        <div className={styles.screen}>
          {children}
        </div>
      </div>
    );
  }

  renderTablet() {
    const { landscape, children } = this.props;
    return (
      <div className={classNames(styles.device, styles.ipad, styles.silver, {
        [styles.landscape]: landscape,
      })}
      >
        <div className={styles.camera} />
        <div className={styles.screen}>
          {children}
        </div>
        <div className={styles.home} />
      </div>
    );
  }

  renderMonitor() {
    const { landscape, children } = this.props;
    return (
      <div className={classNames(styles.device, styles.macbook, {
        [styles.landscape]: landscape,
      })}
      >
        <div className={styles.topBar} />
        <div className={styles.camera} />
        <div className={styles.screen}>
          {children}
        </div>
        <div className={styles.bottomBar} />
      </div>
    );
  }

  render() {
    const { device } = this.props;
    const currentDevice = this.devices.find(d => d.key === device);
    return (
      <div
        className={styles.root}
      >
        {currentDevice.render()}
      </div>
    );
  }
}

export default DeviceFrame;
