import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Icon, Tooltip, Position } from '@blueprintjs/core';

import { AccessControl } from 'shared/components';

import styles from './Navigation.module.scss';

class Navigation extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
  }

  render() {
    const { isOpen } = this.props;

    return (
      <nav
        className={classNames(styles.root, {
          [styles.isClosed]: !isOpen,
        })}
      >
        <div className={styles.inner}>
          <menu className={styles.menu}>
            <ul className={classNames('bp3-list', 'bp3-list-unstyled')}>
              <li>
                <Tooltip
                  content="Dashboard"
                  boundary="viewport"
                  position={Position.LEFT}
                  disabled={isOpen}
                >
                  <NavLink
                    exact
                    activeClassName={styles.active}
                    to="/"
                  >
                    <Icon icon="grid-view" />
                    <span className={styles.label}>Dashboard</span>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  content="Articles"
                  boundary="viewport"
                  position={Position.LEFT}
                  disabled={isOpen}
                >
                  <NavLink
                    activeClassName={styles.active}
                    to="/articles"
                  >
                    <Icon icon="bookmark" />
                    <span className={styles.label}>Articles</span>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  content="Blogs"
                  boundary="viewport"
                  position={Position.LEFT}
                  disabled={isOpen}
                >
                  <NavLink
                    activeClassName={styles.active}
                    to="/blogs"
                  >
                    <Icon icon="bookmark" />
                    <span className={styles.label}>Blogs</span>
                  </NavLink>
                </Tooltip>
              </li>
              <li>
                <Tooltip
                  content="Content creator"
                  boundary="viewport"
                  position={Position.LEFT}
                  disabled={isOpen}
                >
                  <NavLink
                    exact
                    activeClassName={styles.active}
                    to="/content-creator"
                  >
                    <Icon icon="add-to-artifact" />
                    <span className={styles.label}>Content creator</span>
                  </NavLink>
                </Tooltip>
              </li>
              <AccessControl
                allowedPermissions={[AccessControl.PERMISSIONS.admin, AccessControl.PERMISSIONS.technician]}
              >
                <li>
                  <Tooltip
                    content="Subscribers"
                    boundary="viewport"
                    position={Position.LEFT}
                    disabled={isOpen}
                  >
                    <NavLink
                      activeClassName={styles.active}
                      to="/subscribers"
                    >
                      <Icon icon="people" />
                      <span className={styles.label}>Subscribers</span>
                    </NavLink>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    content="Users"
                    boundary="viewport"
                    position={Position.LEFT}
                    disabled={isOpen}
                  >
                    <NavLink
                      activeClassName={styles.active}
                      to="/users"
                    >
                      <Icon icon="people" />
                      <span className={styles.label}>Users</span>
                    </NavLink>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip
                    content="Settings"
                    boundary="viewport"
                    position={Position.LEFT}
                    disabled={isOpen}
                  >
                    <NavLink
                      activeClassName={styles.active}
                      to="/settings"
                    >
                      <Icon icon="cog" />
                      <span className={styles.label}>Settings</span>
                    </NavLink>
                  </Tooltip>
                </li>
              </AccessControl>
            </ul>
          </menu>
        </div>
      </nav>
    );
  }
}

export default Navigation;
