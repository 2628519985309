import * as general from './general.fetch';
import * as subscribers from './subscribers.fetch';
import * as users from './users.fetch';
import * as pricing from './pricing.fetch';
import auth from './auth.fetch';
import overview from './overview.fetch';
import profile from './profile.fetch';

import articles from './articles.fetch';
import blogs from './blogs.fetch';
import newspapers from './newspapers.fetch';
import bettingCards from './bettingCards.fetch';

import events from './events.fetch';

export default {
  general,
  auth,
  overview,
  profile,

  articles,
  blogs,
  newspapers,
  bettingCards,

  events,

  subscribers,
  users,
  pricing,
};
