import { transform, isEqual, isObject } from 'lodash/fp';

export const cleanObject = obj => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  }
  return obj;
};

// Get difference between objects
const _transform = transform.convert({
  cap: false,
});
const iteratee = baseObj => (result, value, key) => {
  if (!isEqual(value, baseObj[key])) {
    const valIsObj = isObject(value) && isObject(baseObj[key]);
    result[key] = valIsObj === true ? differenceObject(value, baseObj[key]) : value;
  }
};
export function differenceObject(targetObj, baseObj) {
  return _transform(iteratee(baseObj), null, targetObj);
}

export default {
  cleanObject,
  differenceObject,
};
