import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { addQuery } from 'shared/helpers/url';

import { UserSelect } from 'shared/components';

const UserFilter = ({ history, location }) => {
  function setFilter(user) {
    history.push(addQuery(location, {
      user_id: user,
    }));
  }

  return (
    <UserSelect
      allowClear
      onChange={setFilter}
      value={location.query.user_id}
      placeholder="Select assignee..."
    />
  );
};

UserFilter.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(UserFilter);
