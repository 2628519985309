import React from 'react';
import { Field } from 'formik';
import { EventSelectField, SeasonSelectField } from 'shared/components';

const LinkingSportXpertSection = ({ values, setFieldValue }) => {
  const { sportxpert_season_id, sportxpert_match_id } = values;

  return (
    <React.Fragment>
      <Field
        large
        name="sportxpert_season_id"
        component={SeasonSelectField}
        label="Link with a season"
        placeholder="Link with a season"
        initialValue={sportxpert_season_id || undefined}
        onChange={sportxpertId => {
          setFieldValue('sportxpert_type', 'season');
          setFieldValue('sportxpert_id', sportxpertId ? sportxpertId : null);
          if (!sportxpertId) {
            setFieldValue('sportxpert_match_id', null);
          }
        }}
        disabled={values.sportxpert_match_id}
      />
      {sportxpert_season_id && (
        <Field
          large
          name="sportxpert_match_id"
          component={EventSelectField}
          label="Link with an event"
          placeholder="Link with an event"
          initialValue={sportxpert_match_id || undefined}
          selectedSeasonId={sportxpert_season_id}
          onChange={sportxpertId => {
            setFieldValue('sportxpert_type', sportxpertId ? 'match' : 'season');
            setFieldValue('sportxpert_id', sportxpertId ? sportxpertId : sportxpert_season_id);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default LinkingSportXpertSection;
