import {
  BETTING_CARDS_GET,
  BETTING_CARDS_PUT,
} from 'data/constants';
import API from 'data/fetch';


export const getBettingCards = () => dispatch => {
  const promise = API.bettingCards.getList();

  return dispatch({
    type: BETTING_CARDS_GET,
    promise,
    tryAgain: () => dispatch(getBettingCards()),
  });
};

export const editBettingCard = ({ id, ...data }) => dispatch => {
  const promise = API.bettingCards.put(id, data);

  return dispatch({
    type: BETTING_CARDS_PUT,
    promise,
    tryAgain: () => dispatch(editBettingCard({ id, ...data })),
  });
};

export default {
  getBettingCards,
  editBettingCard,
};
