import React, { PureComponent } from 'react';
import cx from 'classnames';
import moment from 'moment';

import Types from 'shared/types/Articles.types';

import { ArticleStatusLabel } from 'shared/components';

import CONFIG from 'core/config';

import styles from './ArticleItem.module.scss';

class Article extends PureComponent {
  static propTypes = {
    ...Types.articleItemProperties,
  };

  state = {
    imageLoaded: false,
    imageError: false,
  }

  render() {
    const {
      status, header, picture, signature,
      published_at: publishedAt,
      archived_at: archivedAt,
      updated_at: updatedAt,
    } = this.props;
    const { imageLoaded, imageError } = this.state;

    return (
      <article className={styles.root}>
        <div className={styles.content}>
          <div
            className={cx(
              styles.image, {
                'bp3-skeleton': picture && !imageLoaded && !imageError,
                [styles.loaded]: imageLoaded,
                [styles.error]: imageError,
              })}
          >
            {picture && (
              <img
                src={picture}
                alt={header}
                onLoad={() => this.setState({ imageLoaded: true })}
                onError={() => this.setState({ imageError: true })}
              />
            )}
          </div>
          <div className={styles.body}>
            <h1>{header || <span className={styles.untitled}>Untitled</span>}</h1>
            <div className={styles.meta}>
              {`${signature.first_name} ${signature.last_name}`}
            </div>
          </div>
        </div>
        <div className={styles.rightBox}>
          <div className="bp3-text-small">
            {moment(updatedAt).format(CONFIG.settings.dateTimeDisplayFormat)}
          </div>
          <ArticleStatusLabel
            className="m-r-20"
            status={status}
            publishedAt={publishedAt}
            archivedAt={archivedAt}
          />
        </div>
      </article>
    );
  }
}

export default Article;
